import { createSlice } from "@reduxjs/toolkit";

const consistencyProductSlice = createSlice({
  name: "consistencyProduct",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
    selectedProductId: '',
    selectedProductName: '',
    selectedAtribute: '',
    selectedValueSivina: '',
    selectedValueErri: '',
    selectedLabelSivina: '',
    selectedLabelErri: '',
    selectedConsistencies: {},
  },
  reducers: {
    setConsistencyProducts: (state, action) => {
      state.data = action.payload;
    },
    setConsistencyProduct: (state, action) => {
      state.detail = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setSelectedProductId: (state, action) => {
      state.selectedProductId = action.payload;
    },
    setSelectedProductName: (state, action) => {
      state.selectedProductName = action.payload;
    },
    setSelectedAtribute: (state, action) => {
      state.selectedAtribute = action.payload;
    },
    setSelectedValueSivina: (state, action) => {
      state.selectedValueSivina = action.payload;
    },
    setSelectedValueErri: (state, action) => {
      state.selectedValueErri = action.payload;
    },
    setSelectedLabelSivina: (state, action) => {
      state.selectedLabelSivina = action.payload;
    },
    setSelectedLabelErri: (state, action) => {
      state.selectedLabelErri = action.payload;
    },
    setSelectedConsistency: (state, action) => {
      const { id, value } = action.payload;
      state.selectedConsistencies[id] = value;
    },
  },
});

export const {
  setConsistencyProducts,
  setCurrentPage,
  setItemsPerPage,
  setConsistencyProduct,
  setTotal,
  setSelectedProductId,
  setSelectedProductName,
  setSelectedAtribute,
  setSelectedValueSivina,
  setSelectedValueErri,
  setSelectedLabelSivina,
  setSelectedLabelErri,
  setSelectedConsistency,
} = consistencyProductSlice.actions;

export default consistencyProductSlice.reducer;
