import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  TableCompleteness,
  TableCompletenessStoreGT,
  TableCompletenessStoreMT,
} from "../Tables/TableDataProcessing/TableCompleteness";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

export const CompletenessProductModal = ({
  open,
  onClose,
  onCompletenessStoreGT,
  onEditProduct,
  isLoading,
  itemsPerPageList,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "right", mb: 3 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid item sx={{ float: "left", mb: 3 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1 }}
          >
            List Completeness Product Master
          </Typography>
        </Grid>
        <Grid item sx={{ float: "right", mb: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            href="/setup-product-master"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Setup Product Master"}
          </Button>
          <Button
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            onClick={onCompletenessStoreGT}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Go To Store Master"}
          </Button>
        </Grid>
        <TableCompleteness
          onEdit={onEditProduct}
          itemsPerPageList={itemsPerPageList}
        />
      </Box>
    </Modal>
  );
};

export const CompletenessProductModalMT = ({
  open,
  onClose,
  onCompletenessStoreMT,
  onEditProduct,
  isLoading,
  itemsPerPageList,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "right", mb: 3 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid item sx={{ float: "left", mb: 3 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1 }}
          >
            List Completeness Product Master MT
          </Typography>
        </Grid>

        <Grid item sx={{ float: "right", mb: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            href="/setup-product-master"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Setup Product Master"}
          </Button>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            onClick={onCompletenessStoreMT}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Go To Store Master"}
          </Button>
        </Grid>
        <TableCompleteness
          onEdit={onEditProduct}
          itemsPerPageList={itemsPerPageList}
        />
      </Box>
    </Modal>
  );
};

export const CompletenessStoreGTModal = ({
  projectType,
  open,
  onClose,
  onCompletenessProduct,
  onEditStore,
  isLoading,
  itemsPerPageList,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "right", mb: 3 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid item sx={{ float: "left", mb: 3 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1 }}
          >
            List Completeness Store Master
          </Typography>
        </Grid>
        <Grid item sx={{ float: "right", mb: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            href="/setup-product-master"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Setup Product Master"}
          </Button>
          <Button
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            onClick={onCompletenessProduct}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Go To Product Master"}
          </Button>
        </Grid>
        <TableCompletenessStoreGT
          onEdit={onEditStore}
          itemsPerPageList={itemsPerPageList}
          projectType={projectType}
        />
      </Box>
    </Modal>
  );
};

export const CompletenessStoreMTModal = ({
  open,
  onClose,
  onCompletenessProduct,
  onEditStore,
  isLoading,
  itemsPerPageList,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "right", mb: 3 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid item sx={{ float: "left", mb: 3 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1 }}
          >
            List Completeness Store Master MT
          </Typography>
        </Grid>

        <Grid item sx={{ float: "right", mb: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            href="/setup-store-master"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Setup Store Master"}
          </Button>
          <Button
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            onClick={onCompletenessProduct}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Go To Product Master"}
          </Button>
        </Grid>
        <TableCompletenessStoreMT
          onEdit={onEditStore}
          itemsPerPageList={itemsPerPageList}
        />
      </Box>
    </Modal>
  );
};
