import React from "react";
import { Modal, Box, Button, Tooltip } from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function UpdateDataMobileToWeb({
  isLoading,
  modalStyle,
  closeIconTarget,
  modalOpen,
  handleClose,
  handleSubmit,
}) {
  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="update-data-modal-title"
      aria-describedby="update-data-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={modalStyle}>
        <Tooltip title="Close Update Data">
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeIconTarget}
            sx={{ position: "absolute", top: 20, right: 30 }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <h2>Konfirmasi Update data dari mobile ke web</h2>
        <p>Apakah anda yakin untuk update data ini?</p>
        <div>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              width: 190,
              height: 50,
              mt: 2,
              mr: 1,
              color: "#b34469",
              borderColor: "#b34469",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <BootstrapButton
            variant="contained"
            disableRipple
            sx={{ width: 182, mt: 2, height: 50, color: "#FFF" }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "UPDATE"}
          </BootstrapButton>
        </div>
      </Box>
    </Modal>
  );
}

export default UpdateDataMobileToWeb;
