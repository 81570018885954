import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const ModalConfirmOk = ({ open, onClose, onConfirm }) => {

    const CancelButton = styled(Button)({
        backgroundColor: '#DE3436',
        color: 'white',
        '&:hover': {
          backgroundColor: '#DC1F21', 
        },
      });
      
      const ConfirmButton = styled(Button)({
        backgroundColor: '#469F3F',
        color: 'white',
        '&:hover': {
          backgroundColor: '#32932B', 
        },
      });

      
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'white',
          border: '0px solid #000',
          boxShadow: 24,
          padding: '16px 32px 24px',
          borderRadius: "5px"
        }}
      >
        <Typography sx={{ mt: 2, fontSize: "18px", color: "#383838"}}>
          Apakah anda yakin ingin mengkonfirmasi data tersebut?
        </Typography>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <CancelButton onClick={onClose} style={{ marginRight: '8px' }}>
            Tidak
          </CancelButton>
          <ConfirmButton onClick={onConfirm}>
            Ya
          </ConfirmButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalConfirmOk;
