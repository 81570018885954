import { createSlice } from "@reduxjs/toolkit"

const dataSurveySlice = createSlice({
  name: "dataSurvey",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
    selectedSurveyId: '',
    selectedProvinceId: '',
    selectedRegencyId: '',
    selectedDistrictId: '',
    selectedSubDistrictId: '',
    selectedAuditStatus: [],
    selectedTipeSurveyId: '',
    selectedUsernameSurveyorId: '',
    selectedIsNegative: '',
    dataSurveyfilterColumnName: [],
    filterColumnName: '',
    filterColumnOperator: 'LIKE',
    filterColumnValue: '',
  },
  reducers: {
    // addTodataSurvey: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setDataSurveys: (state, action) => {
      state.data = action.payload;
    },
    setDataSurvey: (state, action) => {
      state.detail = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setSelectedSurveyId: (state, action) => {
      state.selectedSurveyId = action.payload;
    },
    setProvinceCode: (state, action) => {
      state.selectedProvinceId = action.payload;
    },
    setRegencyCode: (state, action) => {
      state.selectedRegencyId = action.payload;
    },
    setDistrictCode: (state, action) => {
      state.selectedDistrictId = action.payload;
    },
    setSubDistrictCode: (state, action) => {
      state.selectedSubDistrictId = action.payload;
    },
    setSelectedAuditStatus: (state, action) => {
      state.selectedAuditStatus = action.payload;
    },
    setTipeSurveyCode: (state, action) => {
      state.selectedTipeSurveyId = action.payload;
    },
    setUsernameSurveyorCodeId: (state, action) => {
      state.selectedUsernameSurveyorId = action.payload;
    },
    setSelectedIsNegative: (state, action) => {
      state.selectedIsNegative = action.payload;
    },
    setDataSurveyColumn: (state, action) => {
      state.dataSurveyfilterColumnName = action.payload;
    },
    setFilterColumnName: (state, action) => {
      state.filterColumnName = action.payload;
    },
    setFilterColumnOperator: (state, action) => {
      state.filterColumnOperator = action.payload;
    },
    setFilterColumnValue: (state, action) => {
      state.filterColumnValue = action.payload;
    },
  }
})

export const {
  setDataSurveys,
  setCurrentPage,
  setItemsPerPage,
  setDataSurvey,
  setTotal,
  setSelectedSurveyId,
  setProvinceCode,
  setRegencyCode,
  setDistrictCode,
  setSubDistrictCode,
  setSelectedAuditStatus,
  setTipeSurveyCode,
  setUsernameSurveyorCode,
  setSelectedIsNegative,
  setDataSurveyColumn,
  setFilterColumnName,
  setFilterColumnOperator,
  setFilterColumnValue,
} = dataSurveySlice.actions;
export default dataSurveySlice.reducer;
