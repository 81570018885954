import { setInitial, setRoleCode, setProfile } from '../redux/slices/profileSlice';

export const fetchProfile = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/auth/profile`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      dispatch(setProfile(data.data));
      dispatch(setInitial(data.data.initial));
      dispatch(setRoleCode(data.data.role_code));
    } else {
      console.error(`Failed to fetch profile:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching profile:`, error);
  }
};
