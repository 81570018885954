import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import Navbar from '../../../Components/Navbar';
import { fetchProfile } from '../../../utils/fetchProfile';
import SearchBarSetupProduct from '../../../Components/SearchBarSetup';
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { IconButton, Modal, Box, Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import DataTable from "./Grid/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import TableViewIcon from '@mui/icons-material/TableView';
import { fetchDataProductMaster, fetchExportDataProductMaster, exportproductmastersetup } from "./DataUtil";
import ModalTableCategory from './ModalTableCategory';
import ImportModalSetup from "./Modalimport";
import { Slide, toast } from 'react-toastify';



// custom css for modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};
// button style
const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "5px 10px",
  border: "1px solid",
  marginLeft: "10px",
  width: "100px",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function SetUpMasterProduct() {
  const [datacategory, setdatacategory] = useState([]);
  const [dataBrand, setdataBrand] = useState([]);
  const [dataPrincipal, setdataPrincipal] = useState([]);
  const [dataStockUnit, setdataStockUnit] = useState([]);
  const [dataPacksize, setdataPacksize] = useState([]);
  const [dataPacktype, setdataPacktype] = useState([]);
  const [dataFlavour, setdataFlavour] = useState([]);
  const [dataUnitvol, setdataUnitvol] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const accessToken = Cookies.get('access_token') || '';
  const [rowModesModel, setRowModesModel] = useState({});
  const [searchBarValue, setSearchBarValue] = useState("Category")
  const [selectedTable, setSelectedTable] = useState("Category")
  const [selectedOption, setSelectedOption] = useState("Category");
  const [modalAddCreate, setModalAddCreate] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedUpdate, setselectedUpdate] = useState(null);

  //import
  const [openDialogImport, setOpenDialogImport] = useState(false);

  // untuk membatas (?page=1&size=10)


  {/*function view data*/ }
  async function fetchProductCategory() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/category-master`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + accessToken,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setdatacategory(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchProductBrand() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/brand-master`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + accessToken,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setdataBrand(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchProductPrincipal() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/principal-master`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + accessToken,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setdataPrincipal(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchStockUnit() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/stock-unit`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + accessToken,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setdataStockUnit(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchPacksize() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/packsize`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + accessToken,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setdataPacksize(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchPacktype() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/packtype`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + accessToken,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setdataPacktype(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchFlavour() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/flavour`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + accessToken,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setdataFlavour(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchUnitvol() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/unitvol`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + accessToken,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setdataUnitvol(data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }


  //function datagrid//table
  {/*field table*/ }
  const [openModalCategory, setOpenModalCategory] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedInformation, setSelectedInformation] = useState(null);

  const handleViewCategory = (params) => {
    setSelectedCategoryId(params.row.id);
    setSelectedInformation(params.row);
    setOpenModalCategory(true);
  };

  const handleCloseViewCategory = () => {
    setOpenModalCategory(false);
    setSelectedInformation(false);
    setSelectedCategoryId("");
  };

  // Category
  const Category = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 50,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Name",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "ord_category",
      headerClassName: "super-app-theme--header",
      headerName: "Ord Category",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          <Tooltip
            title="Edit Category"
            onClick={() => HandleEditCategory(params)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
          title="View Attribute"
          onClick={() => handleViewCategory(params)}
        >
          <IconButton>
            <TableViewIcon />
          </IconButton>
        </Tooltip>
        </div>
      ),
    },
  ];

  // Brand
  const Brand = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 100,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Brand",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          <Tooltip
            title="Edit Brand"
            onClick={() => HandleEditBrand(params)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  //principal
  const Principal = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 100,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Principal",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          <Tooltip
            title="Edit Principal"
            onClick={() => HandleEditPrincipal(params)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  //StockUnit
  const Stockunit = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 100,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "code",
      headerClassName: "super-app-theme--header",
      headerName: "Unit Code",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "description",
      headerClassName: "super-app-theme--header",
      headerName: "DESC",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "unit",
      headerClassName: "super-app-theme--header",
      headerName: "Unit",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "weight",
      headerClassName: "super-app-theme--header",
      headerName: "Weight",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          <Tooltip
            title="Edit Stock Unit"
            onClick={() => HandleEditStockunit(params)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  //Packsize
  const Packsize = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 100,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "desc",
      headerClassName: "super-app-theme--header",
      headerName: "Packsize",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          <Tooltip
            title="Edit Packsize"
            onClick={() => HandleEditPacksize(params)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  // Packtype
  const Packtype = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 100,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "desc",
      headerClassName: "super-app-theme--header",
      headerName: "Packtype",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          <Tooltip
            title="Edit Packtype"
            onClick={() => HandleEditPacktype(params)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  // Flavour
  const Flavour = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "desc",
      headerClassName: "super-app-theme--header",
      headerName: "Flavour",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          <Tooltip
            title="Edit Flavour"
            onClick={() => HandleEditFlavour(params)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  // Ynitvol
  const Unitvol = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "desc",
      headerClassName: "super-app-theme--header",
      headerName: "Unitvol",
      width: 200,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          <Tooltip
            title="Edit Unitvol"
            onClick={() => HandleEditUnitvol(params)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  // const handleRowEditStop = (params, event) => {
  //   if (params.reason === GridRowEditStopReasons.rowFocusOut) {
  //     event.defaultMuiPrevented = true;
  //   }
  // };
  // const handleRowModesModelChange = (newRowModesModel) => {
  //   setRowModesModel(newRowModesModel);
  // };

  {/*function export */ }
  const [selectedCategory, setselectedCategory] = useState(
    localStorage.getItem("selectedCategory") || "all"
  );
  const [selectedBrand, setselectedBrand] = useState(
    localStorage.getItem("selectedBrand") || "all"
  );
  const [selectedPrincipal, setselectedPrincipal] = useState(
    localStorage.getItem("selectedPrincipal") || "all"
  );
  const [selectedStockunit, setselectedStockunit] = useState(
    localStorage.getItem("selectedStockunit") || "all"
  );
  const [selectedPacksize, setselectedPacksize] = useState(
    localStorage.getItem("selectedPacksize") || "all"
  );
  const [selectedPacktype, setselectedPacktype] = useState(
    localStorage.getItem("selectedPacktype") || "all"
  );
  const [selectedFlavour, setselectedFlavour] = useState(
    localStorage.getItem("selectedFlavour") || "all"
  );
  const [selectedUnitvol, setselectedUnitvol] = useState(
    localStorage.getItem("selectedUnitvol") || "all"
  );

  const handleExportData = async () => {

    await fetchDataProductMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'category', // Endpoint
      selectedCategory,
      selectedBrand,
      selectedPrincipal,
      selectedStockunit,
      selectedPacksize,
      selectedPacktype,
      selectedFlavour,
      selectedUnitvol,
    );

    await fetchDataProductMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'brand', // Endpoint
      selectedCategory,
      selectedBrand,
      selectedPrincipal,
      selectedStockunit,
      selectedPacksize,
      selectedPacktype,
      selectedFlavour,
      selectedUnitvol,
    );

    await fetchDataProductMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'principal', // Endpoint
      selectedCategory,
      selectedBrand,
      selectedPrincipal,
      selectedStockunit,
      selectedPacksize,
      selectedPacktype,
      selectedFlavour,
      selectedUnitvol,
    );

    await fetchDataProductMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'stock-unit', // Endpoint
      selectedCategory,
      selectedBrand,
      selectedPrincipal,
      selectedStockunit,
      selectedPacksize,
      selectedPacktype,
      selectedFlavour,
      selectedUnitvol,
    );

    await fetchDataProductMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'packsize', // Endpoint
      selectedCategory,
      selectedBrand,
      selectedPrincipal,
      selectedStockunit,
      selectedPacksize,
      selectedPacktype,
      selectedFlavour,
      selectedUnitvol,
    );

    await fetchDataProductMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'packtype', // Endpoint
      selectedCategory,
      selectedBrand,
      selectedPrincipal,
      selectedStockunit,
      selectedPacksize,
      selectedPacktype,
      selectedFlavour,
      selectedUnitvol,
    );

    await fetchDataProductMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'flavour', // Endpoint
      selectedCategory,
      selectedBrand,
      selectedPrincipal,
      selectedStockunit,
      selectedPacksize,
      selectedPacktype,
      selectedFlavour,
      selectedUnitvol,
    );

    await fetchDataProductMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
      'unitvol', // Endpoint
      selectedCategory,
      selectedBrand,
      selectedPrincipal,
      selectedStockunit,
      selectedPacksize,
      selectedPacktype,
      selectedFlavour,
      selectedUnitvol,
    );

    // Panggil fetchExportDataStoreMaster dengan parameter yang sesuai
    fetchExportDataProductMaster(
      accessToken,
      setIsLoading,
      selectedCategory,
      selectedBrand,
      selectedPrincipal,
      selectedStockunit,
      selectedPacksize,
      selectedPacktype,
      selectedFlavour,
      selectedUnitvol,
    );
  };


  // funtionbuttoncreate
  const handleAddCreate = () => {
    setModalAddCreate(true);
  };
  const handleSetupInputChange = (event) => {
    const { name, value } = event.target;
    setNewCategory({
      ...newCategory,
      [name]: value,
    });
    setNewBrand((prevBrand) => ({
      ...prevBrand,
      [name]: value,
    }));
    setNewPrincipal((prevPrincipal) => ({
      ...prevPrincipal,
      [name]: value,
    }));
    setNewStockUnit((prevStockUnit) => ({
      ...prevStockUnit,
      [name]: value,
    }));
    setNewPacksize((prevPacksize) => ({
      ...prevPacksize,
      [name]: value,
    }));
    setNewPacktype((prevPacktype) => ({
      ...prevPacktype,
      [name]: value,
    }));
    setNewFlavour((prevFlavour) => ({
      ...prevFlavour,
      [name]: value,
    }));
    setNewUnitvol((prevUnitvol) => ({
      ...prevUnitvol,
      [name]: value,
    }));
  };

  // functioncreate

  //Category
  const [newCategory, setNewCategory] = useState({
    id: "",
    name: "",
    ord_category: "",
  });
  const handleCreateCategory = async () => {
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/category-master`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newCategory),
        }
      );

      if (response.status === 201) {
        alert("Category Berhasil dibuat!");
        setModalAddCreate(false);
        await fetchProductCategory();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Category Gagal Dibuat!");
      }
    } catch (error) {
      console.error("Error creating Category", error);
    }
  };

  //Brand
  const [newBrand, setNewBrand] = useState({
    id: "",
    name: "",
  });
  const handleCreateBrand = async () => {
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/brand-master`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newBrand),
        }
      );

      if (response.status === 201) {
        alert("Brand Berhasil dibuat!");
        setModalAddCreate(false);
        await fetchProductBrand();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Brand Gagal Dibuat!");
      }
    } catch (error) {
      console.error("Error creating Brand", error);
    }
  };

  //Principal
  const [newPrincipal, setNewPrincipal] = useState({
    id: "",
    name: "",
  });
  const handleCreatePrincipal = async () => {
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/principal-master`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newPrincipal),
        }
      );

      if (response.status === 201) {
        alert("Principal Berhasil dibuat!");
        setModalAddCreate(false);
        await fetchProductPrincipal();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Principal Gagal Dibuat!");
      }
    } catch (error) {
      console.error("Error creating Principal", error);
    }
  };

  //STOCKUNIT
  const [newStockUnit, setNewStockUnit] = useState({
    id: "",
    description: "",
    unit_code: "",
    weight: "",
    unit: "",
  });
  const handleCreateStockUnit = async () => {
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/stock-unit`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newStockUnit),
        }
      );

      if (response.status === 201) {
        alert("Stockunit Berhasil dibuat!");
        setModalAddCreate(false);
        await fetchStockUnit();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Stockunit Gagal Dibuat!");
      }
    } catch (error) {
      console.error("Error creating Stockunit", error);
    }
  };

  //Packsize
  const [newPacksize, setNewPacksize] = useState({
    id: "",
    desc: "",
  });
  const handleCreatePacksize = async () => {
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/packsize`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newPacksize),
        }
      );

      if (response.status === 201) {
        alert("packsize Berhasil dibuat!");
        setModalAddCreate(false);
        await fetchPacksize();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("packsize Gagal Dibuat!");
      }
    } catch (error) {
      console.error("Error creating packsize", error);
    }
  };

  //Packtype
  const [newPacktype, setNewPacktype] = useState({
    id: "",
    desc: "",
  });
  const handleCreatePacktype = async () => {
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/packtype`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newPacktype),
        }
      );

      if (response.status === 201) {
        alert("packtype Berhasil dibuat!");
        setModalAddCreate(false);
        await fetchPacktype();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("packtype Gagal Dibuat!");
      }
    } catch (error) {
      console.error("Error creating packsize", error);
    }
  };

  //Flavour
  const [newFlavour, setNewFlavour] = useState({
    id: "",
    desc: "",
  });
  const handleCreateFlavour = async () => {
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/flavour`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newFlavour),
        }
      );

      if (response.status === 201) {
        alert("flavour Berhasil dibuat!");
        setModalAddCreate(false);
        await fetchFlavour();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("flavour Gagal Dibuat!");
      }
    } catch (error) {
      console.error("Error creating flavour", error);
    }
  };

  //Unitvol
  const [newUnitvol, setNewUnitvol] = useState({
    id: "",
    desc: "",
  });
  const handleCreateUnitvol = async () => {
    try {
      const token = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/unitvol`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newUnitvol),
        }
      );

      if (response.status === 201) {
        alert("UnitVol Berhasil dibuat!");
        setModalAddCreate(false);
        await fetchUnitvol();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("UnitVol Gagal Dibuat!");
      }
    } catch (error) {
      console.error("Error creating UnitVol", error);
    }
  };


  {/*Function Update */ }

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  }

  {/*Update Category */ }

  const HandleEditCategory = async (params) => {
    setEditCategoryId(params.row.id);
    setEditModalOpen(true);
    setEditedCategory(params.row);
  }

  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editedCategory, setEditedCategory] = useState({
    name: "",
    ord_category: "",
  });

  const handleUpdateCategory = async () => {
    setIsLoading(true);
    if (editCategoryId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/category-master/${editCategoryId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedCategory),
          }
        );

        if (response.status === 200) {
          alert("Category Berhasil di update");
          setEditModalOpen(false);
          await fetchProductCategory();
        } else {
          alert("Category gagal di update")
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  {/**UpdateBrand */ }

  const HandleEditBrand = async (params) => {
    setEditBrandId(params.row.id);
    setEditModalOpen(true);
    setEditedBrand(params.row);
  }
  const [editBrandId, setEditBrandId] = useState(null);
  const [editedBrand, setEditedBrand] = useState(
    {
      name: '',
    });
  const handleUpdateBrand = async () => {
    setIsLoading(true);
    if (editBrandId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/brand-master/${editBrandId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedBrand),
          }
        );

        if (response.status === 200) {
          alert("Brand Berhasil di update");
          setEditModalOpen(false);
          await fetchProductBrand();
        } else {
          alert("Brand gagal di update")
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  {/**UpdatePrincipal */ }

  const HandleEditPrincipal = async (params) => {
    setEditPrincipalId(params.row.id);
    setEditModalOpen(true);
    setEditedPrincipal(params.row);
  }
  const [editPrincipalId, setEditPrincipalId] = useState(null);
  const [editedPrincipal, setEditedPrincipal] = useState({ name: '' });
  const handleUpdatePrincipal = async () => {
    setIsLoading(true);
    if (editPrincipalId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/principal-master/${editPrincipalId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedPrincipal),
          }
        );

        if (response.status === 200) {
          alert("Principal Berhasil di update");
          setEditModalOpen(false);
          await fetchProductPrincipal();
        } else {
          alert("Principal gagal di update")
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  {/**Update StockUnit */ }

  const HandleEditStockunit = async (params) => {
    setEditStockunitId(params.row.id);
    setEditModalOpen(true);
    setEditedStockunit(params.row);
  }
  const [editStockunitId, setEditStockunitId] = useState(null);
  const [editedStockunit, setEditedStockunit] = useState({
    description: '',
    unit: '',
    weight: '',
  });
  const handleUpdateStockunit = async () => {
    setIsLoading(true);
    if (editStockunitId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/stock-unit/${editStockunitId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStockunit),
          }
        );

        if (response.status === 200) {
          alert("Stockunit Berhasil di update");
          setEditModalOpen(false);
          await fetchStockUnit();
        } else {
          alert("Stockunit gagal di update")
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  {/**UpdatePacksize */ }

  const HandleEditPacksize = async (params) => {
    setEditPacksizeId(params.row.id);
    setEditModalOpen(true);
    setEditedPacksize(params.row);
  }
  const [editPacksizeId, setEditPacksizeId] = useState(null);
  const [editedPacksize, setEditedPacksize] = useState(
    {
      desc: '',
    });
  const handleUpdatePacksize = async () => {
    setIsLoading(true);
    if (editPacksizeId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/packsize/${editPacksizeId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedPacksize),
          }
        );

        if (response.status === 200) {
          alert("Packsize Berhasil di update");
          setEditModalOpen(false);
          await fetchPacksize();
        } else {
          alert("Packsize gagal di update")
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  {/**UpdatePacktype */ }

  const HandleEditPacktype = async (params) => {
    setEditPacktypeId(params.row.id);
    setEditModalOpen(true);
    setEditedPacktype(params.row);
  }
  const [editPacktypeId, setEditPacktypeId] = useState(null);
  const [editedPacktype, setEditedPacktype] = useState(
    {
      desc: '',
    });
  const handleUpdatePacktype = async () => {
    setIsLoading(true);
    if (editPacktypeId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/packtype/${editPacktypeId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedPacktype),
          }
        );

        if (response.status === 200) {
          alert("Packtype Berhasil di update");
          setEditModalOpen(false);
          await fetchPacktype();
        } else {
          alert("Packtype gagal di update")
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  {/**UpdateFlavour */ }

  const HandleEditFlavour = async (params) => {
    setEditFlavourId(params.row.id);
    setEditModalOpen(true);
    setEditedFlavour(params.row);
  }
  const [editFlavourId, setEditFlavourId] = useState(null);
  const [editedFlavour, setEditedFlavour] = useState(
    {
      desc: '',
    });
  const handleUpdateFlavour = async () => {
    setIsLoading(true);
    if (editFlavourId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/flavour/${editFlavourId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedFlavour),
          }
        );

        if (response.status === 200) {
          alert("Flavour Berhasil di update");
          setEditModalOpen(false);
          await fetchFlavour();
        } else {
          alert("Flavour gagal di update")
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  {/**UpdateFlavour */ }

  const HandleEditUnitvol = async (params) => {
    setEditUnitvolId(params.row.id);
    setEditModalOpen(true);
    setEditedUnitvol(params.row);
  }
  const [editUnitvolId, setEditUnitvolId] = useState(null);
  const [editedUnitvol, setEditedUnitvol] = useState(
    {
      desc: '',
    });
  const handleUpdateUnitvol = async () => {
    setIsLoading(true);
    if (editUnitvolId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/unitvol/${editUnitvolId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedUnitvol),
          }
        );

        if (response.status === 200) {
          alert("Unitvol Berhasil di update");
          setEditModalOpen(false);
          await fetchUnitvol();
        } else {
          alert("Unitvol gagal di update")
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };


  //function serach bar
  const handleTableSelection = (table) => {
    setSelectedTable(table);
    setSearchBarValue(table);
    setSelectedOption(table);
    setselectedUpdate(table);
  }

  useEffect(() => {
    fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, ["13", "14"]);
    fetchProductCategory();
    fetchProductBrand();
    fetchProductPrincipal();
    fetchStockUnit();
    fetchPacksize();
    fetchPacktype();
    fetchFlavour();
    fetchUnitvol();
  }, [accessToken]);

  useEffect(() => {
    fetchDataProductMaster(
      accessToken,
      setIsLoading,
      setFilteredRows,
    );
  }, [accessToken]);


  //import
  const [fileUrl, setFileUrl] = useState('');
  const [excelFile, setExcelFile] = useState('');

  const handleImport = () => {
    setOpenDialogImport(true);
  };
  const handleCloseDialog = () => {
    setOpenDialogImport(false);
    setFileUrl('');
    setExcelFile('');
  };

  // export product master setup template
  async function exportproductmastersetupTemplate() {
    setIsLoading(true);
    const blob = await exportproductmastersetup(accessToken, 'template', 'api/v1/product-master/export/setup_template', {});
    if (blob) {
      // throw data as blob then download it
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Template Product Master Setup.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      toast.error('Gagal mendownload template Setup Product Master!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  }

  // import setup Product master
  async function importProductmastersetupTemplate() {
    try {
      const formData = new FormData();
      formData.append("file", excelFile);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/product-master/import_product_master_setup`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        toast.success('Import Product Master Setup berhasil dibuat!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        // refresh
        fetchProductCategory();
        fetchProductBrand();
        fetchProductPrincipal();
        fetchStockUnit();
        fetchPacksize();
        fetchPacktype();
        fetchFlavour();
        fetchUnitvol();
        // setFileUrl('')

        // close 
        setOpenDialogImport(false);
      } else if (response.status === 200) {
        // throw data as blob then download it when validation on excel got fail
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Product Master Setup Error.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        toast.error('Ada masalah di excel. Periksa kembali isi excel!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error('Gagal mengimport Product Master Setup!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error('Failed to import Product Master Setup template:', response.statusText);
      }
    } catch (error) {
      console.error('Error importing Product Master Setup template:', error);
    }
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    importProductmastersetupTemplate()
    setIsLoading(false);
  };

  return (
    <div>
      <Navbar
        active="Setup Product Master"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div style={{ padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "30px",
            marginLeft: "20px"
          }}>
          <SearchBarSetupProduct
            label="Pilih"
            data={[
              { label: "Category", value: "Category" },
              { label: "Brand", value: "Brand" },
              { label: "Principal", value: "Principal" },
              { label: "StockUnit", value: "StockUnit" },
              // { label: "Packsize", value: "Packsize" },
              // { label: "Packtype", value: "Packtype" },
              // { label: "Flavour", value: "Flavour" },
              { label: "Unitvol", value: "Unitvol" },
            ]}
            onSelectTable={(table) => handleTableSelection(table)}
            value={searchBarValue}
          />

          {/* button */}
          <div className="button-setup" style={{ display: "flex", gap: "5px" }}>
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleExportData}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Export"}
            </BootstrapButton>
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleAddCreate}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Add"}
            </BootstrapButton>
            <BootstrapButton
              onClick={() => handleImport()}
              variant="contained"
              disableRipple
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Import"}
            </BootstrapButton>
          </div>

        </div>
        <div>
          {selectedTable === 'Category' && datacategory && datacategory.length > 0 ? (
            <DataTable
              rows={datacategory}
              columns={Category}
              setRowsData={setdatacategory}
              setRowModesModel={setRowModesModel}
            />
          ) : selectedTable === 'Brand' && dataBrand && dataBrand.length > 0 ? (
            <DataTable
              rows={dataBrand}
              columns={Brand}
              setRows={setdataBrand}
              setRowModesModel={setRowModesModel}
            />
          ) : selectedTable === "Principal" && dataPrincipal && dataPrincipal.length > 0 ? (
            <DataTable
              getRowId={(row) => row.id}
              rows={dataPrincipal}
              columns={Principal}
              setRows={setdataPrincipal}
              setRowModesModel={setRowModesModel}
            />
          ) : selectedTable === "StockUnit" && dataStockUnit && dataStockUnit.length > 0 ? (
            <DataTable
              getRowId={(row) => row.id}
              rows={dataStockUnit}
              columns={Stockunit}
              setRows={setdataStockUnit}
              setRowModesModel={setRowModesModel}
            />
          ) : selectedTable === "Packsize" && dataPacksize && dataPacksize.length > 0 ? (
            <DataTable
              getRowId={(row) => row.id}
              rows={dataPacksize}
              columns={Packsize}
              setRows={setdataPacksize}
              setRowModesModel={setRowModesModel}
            />
          ) : selectedTable === "Packtype" && dataPacktype && dataPacktype.length > 0 ? (
            <DataTable
              getRowId={(row) => row.id}
              rows={dataPacktype}
              columns={Packtype}
              setRows={setdataPacktype}
              setRowModesModel={setRowModesModel}
            />
          ) : selectedTable === "Flavour" && dataFlavour && dataFlavour.length > 0 ? (
            <DataTable
              getRowId={(row) => row.id}
              rows={dataFlavour}
              columns={Flavour}
              setRows={setdataFlavour}
              setRowModesModel={setRowModesModel}
            />
          ) : selectedTable === "Unitvol" && dataUnitvol && dataUnitvol.length > 0 ? (
            <DataTable
              getRowId={(row) => row.id}
              rows={dataUnitvol}
              columns={Unitvol}
              setRows={setdataUnitvol}
              setRowModesModel={setRowModesModel}
            />
          ) : null}
        </div>

        {/* create product */}
        <Modal
          open={modalAddCreate}
          onClose={() => setModalAddCreate(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ border: 0 }}
        >
          <Box
            sx={{
              ...style,
              width: 900,
              margin: "0 auto",
              maxHeight: "700px",
              overflow: "auto",
              overflowX: "hidden",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Tooltip title="Close Modal">
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setModalAddCreate(false)}
                sx={{ position: "absolute", top: 20, right: 30 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            {selectedOption === "Category" && (
              <div className='Create-Category'>
                <h2 className='mb-2'>Create Category</h2>
                <div>
                  <TextField
                    label="ID"
                    name='id'
                    value={newCategory.id}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="NAME"
                    name='name'
                    value={newCategory.name}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="ORD CATEGORY"
                    name='ord_category'
                    value={newCategory.ord_category}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                    onClick={handleCreateCategory}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : "Submit"}
                  </BootstrapButton>
                </div>
              </div>
            )}
            {selectedOption === "Brand" && (
              <div className='Create-Brand'>
                <h2 className='mb-2'>Create Brand</h2>
                <div>
                  <TextField
                    label="ID"
                    name='id'
                    value={newBrand.id}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="NAME"
                    name='name'
                    value={newBrand.name}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                    onClick={handleCreateBrand}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : "Submit"}
                  </BootstrapButton>
                </div>
              </div>
            )}
            {selectedOption === "Principal" && (
              <div className='Create-Brand'>
                <h2 className='mb-2'>Create Principal</h2>
                <div>
                  <TextField
                    label="ID"
                    name='id'
                    value={newPrincipal.id}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="NAME"
                    name='name'
                    value={newPrincipal.name}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                    onClick={handleCreatePrincipal}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : "Submit"}
                  </BootstrapButton>
                </div>
              </div>
            )}
            {selectedOption === "StockUnit" && (
              <div className='Create-Brand'>
                <h2 className='mb-2'>Create StockUnit</h2>
                <div>
                  <TextField
                    label="ID"
                    name='id'
                    value={newStockUnit.id}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="CODE"
                    name='unit_code'
                    value={newStockUnit.unit_code}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="DESCRIPTION"
                    name='description'
                    value={newStockUnit.description}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="UNIT"
                    name='unit'
                    value={newStockUnit.unit}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="WEIGHT"
                    name='weight'
                    value={newStockUnit.weight}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                    onClick={handleCreateStockUnit}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : "Submit"}
                  </BootstrapButton>
                </div>
              </div>
            )}
            {selectedOption === "Packsize" && (
              <div className='Create-Brand'>
                <h2 className='mb-2'>Create Packsize</h2>
                <div>
                  <TextField
                    label="ID"
                    name='id'
                    value={newPacksize.id}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="DESCRIPTION"
                    name='desc'
                    value={newPacksize.desc}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                    onClick={handleCreatePacksize}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : "Submit"}
                  </BootstrapButton>
                </div>
              </div>
            )}
            {selectedOption === "Packtype" && (
              <div className='Create-Brand'>
                <h2 className='mb-2'>Create Packsize</h2>
                <div>
                  <TextField
                    label="ID"
                    name='id'
                    value={newPacktype.id}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="DESCRIPTION"
                    name='desc'
                    value={newPacktype.desc}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                    onClick={handleCreatePacktype}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : "Submit"}
                  </BootstrapButton>
                </div>
              </div>
            )}
            {selectedOption === "Flavour" && (
              <div className='Create-Flavour'>
                <h2 className='mb-2'>Create Flavour</h2>
                <div>
                  <TextField
                    label="ID"
                    name='id'
                    value={newFlavour.id}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="DESCRIPTION"
                    name='desc'
                    value={newFlavour.desc}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                    onClick={handleCreateFlavour}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : "Submit"}
                  </BootstrapButton>
                </div>
              </div>
            )}
            {selectedOption === "Unitvol" && (
              <div className='Create-Unitvol'>
                <h2 className='mb-2'>Create Unitvol</h2>
                <div>
                  <TextField
                    label="ID"
                    name='id'
                    value={newUnitvol.id}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <TextField
                    label="DESCRIPTION"
                    name='desc'
                    value={newUnitvol.desc}
                    onChange={handleSetupInputChange}
                    sx={{ width: "100%", mb: 2 }}
                  />
                </div>
                <div>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                    onClick={handleCreateUnitvol}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : "Submit"}
                  </BootstrapButton>
                </div>
              </div>
            )}
          </Box>
        </Modal>

        {/* Update Product */}
        <Modal
          open={editModalOpen}
          onClose={() => handleCloseEditModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ border: 0 }}
        >
          <Box
            sx={{
              ...style,
              maxWidth: 800,
              margin: "0 auto",
              maxHeight: "700px",
              overflow: "auto",
              overflowX: "hidden",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {selectedOption === "Category" && (
              <div className='Category-Update'>
                <h2>Edit Category</h2>
                <div>
                  <TextField
                    name="name"
                    label="Name"
                    value={editedCategory.name ?? ''}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedCategory({
                        ...editedCategory,
                        name: e.target.value,
                      })}
                  ></TextField>
                  <TextField
                    name="ord_category"
                    label="Ord Category"
                    value={editedCategory.ord_category ?? ''}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedCategory({
                        ...editedCategory,
                        ord_category: e.target.value,
                      })}
                  ></TextField>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant='contained'
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateCategory}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading...' : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              </div>
            )}
            {selectedOption === "Brand" && (
              <div className='Brand-Update'>
                <h2>Edit Brand</h2>
                <div>
                  <TextField
                    name="name"
                    label="Name"
                    value={editedBrand.name}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedBrand({
                        ...editedBrand,
                        name: e.target.value,
                      })}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant='contained'
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateBrand}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading...' : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              </div>
            )}
            {selectedOption === "Principal" && (
              <div className='Principal-Update'>
                <h2>Edit Principal</h2>
                <div>
                  <TextField
                    name="name"
                    label="Name"
                    value={editedPrincipal.name}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedPrincipal({
                        ...editedPrincipal,
                        name: e.target.value,
                      })}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant='contained'
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdatePrincipal}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading...' : "Update"}
                    </BootstrapButton>
                  </div>
                </div>
              </div>
            )}
            {selectedOption === "StockUnit" && (
              <div className='Stockunit-Update'>
                <h2>Edit Stockunit</h2>
                <div>
                  <TextField
                    name="description"
                    label="Description"
                    value={editedStockunit.description}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedStockunit({
                        ...editedStockunit,
                        description: e.target.value,
                      })}
                  />
                  <TextField
                    name="unit"
                    label="Unit"
                    value={editedStockunit.unit}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedStockunit({
                        ...editedStockunit,
                        unit: e.target.value,
                      })}
                  />
                  <TextField
                    name="weight"
                    label="Weight"
                    value={editedStockunit.weight}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedStockunit({
                        ...editedStockunit,
                        weight: e.target.value,
                      })}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant='contained'
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateStockunit}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading...' : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              </div>
            )}
            {selectedOption === "Packsize" && (
              <div className='Packsize-Update'>
                <h2>Edit Packsize</h2>
                <div>
                  <TextField
                    name="desc"
                    label="Desciption"
                    value={editedPacksize.desc}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedPacksize({
                        ...editedPacksize,
                        desc: e.target.value,
                      })}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant='contained'
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdatePacksize}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading...' : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              </div>
            )}
            {selectedOption === "Packtype" && (
              <div className='Packtype-Update'>
                <h2>Edit Packtype</h2>
                <div>
                  <TextField
                    name="desc"
                    label="Description"
                    value={editedPacktype.desc}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedPacktype({
                        ...editedPacktype,
                        desc: e.target.value,
                      })}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant='contained'
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdatePacktype}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading...' : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              </div>
            )}
            {selectedOption === "Flavour" && (
              <div className='Flavour-Update'>
                <h2>Edit Flavour</h2>
                <div>
                  <TextField
                    name="desc"
                    label="Description"
                    value={editedFlavour.desc}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedFlavour({
                        ...editedFlavour,
                        desc: e.target.value,
                      })}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant='contained'
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateFlavour}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading...' : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              </div>
            )}
            {selectedOption === "Unitvol" && (
              <div className='Unitvol-Update'>
                <h2>Edit Unitvol</h2>
                <div>
                  <TextField
                    name="desc"
                    label="Description"
                    value={editedUnitvol.desc}
                    sx={{ width: "100%", mb: 2 }}
                    onChange={(e) =>
                      setEditedUnitvol({
                        ...editedUnitvol,
                        desc: e.target.value,
                      })}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant='contained'
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateUnitvol}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading...' : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              </div>
            )}
          </Box>
        </Modal>
        <ModalTableCategory
        open={openModalCategory}
        handleClose={handleCloseViewCategory}
        selectedCategoryId={selectedCategoryId}
        selectedInformation={selectedInformation}
      />
        <ImportModalSetup
          open={openDialogImport}
          onClose={handleCloseDialog}
          title={'Import Setup Product Master'}
          buttonTitle={'Upload'}
          fileUrl={fileUrl}
          handleSubmit={handleUpload}
          handleChange={{
            excel: setExcelFile,
            fileUrl: setFileUrl,
          }}
          handleExport={exportproductmastersetupTemplate}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default SetUpMasterProduct; 
