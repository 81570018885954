import { setAuditPlans, setTotal, setAuditPlanColumn } from "../redux/slices/auditPlanSlice";

export const fetchAuditPlans = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '' && value !== undefined)
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/audit-plan`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setAuditPlans(data.data));
      dispatch(setTotal(data.pagination_info.total));
    } else {
      dispatch(setAuditPlans([]));
      console.error(`Failed to fetch audit plans:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching audit plans:`, error);
  }
};

export const fetchAuditPlanColumn = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '' && value !== undefined)
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/audit-plan-columns`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setAuditPlanColumn(data.data));
    } else {
      dispatch(setAuditPlanColumn([]));
      console.error(`Failed to fetch audit plan column:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching audit plan column:`, error);
  }
};
