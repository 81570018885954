import { configureStore } from "@reduxjs/toolkit";
import processManagementReducer from "./slices/processManagementSlice";
import projectReducer from "./slices/projectSlice";
import generalReducer from "./slices/generalSlice";
import profileReducer from "./slices/profileSlice";
import storeMasterReducer from "./slices/storeMasterSlice";
import storeTypeReducer from "./slices/storeTyoeSlice";
import provinceReducer from "./slices/provinceSlice";
import jenisOutletReducer from "./slices/jenisOutletSlice";
import tipeSurveyReducer from "./slices/tipeSurveySlice";
import tipeLokasiReducer from "./slices/tipeLokasiSlice";
import tipeJalanReducer from "./slices/tipeJalanSlice";
import rawDataReducer from "./slices/reportDBRawDataSlice";
import tipeProjectReducer from "./slices/tipeProjectSlice"
import productMasterReducer from "./slices/productMasterSlice";
import consistencyProductReducer from "./slices/consistencyProductSlice";
import consistencyStoreReducer from "./slices/consistencyStoreSlice";
import completenessProductReducer from "./slices/completenessProductSlice";
import completenessStoreReducer from "./slices/completenessStoreSlice";
import auditPlanReducer from "./slices/auditPlanSlice";
import projectManagementReducer from "./slices/projectManagementSlice";
import dataSurveyReducer from "./slices/dataSurveySlice";
import { map } from "highcharts";

const store = configureStore({
    reducer: { 
      general: generalReducer,
      profile: profileReducer,
      province: provinceReducer,
      processManagement: processManagementReducer,
      project: projectReducer,
      storeMaster: storeMasterReducer,
      storeType: storeTypeReducer,
      jenisOutlet: jenisOutletReducer,
      tipeSurvey: tipeSurveyReducer,
      tipeJalan: tipeJalanReducer,
      tipeLokasi: tipeLokasiReducer,
      rawData: rawDataReducer,
      tipeProject: tipeProjectReducer,
      productMaster: productMasterReducer,
      consistencyProduct: consistencyProductReducer,
      consistencyStore: consistencyStoreReducer,
      completenessProduct: completenessProductReducer,
      completenessStore: completenessStoreReducer,
      auditPlan: auditPlanReducer,
      projectManagement: projectManagementReducer,
      dataSurvey: dataSurveyReducer,
    },
});

export default store;
