import React from 'react'
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Button
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";

function EditDataModal({
  isLoading, editedAuditPlan,
  editModalOpen, setEditedAuditPlan, modalStyle,
  dataUserSurveyorOptions, dataUserQcOptions,
  handleUpdateAuditPlan, handleCloseEditModal
}) {
  return (
    <Modal
      open={editModalOpen}
      onClose={handleCloseEditModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box
        sx={modalStyle}
        style={{
          maxHeight: "400px",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <h2>Edit Audit Plan</h2>
        <p>
          Outlet {editedAuditPlan.store_code} -{" "}
          {editedAuditPlan.store_name}
        </p>
        <div>
          { editedAuditPlan.audit_status === 'Not Started' ||
            editedAuditPlan.audit_status === 'On Progress' ||
            editedAuditPlan.audit_status === 'Valid' ?
            <div>
              <TextField
                name="user_name_surveyor"
                label="User Full Name Surveyor"
                select
                sx={{ width: "100%", mb: 2 }}
                value={editedAuditPlan.user_name_surveyor}
                onChange={(e) =>
                  setEditedAuditPlan({
                    ...editedAuditPlan,
                    user_name_surveyor: e.target.value,
                  })
                }
              >
                {dataUserSurveyorOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                name="user_name_qc"
                label="User Full Name QC"
                select
                sx={{ width: "100%", mb: 2 }}
                value={editedAuditPlan.user_name_qc}
                onChange={(e) =>
                  setEditedAuditPlan({
                    ...editedAuditPlan,
                    user_name_qc: e.target.value,
                  })
                }
              >
                <MenuItem key={'-'} value={''}>
                  -
                </MenuItem>
                {dataUserQcOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            : null}
          <TextField
            name="audit_start_at"
            label="Audit Start Date"
            type="date"
            value={editedAuditPlan.audit_start_at}
            sx={{ width: "100%", mb: 2 }}
            onChange={(e) =>
              setEditedAuditPlan({
                ...editedAuditPlan,
                audit_start_at: e.target.value,
              })
            }
          />
          <TextField
            name="audit_end_at"
            label="Audit End Date"
            type="date"
            value={editedAuditPlan.audit_end_at}
            sx={{ width: "100%", mb: 2 }}
            onChange={(e) =>
              setEditedAuditPlan({
                ...editedAuditPlan,
                audit_end_at: e.target.value,
              })
            }
          />
        </div>
        <div style={{display: "flex", justifyContent: "space-between"}}>
        <Button
          variant="outlined"
          disableRipple
          sx={{
            width: 190,
            height: 50,
            mt: 2,
            color: "#b34469",
            borderColor: "#b34469",
          }}
          onClick={handleCloseEditModal}
        >
          Cancel
        </Button>
        <BootstrapButton
          variant="contained"
          disableRipple
          sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
          onClick={handleUpdateAuditPlan}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "UPDATE"}
        </BootstrapButton>
        </div>
      </Box>
    </Modal>
  )
}

export default EditDataModal
