import React from "react";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CalculateIcon from "@mui/icons-material/Calculate";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";

import {
  fetchProductMaster,
  fetchProductMasterColumn,
} from "../../../services/productMasterService";
import {
  fetchPackSize,
  fetchPackType,
  fetchFlavour,
} from "../../ProductMasterTwo/FilterService";
import {
  fetchStoreMaster,
  fetchStoreMasterById,
} from "../../../services/storeMasterService";
import {
  fetchDistrict,
  fetchProvince,
  fetchRegency,
  fetchSubDistrict,
} from "../../../services/areaNewService";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";

import { ModalEditStoreMaster } from "../../../Components/Fragments/Modals/ModalStoreMaster";
import {
  CompletenessProductModal,
  CompletenessProductModalMT,
  CompletenessStoreGTModal,
  CompletenessStoreMTModal,
} from "../../../Components/Fragments/Modals/ModalCompleteness";
import {
  ConsistencyProductModal,
  ConsistencyProductModalMT,
  ConsistencyStoreGTModal,
  ConsistencyStoreMTModal,
} from "../../../Components/Fragments/Modals/ModalConsistensy";
import { RawDataModal } from "../../../Components/Fragments/Modals/ModalRawData";
import ModalConfirmOk from "../../../Components/Fragments/Modals/ModalConfirmOk";
import ModalProcessPreparationStep from "../../../Components/Fragments/Modals/ModalProcessPreparationStep";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#069dae",
    color: theme.palette.common.white,
    border: "solid 1px #fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const PreparationTable = ({
  preparationData,
  projectType,
  currentPeriod,
  previousPeriod,
  handleRefresh,
}) => {
  const dispatch = useDispatch();
  const itemsPerPageList = [5, 10, 50, 100];
  const token = Cookies.get("access_token") || "";
  const [isLoading, setIsLoading] = useState(false);

  const currentPage = useSelector((state) => state.storeMaster.currentPage);
  const itemsPerPage = useSelector((state) => state.storeMaster.itemsPerPage);
  const selectedStatusId = useSelector(
    (state) => state.storeMaster.selectedStatusId
  );
  // selected filter
  const selectedTipeSurvey = useSelector(
    (state) => state.storeMaster.selectedTipeSurvey
  );
  const selectedProvinceId = useSelector(
    (state) => state.storeMaster.selectedProvinceId
  );
  const selectedRegencyId = useSelector(
    (state) => state.storeMaster.selectedRegencyId
  );
  const selectedDistrictId = useSelector(
    (state) => state.storeMaster.selectedDistrictId
  );
  const selectedSubDistrictId = useSelector(
    (state) => state.storeMaster.selectedSubDistrictId
  );
  const filterColumnName = useSelector(
    (state) => state.storeMaster.filterColumnName
  );
  const filterColumnOperator = useSelector(
    (state) => state.storeMaster.filterColumnOperator
  );
  const filterColumnValue = useSelector(
    (state) => state.storeMaster.filterColumnValue
  );

  const [openTableCompleteness, setOpenTableCompleteness] = useState(false);
  const [openTableCompletenessProductMT, setOpenTableCompletenessProductMT] =
    useState(false);
  const [openTableCompletenessStoreGT, setOpenTableCompletenessStoreGT] =
    useState(false);
  const [openTableCompletenessStoreMT, setOpenTableCompletenessStoreMT] =
    useState(false);

  const [openTableConsistency, setOpenTableConsistency] = useState(false);
  const [openTableConsistencyProductMT, setOpenTableConsistencyProductMT] =
    useState(false);
  const [openTableConsistencyStoreGT, setOpenTableConsistencyStoreGT] =
    useState(false);
  const [openTableConsistencyStoreMT, setOpenTableConsistencyStoreMT] =
    useState(false);
  const [openRawDataModal, setOpenRawDataModal] = useState(false);
  const [remarkDataValidationRtaGT, setRemarkDataValidationRtaGT] =
    useState("");

  const dataStoreType = useSelector((state) => state.storeType.data);
  const dataProvince = useSelector((state) => state.province.data);
  const dataStatus = useSelector(
    (state) => state.storeMaster.storeMasterStatus
  );
  const dataJenisOutlet = useSelector((state) => state.jenisOutlet.data);
  const dataTipeSurvey = useSelector((state) => state.tipeSurvey.data);
  const dataTipeJalan = useSelector((state) => state.tipeJalan.data);
  const dataTipeLokasi = useSelector((state) => state.tipeLokasi.data);

  const selectedProductGroupId = useSelector(
    (state) => state.productMaster.selectedProductGroupId
  );
  const selectedCategory = useSelector(
    (state) => state.productMaster.selectedCategory
  );
  const selectedPrincipal = useSelector(
    (state) => state.productMaster.selectedPrincipal
  );
  const selectedBrand = useSelector(
    (state) => state.productMaster.selectedBrand
  );

  const [dataBrandMaster, setDataBrandMaster] = useState([]);
  const [dataPackType, setDataPackType] = useState([]);
  const [dataPackSize, setDataPackSize] = useState([]);
  const [dataUnitVol, setDataUnitVol] = useState([]);
  const [dataStockUnit, setDataStockUnit] = useState([]);
  const [dataCategoryMaster, setDataCategoryMaster] = useState([]);
  const [dataPrincipalMaster, setDataPrincipalMaster] = useState();
  const [dataPrincipalFilter, setDataPrincipalFilter] = useState([]);

  const [storeNameOptions, setStoreNameOptions] = useState([]);
  const [storeNameOptions2, setStoreNameOptions2] = useState([]);

  const accessToken = useSelector((state) => state.general.accessToken);

  const [formData, setFormData] = useState({
    id: "",
    store_id: "",
    store_name: "",
    store_type_code: "",
    store_address: "",
    store_latitude: "",
    store_longitude: "",
    province_code: "",
    regency_code: "",
    district_code: "",
    sub_district_code: "",
    jenis_outlet: "",
    tipe_survey: "",
    tipe_jalan: "",
    tipe_lokasi: "",
  });

  // data dynamic
  const [dataRegency, setDataRegency] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);

  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  // edit or update
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedProduct, setEditedProduct] = useState({});
  const [editProductId, setEditProductId] = useState(null);

  const handleEditProduct = async (id) => {
    setEditedProduct(await fetchProductMasterSpecific(id));
    setEditProductId(id);
    setEditModalOpen(true);
  };

  const stockUnitDescriptionToIdMap = {};
  dataStockUnit.forEach((item) => {
    stockUnitDescriptionToIdMap[item.description] = item.id;
  });

  const ProductGroupLabelToValueMap = {};
  storeNameOptions2.forEach((item) => {
    ProductGroupLabelToValueMap[item.label] = item.value;
  });

  function findNewAndOldItems(newArray, oldArray) {
    const sortedNewArray = newArray.slice().sort();
    const sortedOldArray = oldArray.slice().sort();
    return { sortedNewArray, sortedOldArray };
  }

  function findAddedAndRemovedItemGroups(newArray, oldArray) {
    const addedItemGroups = newArray.filter((item) => !oldArray.includes(item));
    const removedItemGroups = oldArray.filter(
      (item) => !newArray.includes(item)
    );
    return { addedItemGroups, removedItemGroups };
  }

  const handleUpdateProduct = async () => {
    if (editProductId) {
      const token = Cookies.get("access_token");
      const { sortedNewArray, sortedOldArray } = findNewAndOldItems(
        editedStock,
        selectedOldStock
      );
      if (sortedOldArray.length > 0) {
        try {
          // unassign
          // Create a new FormData object
          const formData = new FormData();

          const stockUnitIds = sortedOldArray.map(
            (description) => stockUnitDescriptionToIdMap[description]
          );
          formData.append("stock_unit_ids", stockUnitIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/stock-unit/unassign`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (!response.ok) {
            alert("Stock gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }

      if (sortedNewArray.length > 0) {
        try {
          // assign
          // Create a new FormData object
          const formData = new FormData();

          // Add the stock_unit_ids to the form data
          const stockUnitIds = sortedNewArray.map(
            (description) => stockUnitDescriptionToIdMap[description]
          );
          formData.append("stock_unit_ids", stockUnitIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/stock-unit/assign`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Stock berhasil diubah!");
            setEditModalOpen(false);
          } else {
            alert("Stock gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }

      const { addedItemGroups, removedItemGroups } =
        findAddedAndRemovedItemGroups(editedGroup, selectedOldGroup);
      if (addedItemGroups.length > 0) {
        try {
          // assign
          // Create a new FormData object
          const formData = new FormData();

          // Add the groups_ids to the form data
          const groupIds = addedItemGroups.map(
            (label) => ProductGroupLabelToValueMap[label]
          );
          formData.append("product_group_ids", groupIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/group/assign`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Group berhasil ditambah!");
          } else {
            alert("Group gagal ditambah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      if (removedItemGroups.length > 0) {
        try {
          // unassign
          // Create a new FormData object
          const formData = new FormData();

          const groupIds = removedItemGroups.map(
            (label) => ProductGroupLabelToValueMap[label]
          );
          formData.append("product_group_ids", groupIds);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}/group/unassign`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (response.ok) {
            alert("Group berhasil diubah!");
            setEditModalOpen(false);
          } else {
            alert("Group gagal diubah!");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/product-master/${editProductId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedProduct),
          }
        );

        const message = await response.json();

        if (response.ok) {
          alert("Produk berhasil diubah!");
          setEditedProduct(await fetchProductMasterSpecific(editProductId));
          window.location.reload();
        } else if (response.status === 404) {
          alert(message.detail);
        } else if (response.status === 400) {
          alert(message.detail);
        } else {
          alert("Produk gagal diubah!");
        }
      } catch (error) {
        console.error("Error:", error);
      }

      await fetchProductMaster(
        selectedProductGroupId,
        selectedCategory,
        selectedPrincipal,
        selectedBrand
      );
    }
  };

  async function fetchProductMasterSpecific(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-master/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        setEditedStock(data.product_stock_unit);
        setSelectedOldStock(data.product_stock_unit);
        setEditedGroup(data.product_group);
        setSelectedOldGroup(data.product_group);
        return data;
      } else {
        throw new Error("Failed to fetch product master");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [editedStock, setEditedStock] = useState([]);
  const [selectedOldStock, setSelectedOldStock] = useState([]);

  const handleStockUnitChange = (event) => {
    const {
      target: { value },
    } = event;
    setEditedStock(typeof value === "string" ? value.split(",") : value);
  };

  const [editedGroup, setEditedGroup] = useState([]);
  const [selectedOldGroup, setSelectedOldGroup] = useState([]);

  const handleGroupChange = (event) => {
    const {
      target: { value },
    } = event;
    setEditedGroup(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [productGroups, setProductGroups] = useState([]);

  async function fetchProductGroup() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/product-group-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const { data } = await response.json();
        setProductGroups(data);
        const transformedData = data.map((group) => ({
          value: group.id,
          label: group.name,
        }));
        setStoreNameOptions2(transformedData);
      } else {
        throw new Error("Failed to fetch product groups");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchBrandMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/brand-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataBrandMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/stock-unit`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataStockUnit(data.data);
        setDataPackType(data.data_pack_type);
        setDataPackSize(data.data_pack_size);
        setDataUnitVol(data.data_unit_vol);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchCategoryMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/category-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataCategoryMaster(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  async function fetchPrincipalMaster() {
    const token = Cookies.get("access_token") || "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/principal-master`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataPrincipalMaster(data.data);
        setDataPrincipalFilter(data.data);
      } else {
        alert("Gagal Mengambil Data");
      }
    } catch (error) {
      console.error("Ada masalah, error :", error);
    }
  }

  useEffect(() => {
    fetchProductMasterColumn(dispatch, token);
    fetchCategoryMaster();
    fetchPrincipalMaster();
    fetchBrandMaster();
    fetchProductGroup();
  }, []);

  const handleEditStore = async (id) => {
    const detail = await fetchStoreMasterById(accessToken, id);
    // call data dynamic
    setDataRegency(
      await fetchRegency(accessToken, {
        province_code: detail.province_code,
      })
    );
    setDataDistrict(
      await fetchDistrict(accessToken, {
        regency_code: detail.regency_code,
      })
    );
    setDataSubDistrict(
      await fetchSubDistrict(accessToken, {
        district_code: detail.district_code,
      })
    );
    // set data
    setFormData({
      id: detail.id,
      store_id: detail.store_id,
      store_name: detail.store_name,
      store_type_code: detail.store_type_code,
      store_address: detail.store_address ? detail.store_address : "",
      store_latitude: detail.store_latitude ? detail.store_latitude : 0,
      store_longitude: detail.store_longitude ? detail.store_longitude : 0,
      province_code: detail.province_code,
      regency_code: detail.regency_code,
      district_code: detail.district_code,
      sub_district_code: detail.sub_district_code,
      jenis_outlet: detail.jenis_outlet,
      tipe_survey: detail.tipe_survey,
      tipe_jalan: detail.tipe_jalan,
      tipe_lokasi: detail.tipe_lokasi,
    });
    // show dialog
    setOpenDialogEdit(true);
  };

  const handleCloseDialog = () => {
    // close the dialog
    setOpenDialogEdit(false);
    // clear data
    setFormData({
      id: "",
      store_id: "",
      store_name: "",
      store_type_code: "",
      store_address: "",
      store_latitude: "",
      store_longitude: "",
      province_code: "",
      regency_code: "",
      district_code: "",
      sub_district_code: "",
      jenis_outlet: "",
      tipe_survey: "",
      tipe_jalan: "",
      tipe_lokasi: "",
    });
    // reset
    setDataRegency([]);
    setDataDistrict([]);
    setDataSubDistrict([]);
  };

  const handleChangeStoreMaster = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // confirm ok
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  // const [selectedRowDataDataValidation, setSelectedRowDataDataValidation] = useState(null);
  // const [confirmModalOpenDataValidation, setConfirmModalOpenDataValidation] = useState(false);

  const handleConfirmOk = async () => {
    try {
      const { period, prev_period } = selectedRowData;
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing-raw/rta_gt_sales_check`;
      const queryParams = new URLSearchParams({
        period,
        prev_period,
      }).toString();

      const response = await fetch(`${apiUrl}?${queryParams}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast.success("Data berhasil dikonfirmasi!");
        handleRefresh();
        setConfirmModalOpen(false);
      } else {
        const message = await response.json();
        toast.error(message.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Gagal mengkonfirmasi data!");
    }
  };

  // const handleConfirmDataValidation = async () => {
  //   try {
  //     const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing/data_preparation-data-validation`;

  //     const response = await fetch(`${apiUrl}`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(selectedRowDataDataValidation)
  //     });

  //     if (response.status === 201) {
  //       toast.success("Data berhasil dikonfirmasi!");
  //       handleRefresh();
  //       setConfirmModalOpen(false);
  //     } else {
  //       const message = await response.json();
  //       toast.error(message.detail);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error("Gagal mengkonfirmasi data!");
  //   }
  // };

  const handleOpenConfirmModal = (rowData) => {
    setSelectedRowData(rowData);
    setConfirmModalOpen(true);
  };

  // const handleOpenConfirmModalDataValidation = (rowData) => {
  //   setSelectedRowDataDataValidation(rowData);
  //   setConfirmModalOpenDataValidation(true);
  // };

  const [processModalOpen, setProcessModalOpen] = useState(false);
  const [selectedProcessData, setSelectedProcessData] = useState(null);

  const handleProcessOk = async () => {
    setIsLoading(true);
    try {
      const { id, description } = selectedProcessData;
      let apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing-single/data_preparation/${id}/process`;

      if (description === "Data Validation") {
        apiUrl += `?prev_period=${previousPeriod}`;
      }

      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setProcessModalOpen(false);
        toast.success(`${description} mulai diproses!`);
        handleRefresh();
        setSelectedProcessData(null);
      } else {
        const message = await response.json();
        toast.error(message.detail);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Gagal mengkonfirmasi data!");
    }
    setIsLoading(false);
  };

  const handleOpenProcessModal = (rowData) => {
    setSelectedProcessData(rowData);
    setProcessModalOpen(true);
  };

  // update store msater
  async function updateStoreMaster() {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-master/${formData.id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status === 200) {
        toast.success("Store berhasil diubah!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        fetchStoreMaster(dispatch, accessToken, customParams);

        // clear data
        setFormData({
          id: "",
          store_id: "",
          store_name: "",
          store_type_code: "",
          store_address: "",
          store_latitude: "",
          store_longitude: "",
          province_code: "",
          regency_code: "",
          district_code: "",
          sub_district_code: "",
          jenis_outlet: "",
          tipe_survey: "",
          tipe_jalan: "",
          tipe_lokasi: "",
        });
        // reset
        setDataRegency([]);
        setDataDistrict([]);
        setDataSubDistrict([]);
        // close dialog
        setOpenDialogEdit(false);
      } else {
        toast.error("Gagal mengubah store!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error("Failed to update store:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing store:", error);
    }
    setIsLoading(false);
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    // call update store master
    updateStoreMaster();
  };

  const customParams = {
    size: itemsPerPage,
    page: currentPage,
    store_status: selectedStatusId,
    tipe_survey: selectedTipeSurvey,
    province_id: selectedProvinceId,
    regency_id: selectedRegencyId,
    district_id: selectedDistrictId,
    sub_district_id: selectedSubDistrictId,
    column_name: filterColumnName,
    column_operator: filterColumnOperator,
    column_value: filterColumnValue,
  };

  const handleChangeProvince = async (e) => {
    // set province selected but regency not selected
    setFormData({
      ...formData,
      province_code: e.target.value,
      regency_code: "",
    });
    // get data regency based on province selected
    setDataRegency(
      await fetchRegency(accessToken, {
        province_code: e.target.value,
      })
    );
  };

  const handleChangeRegency = async (e) => {
    // set regency selected but district not selected
    setFormData({
      ...formData,
      regency_code: e.target.value,
      district_code: "",
    });
    // get data district based on regency selected
    setDataDistrict(
      await fetchDistrict(accessToken, {
        regency_code: e.target.value,
      })
    );
  };

  const handleChangeDistrict = async (e) => {
    // set district selected but sub district not selected
    setFormData({
      ...formData,
      district_code: e.target.value,
      sub_district_code: "",
    });
    // get data sub district based on district selected
    setDataSubDistrict(
      await fetchSubDistrict(accessToken, {
        district_code: e.target.value,
      })
    );
  };

  const handleRemarkClick = async (remark, stepDescription, id) => {
    if (remark !== "done" && remark !== "progress") {
      if (stepDescription === "Completeness Check") {
        if (["rta_gt", "rdc_gt", "rdc_pharmacy"].includes(projectType)) {
          setOpenTableCompletenessStoreGT(true);
        } else if (projectType === "rdc_mt") {
          setOpenTableCompletenessStoreMT(true);
        } else if (remark === "Not Yet") {
          return null;
        }
      } else if (stepDescription === "Consistency Check") {
        if (["rta_gt", "rdc_gt", "rdc_pharmacy"].includes(projectType)) {
          setOpenTableConsistencyStoreGT(true);
        } else if (projectType === "rdc_mt") {
          setOpenTableConsistencyStoreMT(true);
        } else if (remark === "Not Yet") {
          return null;
        }
      } else if (stepDescription === "Data Validation") {
        if (projectType === "rta_gt" && remark) {
          setOpenRawDataModal(true);
          setRemarkDataValidationRtaGT(remark);
        } else if (remark === "Not Yet") {
          return null;
        }
      } else if (stepDescription === "Cell Store Mapping") {
        if (remark === "terdapat store yang tidak memiliki cellcode") {
          const filename = `store_cell_mapping_incomplete_${projectType}_${currentPeriod}`;
          await handleExportValidation(id, filename);
        } else if (remark === "Not Yet") {
          return null;
        }
      } else if (stepDescription === "Calculate Projection Factor") {
        if (
          remark === "terdapat projection factor atau adj factor yang kosong"
        ) {
          const filename = `df_raw_incomplete_p_adj_factor_${projectType}_${currentPeriod}`;
          await handleExportValidation(id, filename);
        } else if (remark === "Not Yet") {
          return null;
        }
      }
    }
  };

  const handleExportProductCompare = async () => {
    try {
      const queryParams = new URLSearchParams();
      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing/product-master/compare/export` +
        (queryString ? `&${queryString}` : "");

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `Product Master Compare.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success(`Data Product Master Compare berhasil di export!`);
      } else {
        const message = await response.json();
        toast.error(
          message.detail || "An error occurred while exporting data."
        );
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while exporting data.");
    }
  };

  const handleExportStoreCompare = async () => {
    try {
      const queryParams = new URLSearchParams();
      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing/store-master/compare/export` +
        (queryString ? `&${queryString}` : "");

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `Store Master Compare.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success(`Data Store Master Compare berhasil di export!`);
      } else {
        const message = await response.json();
        toast.error(
          message.detail || "An error occurred while exporting data."
        );
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while exporting data.");
    }
  };

  const handleExportValidation = async (id, filename) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing/data_preparation/${id}/export`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${filename}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else if (response.status === 422) {
        alert("Gagal mengexport file!");
      }
    } catch (e) {
      console.error("Error exporting data:", e);
      toast.error("Error exporting data. Please try again.");
    }
  };

  const handleClose = () => {
    setOpenTableCompleteness(false);
    setOpenTableCompletenessProductMT(false);
    setOpenTableCompletenessStoreGT(false);
    setOpenTableCompletenessStoreMT(false);
    setOpenTableConsistency(false);
    setOpenTableConsistencyProductMT(false);
    setOpenTableConsistencyStoreGT(false);
    setOpenTableConsistencyStoreMT(false);
    setOpenRawDataModal(false);
  };

  const handleConsistencyProduct = () => {
    setOpenTableConsistency(true);
    setOpenTableConsistencyStoreGT(false);
    setOpenTableConsistencyStoreMT(false);
  };

  const handleConsistencyProductMT = () => {
    setOpenTableConsistencyProductMT(true);
    setOpenTableConsistencyStoreGT(false);
    setOpenTableConsistencyStoreMT(false);
  };

  const handleConsistencyStoreGT = () => {
    setOpenTableConsistencyStoreGT(true);
    setOpenTableConsistency(false);
    setOpenTableConsistencyStoreMT(false);
  };

  const handleConsistencyStoreMT = () => {
    setOpenTableConsistencyStoreMT(true);
    setOpenTableConsistencyProductMT(false);
    setOpenTableConsistencyStoreGT(false);
  };

  const handleCompletenessProduct = () => {
    setOpenTableCompleteness(true);
    setOpenTableCompletenessStoreGT(false);
    setOpenTableCompletenessStoreMT(false);
  };

  const handleCompletenessProductMT = () => {
    setOpenTableCompletenessProductMT(true);
    setOpenTableCompletenessStoreGT(false);
    setOpenTableCompletenessStoreMT(false);
  };

  const handleCompletenessStoreGT = () => {
    setOpenTableCompletenessStoreGT(true);
    setOpenTableCompleteness(false);
    setOpenTableCompletenessStoreMT(false);
  };

  const handleCompletenessStoreMT = () => {
    setOpenTableCompletenessStoreMT(true);
    setOpenTableCompletenessProductMT(false);
    setOpenTableCompletenessStoreGT(false);
  };

  const [selectedFilteredCategory, setSelectedFilteredCategory] = useState("");
  const [filteredPackSize, setFilteredPackSize] = useState([]);
  const [filteredPackType, setFilteredPackType] = useState([]);
  const [filteredFlavour, setFilteredFlavour] = useState([]);

  const categorySelector = (event) => {
    if (event && event.target) {
      const selectedCategory = event.target.value;
      setEditedProduct({
        ...editedProduct,
        category_master_id: selectedCategory,
      });
      setSelectedFilteredCategory(selectedCategory);
    } else {
      console.error("Something went wrong");
    }
  };

  useEffect(() => {
    setSelectedFilteredCategory(editedProduct.category_master_id);
  }, [editedProduct.category_master_id]);

  useEffect(() => {
    fetchPackSize(token, setFilteredPackSize, selectedFilteredCategory);
    fetchPackType(token, setFilteredPackType, selectedFilteredCategory);
    fetchFlavour(token, setFilteredFlavour, selectedFilteredCategory);
  }, [selectedFilteredCategory]);

  return (
    <div>
      <TableContainer component={Paper} style={{ maxHeight: "fit-content" }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Panel
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Current Period
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Previous Period
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Order
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Step Description
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Remarks
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Completed Time
              </StyledTableCell>
              <StyledTableCell align="center" style={{ color: "white" }}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {preparationData.length > 0 ? (
              preparationData.map((row, index) => {
                const previousRow = preparationData.find(
                  (r) => r.position === row.position - 1
                );
                const canShowAction = !previousRow || previousRow.remarks;

                const handleProcessClick = () => {
                  if (
                    projectType === "rta_gt" &&
                    row.description === "Data Validation" &&
                    !previousPeriod
                  ) {
                    toast.error(
                      "Mohon mengisi previous period untuk menjalankan process data validation."
                    );
                    return;
                  }
                  handleOpenProcessModal(row);
                };

                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{
                      whiteSpace: "nowrap",
                      minWidth: row.minWidth,
                      color: "#FFF",
                      fontWeight: "bold",
                    }}
                  >
                    <TableCell align="center">{row.project_type}</TableCell>
                    <TableCell align="center">{row.period}</TableCell>
                    <TableCell align="center">{row.prev_period}</TableCell>
                    <TableCell align="center">{row.position}</TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                    <TableCell align="center">
                      {row.remarks && (
                        <Tooltip
                          title="Edit Data Preparation"
                          onClick={() =>
                            handleRemarkClick(
                              row.remarks,
                              row.description,
                              row.id
                            )
                          }
                        >
                          <span
                            style={{
                              cursor:
                                row.remarks !== "done" ? "pointer" : "default",
                              backgroundColor:
                                row.remarks !== "done"
                                  ? "lightgray"
                                  : "transparent",
                              padding: "5px 10px",
                              borderRadius: "4px",
                              display: "inline-block",
                            }}
                          >
                            {row.remarks}
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center">{row.process_at}</TableCell>
                    <TableCell align="center">
                      {canShowAction && (
                        <>
                          {row.remarks !== "done" &&
                            projectType === "rta_gt" &&
                            row.description === "Data Validation" &&
                            /Ada \d+ transaksi out of ranges/.test(
                              row.remarks
                            ) &&
                            !row.remarks.includes(
                              "dan telah dikonfirmasi OK"
                            ) && (
                              <Tooltip title="Confirm Ok">
                                <IconButton
                                  onClick={() => handleOpenConfirmModal(row)}
                                >
                                  <CheckBoxIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          <Tooltip title="Process Data Preparation">
                            <IconButton onClick={handleProcessClick}>
                              <DirectionsRunIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  {isLoading ? "Loading..." : "No Data Available"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal Completeness Check Product */}
      <CompletenessProductModal
        open={openTableCompleteness}
        onClose={handleClose}
        onCompletenessStoreGT={handleCompletenessStoreGT}
        onEditProduct={handleEditProduct}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
      />

      {/* Modal Completeness Product MT Check Product */}
      <CompletenessProductModalMT
        open={openTableCompletenessProductMT}
        onClose={handleClose}
        onCompletenessStoreMT={handleCompletenessStoreMT}
        onEditProduct={handleEditProduct}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
      />

      {/* Modal Completeness Check Store GT */}
      <CompletenessStoreGTModal
        open={openTableCompletenessStoreGT}
        onClose={handleClose}
        onCompletenessProduct={handleCompletenessProduct}
        onEditStore={handleEditStore}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
        projectType={projectType}
      />

      {/* Modal Completeness Check Store MT*/}
      <CompletenessStoreMTModal
        open={openTableCompletenessStoreMT}
        onClose={handleClose}
        onCompletenessProduct={handleCompletenessProductMT}
        onEditStore={handleEditStore}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
      />

      {/* Modal Consistency Check Product*/}
      <ConsistencyProductModal
      handleExportProductCompare={handleExportProductCompare}
        open={openTableConsistency}
        onClose={handleClose}
        onConsistencyStoreGT={handleConsistencyStoreGT}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
      />

      {/* Modal Consistency MT Check Product*/}
      <ConsistencyProductModalMT
        open={openTableConsistencyProductMT}
        onClose={handleClose}
        onConsistencyStoreMT={handleConsistencyStoreMT}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
      />

      {/* Modal Consistency Check Store GT*/}
      <ConsistencyStoreGTModal
      handleExportStoreCompare={handleExportStoreCompare}
        open={openTableConsistencyStoreGT}
        onClose={handleClose}
        onConsistencyProduct={handleConsistencyProduct}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
        projectType={projectType}
      />

      {/* Modal Consistency Check Store MT*/}
      <ConsistencyStoreMTModal
        open={openTableConsistencyStoreMT}
        onClose={handleClose}
        onConsistencyProduct={handleConsistencyProductMT}
        isLoading={isLoading}
        itemsPerPageList={itemsPerPageList}
      />

      {/* Modal Validation*/}
      <RawDataModal
        open={openRawDataModal}
        onClose={handleClose}
        currentPeriod={currentPeriod}
        accessToken={accessToken}
        remarkDataValidationRtaGT={remarkDataValidationRtaGT}
      />

      {/* modal confirm ok */}
      <ModalConfirmOk
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleConfirmOk}
      />

      {/* modal process preparation step */}
      <ModalProcessPreparationStep
        open={processModalOpen}
        onClose={() => setProcessModalOpen(false)}
        onProcess={handleProcessOk}
        isLoading={isLoading}
        selectedProcessData={selectedProcessData}
      />

      {/* <ModalConfirmOk
        open={confirmModalOpenDataValidation}
        onClose={() => setConfirmModalOpenDataValidation(false)}
        onConfirm={handleConfirmDataValidation}
      /> */}

      {/* modal edit */}
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box sx={{ ...style, width: "700px" }}>
          {/* Content in the edit modal */}
          <div
            style={{
              maxHeight: "600px",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            <h2>Edit Product</h2>
            <TextField
              label="Product Name"
              sx={{ width: "100%" }}
              value={editedProduct.product_name}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  product_name: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Barcode"
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.barcode}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  barcode: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Category Master"
              select
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.category_master_id}
              onChange={categorySelector}
            >
              {dataCategoryMaster?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Principal Master"
              select
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.principal_master_id}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  principal_master_id: e.target.value,
                })
              }
            >
              {dataPrincipalMaster?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Brand Master"
              select
              sx={{ width: "100%", mb: 1 }}
              value={editedProduct.brand_master_id}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  brand_master_id: e.target.value,
                })
              }
            >
              {dataBrandMaster?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Pack Size"
              select
              value={editedProduct.packsize}
              sx={{ width: "100%", mb: 2 }}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  packsize: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            >
              {filteredPackSize?.map((option) => (
                <MenuItem
                  key={option.atribut_value}
                  value={option.atribut_value}
                >
                  {option.atribut_value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Pack Type"
              select
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.packtype}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  packtype: e.target.value,
                })
              }
            >
              {filteredPackType?.map((option) => (
                <MenuItem
                  key={option.atribut_value}
                  value={option.atribut_value}
                >
                  {option.atribut_value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Flavour"
              select
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.flavour}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  flavour: e.target.value,
                })
              }
            >
              {filteredFlavour?.map((option) => (
                <MenuItem
                  key={option.atribut_value}
                  value={option.atribut_value}
                >
                  {option.atribut_value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Convertedvol"
              type="number"
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.convertedvol}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  convertedvol: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Control Price"
              type="number"
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.ctrl_price}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  ctrl_price: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Unitvol"
              select
              sx={{ width: "100%", mb: 2 }}
              value={editedProduct.unitvol}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  unitvol: e.target.value,
                })
              }
            >
              {dataUnitVol?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <FormControl sx={{ width: "100%", mb: 2 }}>
              <InputLabel id="demo-multiple-name-label">Stok Unit</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={editedStock}
                onChange={handleStockUnitChange}
                input={<OutlinedInput label="Stok Unit" />}
              >
                {dataStockUnit.map((item, index) => (
                  <MenuItem key={index} value={item.description}>
                    {item.code} - {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "100%", mb: 2 }}>
              <InputLabel id="demo-multiple-name-label-group">Group</InputLabel>
              <Select
                labelId="demo-multiple-name-label-group"
                id="demo-multiple-name-group"
                multiple
                value={editedGroup}
                onChange={handleGroupChange}
                input={<OutlinedInput label="Group" />}
              >
                {storeNameOptions2.map((item) => (
                  <MenuItem key={item.label} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  width: 180,
                  height: 50,
                  mt: 2,
                  color: "#b34469",
                  borderColor: "#b34469",
                }}
                onClick={() => setEditModalOpen(false)}
              >
                Cancel
              </Button>
              <BootstrapButton
                variant="contained"
                disableRipple
                sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
                onClick={handleUpdateProduct}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "UPDATE"}
              </BootstrapButton>
            </div>
          </div>
        </Box>
      </Modal>
      {/* modal edit */}
      <ModalEditStoreMaster
        open={openDialogEdit}
        onClose={handleCloseDialog}
        title={"Edit Store"}
        buttonTitle={"Update"}
        formData={formData}
        handleSubmit={handleUpdate}
        handleChange={{
          general: handleChangeStoreMaster,
          province: handleChangeProvince,
          regency: handleChangeRegency,
          district: handleChangeDistrict,
        }}
        isLoading={isLoading}
        items={{
          dataStoreType: dataStoreType,
          dataProvince: dataProvince,
          dataRegency: dataRegency,
          dataDistrict: dataDistrict,
          dataSubDistrict: dataSubDistrict,
          dataJenisOutlet: dataJenisOutlet,
          dataTipeSurvey: dataTipeSurvey,
          dataTipeJalan: dataTipeJalan,
          dataTipeLokasi: dataTipeLokasi,
        }}
      />
    </div>
  );
};

export default PreparationTable;
