import React from 'react';
import { TextField, Autocomplete, MenuItem } from '@mui/material';

function SearchBarSetupMaster({ label, data, onSelectTable, value }) {
  return (
    <TextField
      select
      label={label}
      value={value}
      onChange={(e) => onSelectTable(e.target.value)}
      style={{ width: "200px" }}
    >
      {data.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default SearchBarSetupMaster;
