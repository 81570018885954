export async function fetchAreaData(token, areaName) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/area/${areaName}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const { data } = await response.json();
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function fetchDistrictOptionsByArea(token, selectedArea) {
  if (selectedArea) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/districts?area=${selectedArea}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        return data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }
  return [];
}

export async function fetchSubDistrictOptionsByDistrict(token, selectedDistrict) {
  if (selectedDistrict) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/sub-districts?district_code=${selectedDistrict}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        return data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }
  return [];
}
