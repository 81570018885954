import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TableCategory from "./TableCategory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  overflowY: "auto",
  maxHeight: 600,
  p: 3,
};

const closeButtonStyle = {
  position: "absolute",
  top: 5,
  right: 18,
};

const ModalTableCategory = ({
  open,
  handleClose,
  selectedCategoryId,
  selectedInformation,
}) => (
  <Modal open={open} onClose={handleClose}>
    <Box sx={style}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={closeButtonStyle}
      >
        <CloseIcon />
      </IconButton>
      <TableCategory
        selectedCategoryId={selectedCategoryId}
        selectedInformation={selectedInformation}
      />
    </Box>
  </Modal>
);

export default ModalTableCategory;
