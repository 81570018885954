export async function fetchDataProductMaster(token, setIsLoading, setFilteredRows, selectedCategory = 'all', selectedBrand = 'all', selectedPrincipal = 'all', selectedStockunit = 'all', selectedPacksize = 'all', selectedPacktype = 'all', selectedFlavour = 'all', selectedUnitvol = 'all') {
    setFilteredRows([])
    setIsLoading(true);

    // Create an empty URLSearchParams object
    const queryParams = new URLSearchParams();

    if (selectedCategory && selectedCategory !== 'all') {
        queryParams.append('Category', selectedCategory);
    }

    if (selectedBrand && selectedBrand !== 'all') {
        queryParams.append('Brand', selectedBrand);
    }

    if (selectedPrincipal && selectedPrincipal !== 'all') {
        queryParams.append('Principal', selectedPrincipal);
    }

    if (selectedStockunit && selectedStockunit !== 'all') {
        queryParams.append('StockUnit', selectedStockunit);
    }

    if (selectedPacksize && selectedPacksize !== 'all') {
        queryParams.append('Packsize', selectedPacksize);
    }

    if (selectedPacktype && selectedPacktype !== 'all') {
        queryParams.append('Packtype', selectedPacktype);
    }

    if (selectedFlavour && selectedFlavour !== 'all') {
        queryParams.append('Flavour', selectedFlavour);
    }

    if (selectedUnitvol && selectedUnitvol !== 'all') {
        queryParams.append('Unitvol', selectedUnitvol);
    }


    // Construct the full URL with query parameters
    const queryString = queryParams.toString();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/category-master` + (queryString ? `?${queryString}` : '');

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            setFilteredRows(data.data); // Initialize filteredRows with the retrieved data
        } else {
            setFilteredRows([])
            // Handle error
        }
    } catch (error) {
        // Handle error
    }

    setIsLoading(false);
}

  export async function fetchExportDataProductMaster(token, setIsLoading, selectedCategory = 'all', selectedBrand = 'all', selectedPrincipal = 'all', selectedStockunit = 'all', selectedPacksize = 'all', selectedPacktype = 'all', selectedFlavour = 'all', selectedUnitvol = 'all') {
    setIsLoading(true);

    // Create an empty URLSearchParams object
    const queryParams = new URLSearchParams();

    if (selectedCategory && selectedCategory !== 'all') {
        queryParams.append('Category', selectedCategory);
    }

    if (selectedBrand && selectedBrand !== 'all') {
        queryParams.append('Brand', selectedBrand);
    }

    if (selectedPrincipal && selectedPrincipal !== 'all') {
        queryParams.append('Principal', selectedPrincipal);
    }

    if (selectedStockunit && selectedStockunit !== 'all') {
        queryParams.append('StockUnit', selectedStockunit);
    }

    if (selectedPacksize && selectedPacksize !== 'all') {
        queryParams.append('Packsize', selectedPacksize);
    }

    if (selectedPacktype && selectedPacktype !== 'all') {
        queryParams.append('Packtype', selectedPacktype);
    }

    if (selectedFlavour && selectedFlavour !== 'all') {
        queryParams.append('Flavour', selectedFlavour);
    }

    if (selectedUnitvol && selectedUnitvol !== 'all') {
        queryParams.append('Unitvol', selectedUnitvol);
    }


    const queryString = queryParams.toString();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/product-master/setup/export` + (queryString ? `?${queryString}` : '');
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Result Product Master.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } else if (response.status === 404) {
            alert('data not found')
        } else {
            alert('something went wrong went export data')
        }
    } catch (error) {
        // Handle error
    }

    setIsLoading(false);
}

export const exportproductmastersetup = async (accessToken, title, url, customParams = {}) => {
    try {
      const filteredParams = Object.fromEntries(
        Object.entries(customParams).filter(([key, value]) => value !== '')
      );
  
      const queryString = Object.keys(filteredParams)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
        .join('&');
  
      const apiUrl = `${process.env.REACT_APP_API_URL}/${url}`;
      const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;
      
      const response = await fetch(fullUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.ok) {
        const blob = await response.blob();
        return blob;
      } else {
        console.error(`Failed to export ${title} store:`, response.statusText);
        return;
      }
    } catch (error) {
      console.error(`Error exporting ${title} store:`, error);
    }
  };