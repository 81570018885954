export async function fetchProfile(token, setDataProfile, setNotAllowAccessPage, roleToDisallow = []) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/auth/profile`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      setDataProfile(data.data);

      const checkRole = data.data.role_code;

      // Check if the user's role is in the list of roles to disallow access
      if (roleToDisallow.includes(checkRole)) {
        setNotAllowAccessPage(true);
      }
    } else if(response.status === 403 || response.status === 401){
      window.location.replace("/")
    } else {
      setDataProfile([]);
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }
}
