import { setProjects, setTotalPages } from '../redux/slices/projectSlice';

export const fetchProjects = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/project`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      dispatch(setProjects(data.data));
      dispatch(setTotalPages(data.pagination_info.total_pages));
    } else {
      console.error(`Failed to fetch projects:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching projects:`, error);
  }
};
