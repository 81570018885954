import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setItemsPerPage } from '../../../redux/slices/dataSurveySlice';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableSortLabel, TableRow, Tooltip, IconButton } from '@mui/material';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import TableViewIcon from '@mui/icons-material/TableView';
import { fetchDataSurveys } from '../../../services/dataSurveyService';

export const TableDataSurvey = ({ isLoading, itemsPerPageList, openNewTab, surveyType }) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.dataSurvey.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector((state) => state.dataSurvey.currentPage);
  const itemsPerPage = useSelector((state) => state.dataSurvey.itemsPerPage);
  const total = useSelector((state) => state.dataSurvey.total);

  const selectedSurveyId = useSelector((state) => state.dataSurvey.selectedSurveyId);
  const selectedProvinceId = useSelector((state) => state.dataSurvey.selectedProvinceId);
  const selectedRegencyId = useSelector((state) => state.dataSurvey.selectedRegencyId);
  const selectedDistrictId = useSelector((state) => state.dataSurvey.selectedDistrictId);
  const selectedSubDistrictId = useSelector((state) => state.dataSurvey.selectedSubDistrictId);
  const selectedAuditStatus = useSelector((state) => state.dataSurvey.selectedAuditStatus);
  const selectedTipeSurveyId = useSelector((state) => state.dataSurvey.selectedTipeSurveyId);
  const selectedUserNameSurveyor = useSelector((state) => state.dataSurvey.selectedUserNameSurveyor);
  const selectedIsNegative = useSelector((state) => state.dataSurvey.selectedIsNegative);

  const [sortBy, setSortBy] = useState('store_name');
  const [sortOrder, setSortOrder] = useState('desc');

  const fetchData = (currentPage, itemsPerPage, sortBy, sortOrder) => {
    try {
      dispatch(setCurrentPage(currentPage));
      const customParams = {
        size: itemsPerPage,
        page: currentPage,
        province_code: selectedProvinceId,
        regency_code: selectedRegencyId,
        district_code: selectedDistrictId,
        sub_district_code: selectedSubDistrictId,
        tipe_survey: selectedTipeSurveyId,
        audit_status: selectedAuditStatus.length > 0 ? selectedAuditStatus : null,
        user_name_surveyor: selectedUserNameSurveyor,
        is_negative: selectedIsNegative,
        sort_by: sortBy,
        sort_order: sortOrder,
      };
      fetchDataSurveys(dispatch, accessToken, customParams, selectedSurveyId);
    } catch (error) {
      console.error(`Error fetching data:`, error);
    }
  };

  useEffect(() => {
    fetchData(currentPage || 1, itemsPerPage, sortBy, sortOrder);
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedSurveyId,
    selectedAuditStatus,
    selectedTipeSurveyId,
    selectedProvinceId,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId,
    selectedUserNameSurveyor,
    sortBy,
    sortOrder,
  ]);

  const handlePageChange = (event, page) => {
    fetchData(page + 1, itemsPerPage, sortBy, sortOrder);
  };

  const handleChangeItemPerPageSelect = (e) => {
    dispatch(setItemsPerPage(e.target.value));
    fetchData(1, e.target.value, sortBy, sortOrder);
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === 'asc';
    setSortOrder(isSorting ? 'desc' : 'asc');
    setSortBy(column);
  }

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: '#FFF !important',
          '&:hover': {
            color: '#FFF',
          },
          '& .MuiTableSortLabel-icon': {
            color: '#FFF !important',
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const renderAuditStatus = (status) => {
    let statusText = "";

    switch (status) {
      case "Valid":
        statusText = (
          <div
            style={{
              background: "lightblue",
              color: "white",
              padding: "10px",
            }}
          >
            Valid
          </div>
        );
        break;
      case "Not Started":
        statusText = (
          <div
            style={{ background: "red", color: "white", padding: "10px" }}
          >
            Not Started
          </div>
        );
        break;
      case "Closed":
        statusText = (
          <div
            style={{ background: "green", color: "white", padding: "10px" }}
          >
            Closed
          </div>
        );
        break;
      case "On Progress":
        statusText = (
          <div
            style={{
              background: "orange",
              color: "white",
              padding: "10px",
            }}
          >
            On Progress
          </div>
        );
        break;
      default:
        statusText = status;
        break;
    }

    return statusText;
  };

  const renderQCDataExist = (params) => {
    let qcDataExist = "";

    const { id, audit_status, qc_data_exist } = params.row;

    const handleViewDataTrx = () => {
      openNewTab(id, audit_status, surveyType, true);
    };

    if (qc_data_exist) {
      qcDataExist = (
        <Tooltip
          title="View QC Data"
          onClick={handleViewDataTrx}
        >
          <IconButton>
            <BackupTableIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return qcDataExist;
  };

  const renderActions = (params) => {
    const { id, audit_status } = params.row;

    const handleViewDataTrx = () => {
      openNewTab(id, audit_status, surveyType, false);
    };

    return (
      <Tooltip title="View Data Trx" onClick={handleViewDataTrx}>
        <IconButton>
          <TableViewIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const tableCellStyle = {
    whiteSpace: 'nowrap',
    backgroundColor: 'rgb(6,157,174)',
    color: '#FFF',
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    minWidth: 120,
    textAlign: 'center',
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {sortTableCell('Kode Outlet', 'store_code')}
              {sortTableCell('Nama Outlet', 'store_name')}
              {sortTableCell('Tipe Outlet', 'store_type')}
              {sortTableCell('Audit Plan', 'audit_plan_master_id')}
              {sortTableCell('Username Surveyor', 'user_name_surveyor')}
              {sortTableCell('Date Uploaded', 'date_uploaded')}
              {sortTableCell('Start Auditing', 'audit_start_at')}
              {sortTableCell('Provinsi', 'province_name')}
              {sortTableCell('Kota/Kabupaten', 'regency_name')}
              {sortTableCell('Kecamatan', 'district_name')}
              {sortTableCell('Kelurahan', 'sub_district_name')}
              {sortTableCell('QC Data', 'qc_data_exist')}
              <TableCell align="center" style={tableCellStyle}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">{row.store_id}</TableCell>
                  <TableCell align="center">{row.store_name}</TableCell>
                  <TableCell align="center">{row.store_type}</TableCell>
                  <TableCell align="center">{renderAuditStatus(row.audit_status)}</TableCell>
                  <TableCell align="center">{row.user_name_surveyor}</TableCell>
                  <TableCell align="center">{row.date_uploaded}</TableCell>
                  <TableCell align="center">{row.start_auditing}</TableCell>
                  <TableCell align="center">{row.province_name}</TableCell>
                  <TableCell align="center">{row.regency_name}</TableCell>
                  <TableCell align="center">{row.district_name}</TableCell>
                  <TableCell align="center">{row.sub_district_name}</TableCell>
                  <TableCell align="center">{renderQCDataExist({ row })}</TableCell>
                  <TableCell align="center">{renderActions({ row })}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={13} align="center">
                  {isLoading ? "Loading..." : "No Data Available"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* pagination */}
      {items.length > 0 &&
        <TablePagination
          rowsPerPageOptions={itemsPerPageList}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
        />
      }
    </Paper>
  );
};
