import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import {
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MoveDownIcon from '@mui/icons-material/MoveDown';
import DownloadingIcon from '@mui/icons-material/Downloading';

function DataGridStoreMaster({ isLoading, rows, handleOpenStatusModal, handleOpenDeleteModal, handleEditClick, selectedProjectType, handleOpenTransferDataRak2Modal, handleUpdateDataMobileToWebModal }) {

  const columns = [
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => (
        <div>
          {
            selectedProjectType !== "adhoc" ? (
              <Tooltip
                title="Update Data Mobile to Website"
                onClick={() => handleUpdateDataMobileToWebModal(params.row)}
              >
                <IconButton>
                  <DownloadingIcon />
                </IconButton>
              </Tooltip>
            ) : null
          }
          {
            selectedProjectType === "rak1" ? (
              <Tooltip
                title="Transfer data to rak2"
                onClick={() => handleOpenTransferDataRak2Modal(params.row)}
              >
                <IconButton>
                  <MoveDownIcon />
                </IconButton>
              </Tooltip>
            ) : null
          }
          {params.row.audit_status === "Not Started" ||
            params.row.audit_status === "Valid" ||
            params.row.audit_status === "On Progress"
            ? (
              <Tooltip
                title="Edit Audit Plan"
                onClick={() => handleEditClick(params.row.id)}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          {params.row.audit_status === "Not Started" ||
            params.row.audit_status === "Valid" ||
            params.row.audit_status === "On Progress" 
            ? (
              <Tooltip
              title="Delete Audit Plan"
              onClick={() => handleOpenDeleteModal(params.row.id)}
            >
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {params.row.audit_status === "Closed" ||
            params.row.audit_status === "closed" ? (
            <Tooltip
              title="Update Audit Plan to Valid"
              onClick={() => handleOpenStatusModal(params.row.id, 'Valid')}
            >
              <IconButton>
                <AutorenewIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {params.row.audit_status === "Valid" ? (
            <Tooltip
              title="Update Audit Plan to Closed"
              onClick={() => handleOpenStatusModal(params.row.id, 'Closed')}
            >
              <IconButton>
                <AutorenewIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      ),
    },
    {
      field: "store_code",
      headerClassName: "super-app-theme--header",
      headerName: "KODE OUTLET",
      width: 150,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "audit_plan",
      headerClassName: "super-app-theme--header",
      headerName: "AUDIT PLAN",
      width: 150,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "store_name",
      headerClassName: "super-app-theme--header",
      headerName: "NAMA OUTLET",
      width: 150,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "store_type",
      headerClassName: "super-app-theme--header",
      headerName: "TIPE OUTLET",
      width: 130,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "audit_status",
      headerClassName: "super-app-theme--header",
      headerName: "STATUS",
      width: 150,
      align: "left",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => {
        let statusText = "";

        switch (params.value) {
          case "Valid":
            statusText = (
              <div
                style={{
                  background: "lightblue",
                  color: "white",
                  padding: "10px",
                }}
              >
                Valid
              </div>
            );
            break;
          case "Not Started":
            statusText = (
              <div
                style={{ background: "red", color: "white", padding: "10px" }}
              >
                Not Started
              </div>
            );
            break;
          case "Closed":
            statusText = (
              <div
                style={{ background: "green", color: "white", padding: "10px" }}
              >
                Closed
              </div>
            );
            break;
          case "On Progress":
            statusText = (
              <div
                style={{
                  background: "orange",
                  color: "white",
                  padding: "10px",
                }}
              >
                On Progress
              </div>
            );
            break;
          default:
            statusText = params.value;
            break;
        }

        return statusText;
      },
    },
    {
      field: "user_name_surveyor",
      headerClassName: "super-app-theme--header",
      headerName: "USERNAME SURVEYOR",
      width: 180,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "user_full_name_surveyor",
      headerClassName: "super-app-theme--header",
      headerName: "FULLNAME SURVEYOR",
      width: 180,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "user_name_qc",
      headerClassName: "super-app-theme--header",
      headerName: "USERNAME QC",
      width: 140,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "user_full_name_qc",
      headerClassName: "super-app-theme--header",
      headerName: "FULLNAME QC",
      width: 140,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "audit_start_at",
      headerClassName: "super-app-theme--header",
      headerName: "MULAI KUNJUNGAN",
      width: 160,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "audit_end_at",
      headerClassName: "super-app-theme--header",
      headerName: "AKHIR KUNJUNGAN",
      width: 170,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      width: 180,
      headerName: "TANGGAL DIBUAT",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "updated_at",
      headerClassName: "super-app-theme--header",
      width: 180,
      headerName: "TANGGAL DIPERBARUI",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
  ];

  return (
    <DataGrid
      sx={{
        mt: 2,
        ml: 2,
        mr: 2,
        "& .super-app-theme--header": {
          backgroundColor: "rgb(6,157,174)",
          color: "#FFF",
          border: 2,
          fontWeight: "bold",
          borderColor: "rgb(234,243,248)",
        },
        border: 2,
        borderColor: "rgb(234,243,248)",
        fontSize: 15,
      }}
      loading={isLoading}
      getRowId={(row) => row.id}
      rows={rows}
      columns={columns}
      editMode="row"
      getRowHeight={() => 'auto'}
    // rowModesModel={rowModesModel}
    // slotProps={{
    //   toolbar: { rows, setRowModesModel },
    // }}
    />
  )
}

export default DataGridStoreMaster
