// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width:430px) {
    .utama {
        display: flex;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .button-audit {
        margin-left: 5px;
    }

    .button-audit-plan {
        height: 40px;
    }

    .button-audit-plan p {
        font-size: 14px !important;
    }
}

@media only screen and (max-width:376px) {
    .utama {
        display: flex;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .button-audit {
        margin-left: 5px;
    }

    .button-audit-plan {
        height: 40px;
    }

    .button-audit-plan p {
        font-size: 12px !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/style/auditplan.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,aAAa;QACb,qCAAqC;IACzC;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI;QACI,aAAa;QACb,aAAa;QACb,qCAAqC;IACzC;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":["@media only screen and (max-width:430px) {\n    .utama {\n        display: flex;\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n    }\n\n    .button-audit {\n        margin-left: 5px;\n    }\n\n    .button-audit-plan {\n        height: 40px;\n    }\n\n    .button-audit-plan p {\n        font-size: 14px !important;\n    }\n}\n\n@media only screen and (max-width:376px) {\n    .utama {\n        display: flex;\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n    }\n\n    .button-audit {\n        margin-left: 5px;\n    }\n\n    .button-audit-plan {\n        height: 40px;\n    }\n\n    .button-audit-plan p {\n        font-size: 12px !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
