export async function fetchDataAuditPlan(token, setIsLoading, setFilteredRows, selectedSurveyId = 'all', selectedStoreTypeCode = 'all', selectedSurveyTypeCode = 'all', selectedProvinceCode = 'all', selectedRegencyCode = 'all', selectedDistrictCode = 'all', selectedSubDistrictCode = 'all', selectedAuditStatus = 'all', selectedUserNameSurveyor = 'all') {
  setFilteredRows([])
  setIsLoading(true);

  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  // Add selectedSurveyId to the query if it's provided
  if (selectedSurveyId && selectedSurveyId !== 'all') {
    queryParams.append('survey_id', selectedSurveyId);
  }

  // Add selectedStoreTypeCode to the query if it's provided
  if (selectedStoreTypeCode && selectedStoreTypeCode !== 'all') {
    queryParams.append('store_type_code', selectedStoreTypeCode);
  }

  // Add selectedStoreTypeCode to the query if it's provided
  if (selectedSurveyTypeCode && selectedSurveyTypeCode !== 'all') {
    queryParams.append('tipe_survey', selectedSurveyTypeCode);
  }

  // Add selectedProvinceCode to the query if it's provided
  if (selectedProvinceCode && selectedProvinceCode !== 'all') {
    queryParams.append('province_code', selectedProvinceCode);
  }

  // Add selectedRegencyCode to the query if it's provided
  if (selectedRegencyCode && selectedRegencyCode !== 'all') {
    queryParams.append('regency_code', selectedRegencyCode);
  }

  // Add selectedDistrictCode to the query if it's provided
  if (selectedDistrictCode && selectedDistrictCode !== 'all') {
    queryParams.append('district_code', selectedDistrictCode);
  }

  // Add selectedSubDistrictCode to the query if it's provided
  if (selectedSubDistrictCode && selectedSubDistrictCode !== 'all') {
    queryParams.append('sub_district_code', selectedSubDistrictCode);
  }

  // Add selectedAuditStatus to the query if it's provided
  if (selectedAuditStatus && selectedAuditStatus !== 'all') {
    queryParams.append('audit_status', selectedAuditStatus);
  }

  // Add selectedUserNameSurveyor to the query if it's provided
  if (selectedUserNameSurveyor && selectedUserNameSurveyor !== 'all') {
    queryParams.append('user_name_surveyor', selectedUserNameSurveyor);
  }

  // Construct the full URL with query parameters
  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/audit-plan` + (queryString ? `?${queryString}` : '');
  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setFilteredRows(data.data); // Initialize filteredRows with the retrieved data
    } else {
      setFilteredRows([])
      // Handle error
    }
  } catch (error) {
    // Handle error
  }

  setIsLoading(false);
}

export async function fetchExportDataAuditPlan(token, setIsLoading, selectedSurveyId = 'all', selectedStoreTypeCode = 'all', selectedProvinceCode = 'all', selectedRegencyCode = 'all', selectedDistrictCode = 'all', selectedSubDistrictCode = 'all', selectedAuditStatus = 'all', selectedUserNameSurveyor = 'all', selectedSurveyTypeCode = 'all') {
  setIsLoading(true);

  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  // Add selectedSurveyId to the query if it's provided
  if (selectedSurveyId && selectedSurveyId !== 'all') {
    queryParams.append('survey_id', selectedSurveyId);
  }

  // Add selectedStoreTypeCode to the query if it's provided
  if (selectedStoreTypeCode && selectedStoreTypeCode !== 'all') {
    queryParams.append('store_type_code', selectedStoreTypeCode);
  }

  // Add selectedProvinceCode to the query if it's provided
  if (selectedProvinceCode && selectedProvinceCode !== 'all') {
    queryParams.append('province_code', selectedProvinceCode);
  }

  // Add selectedRegencyCode to the query if it's provided
  if (selectedRegencyCode && selectedRegencyCode !== 'all') {
    queryParams.append('regency_code', selectedRegencyCode);
  }

  // Add selectedDistrictCode to the query if it's provided
  if (selectedDistrictCode && selectedDistrictCode !== 'all') {
    queryParams.append('district_code', selectedDistrictCode);
  }

  // Add selectedSubDistrictCode to the query if it's provided
  if (selectedSubDistrictCode && selectedSubDistrictCode !== 'all') {
    queryParams.append('sub_district_code', selectedSubDistrictCode);
  }

  // Add selectedAuditStatus to the query if it's provided
  if (selectedAuditStatus && selectedAuditStatus !== 'all') {
    queryParams.append('audit_status', selectedAuditStatus);
  }

  // Add selectedUserNameSurveyor to the query if it's provided
  if (selectedUserNameSurveyor && selectedUserNameSurveyor !== 'all') {
    queryParams.append('user_name_surveyor', selectedUserNameSurveyor);
  }

  // Add selectedUserNameSurveyor to the query if it's provided
  if (selectedSurveyTypeCode && selectedSurveyTypeCode !== 'all') {
    queryParams.append('tipe_survey', selectedSurveyTypeCode);
  }

  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/export` + (queryString ? `?${queryString}` : '');
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Result Audit Plan.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else if (response.status === 404) {
      alert('data not found')
    } else {
      alert('something went wrong went export data')
    }
  } catch (error) {
    // Handle error
  }

  setIsLoading(false);
}


export async function fetchProvincesFormatted(token, setDataProvince) {
  setDataProvince([])
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/area/provinces`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const { data } = await response.json();
      const newArray = data.map(item => ({
        label: item.name,
        id: item.code,
      }));

      setDataProvince(newArray)

    } else {
      setDataProvince([])
    }
  } catch (error) {
    console.error(error);
  }
}

export async function fetchRegenciesFormatted(token, provinceCode, setDataRegency) {
  setDataRegency([])
  if (provinceCode && provinceCode !== 'all') {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/regencies?province_code=${provinceCode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        const newArray = data.map(item => ({
          label: item.name,
          id: item.code,
        }));

        setDataRegency(newArray)

      } else {
        setDataRegency([])
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export async function fetchDistrictFormatted(token, regencyCode, setDataDistrict) {
  setDataDistrict([])
  if (regencyCode && regencyCode !== 'all') {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/districts?regency_code=${regencyCode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        const newArray = data.map(item => ({
          label: item.name,
          id: item.code,
        }));

        setDataDistrict(newArray)

      } else {
        setDataDistrict([])
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export async function fetchSubDistrictFormatted(token, districtCode, setDataSubDistrict) {
  setDataSubDistrict([])
  if (districtCode && districtCode !== 'all') {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/sub-districts?district_code=${districtCode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        const newArray = data.map(item => ({
          label: item.name,
          id: item.code,
        }));

        setDataSubDistrict(newArray)

      } else {
        setDataSubDistrict([])
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export async function fetchStoreMasterByStoreId(token, storeId) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/store-master/store_id/${storeId}?is_active=true`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const { data } = await response.json();
      return data;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function fetchSurveyTypeFormatted(token, setDataSurveyType) {
  setDataSurveyType([])
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/store-type`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      const newArray = data.data_tipe_survey.map(item => ({
        label: item,
        id: item,
      }));
      setDataSurveyType(newArray);
    } else {
      setDataSurveyType([])
    }
  } catch (error) {
    // Handle error
    console.error(error);
  }
}

export async function fetchPeriodeFormatted(token, setDataPeriode) {
  setDataPeriode([]);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/project/period`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      const newArray = data.data.map(item => ({
        label: item.period.toString(), // Mengonversi period menjadi string
        id: item.period.toString(),
      }));
      setDataPeriode(newArray);
    } else {
      setDataPeriode([]);
    }
  } catch (error) {
    console.error(error);
  }
}

export async function fetchProjectFormatted(token, periodeCode, setDataProject) {
  setDataProject([])
  if (periodeCode && periodeCode !== 'all') {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project?period=${periodeCode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        const newArray = data.map(item => ({
          label: item.project_name,
          id: item.survey_id,
          project_type_id: item.project_type_id,
        }));

        setDataProject(newArray)

      } else {
        setDataProject([])
      }
    } catch (error) {
      console.error(error);
    }
  }
}
