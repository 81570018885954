import { createSlice } from "@reduxjs/toolkit"

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    initial: '',
    role_code: '',
    profile: {},
  },
  reducers: {
    setInitial: (state, action) => {
      state.initial = action.payload;
    },
    setRoleCode: (state, action) => {
      state.role_code = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
  }
})

export const { setInitial, setRoleCode, setProfile  } = profileSlice.actions;
export default profileSlice.reducer;
