import { createSlice } from "@reduxjs/toolkit"

const tipeJalanSlice = createSlice({
  name: "tipeJalan",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
  },
  reducers: {
    // addToTipeJalan: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setTipeJalans: (state, action) => {
      state.data = action.payload;
    },
    setTipeJalan: (state, action) => {
      state.detail = action.payload;
    },
    editTipeJalan: (state, action) => {
      const editedTipeJalanIndex = state.data.findIndex(TipeJalan => TipeJalan.id === action.payload.id);
      if (editedTipeJalanIndex !== -1) {
        state.data[editedTipeJalanIndex] = {
          ...state.data[editedTipeJalanIndex], // Copy the existing properties of the store Type
          ...action.payload.updates, // Merge in the custom properties from the action payload
        };
      }
    },
    deleteTipeJalan: (state, action) => {
      state.data = state.data.filter(TipeJalan => TipeJalan.id !== action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
  }
})

export const { deleteTipeJalan, setTipeJalans, setCurrentPage, setItemsPerPage, setTipeJalan, editTipeJalan, setTotal } = tipeJalanSlice.actions;
export default tipeJalanSlice.reducer;
