import { React, useState } from "react";
import { Button, Dialog, ImageList, ImageListItem, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, styled, Typography, ButtonGroup } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from '@mui/icons-material/Upload';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { BootstrapButton } from '../../../Components/Button/BootstrapButton';
import { VisuallyHiddenInput } from '../../../Components/Button/VisuallyHiddenInput';

function ImportModalSetup({
    open,
    onClose,
    title,
    fileUrl,
    buttonTitle,
    handleSubmit,
    handleChange,
    handleExport,
    isLoading,
}) {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle>
            <span>{title}</span>
            <IconButton style={{ float: "right" }} onClick={onClose}>
              <CloseIcon color="primary" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ButtonGroup>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                color="warning"
                startIcon={<NoteAltIcon />}
              >
                Template
                <VisuallyHiddenInput onClick={handleExport} />
              </Button>
            </ButtonGroup>
            <form onSubmit={handleSubmit}
            >
              <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                <Grid item xs={12} sm={12}>
                  <Typography>
                    {
                      fileUrl === '' ? 'Tidak ada file yang terpilih' : fileUrl
                    }
                  </Typography>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    startIcon={<UploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput type="file" name="file" required onChange={(event) => {
                      const file = event.target.files?.[0];
                      if (file) {
                        handleChange.excel(file);
                        handleChange.fileUrl(file.name)
                      } else {
                        handleChange.excel('');
                        handleChange.fileUrl("");
                      }
                    }} />
                  </Button>
                </Grid>
                <Grid item xs={8} sm={4}>
                  <Button
                    variant="outlined"
                    disableRipple
                    sx={{
                      width: 190,
                      height: 50,
                      mt: 2,
                      color: "#b34469",
                      borderColor: "#b34469",
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={8} sm={4}>
                  <BootstrapButton
                    variant="contained"
                    disableRipple
                    sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : buttonTitle}
                  </BootstrapButton>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      );
}

export default ImportModalSetup
