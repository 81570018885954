import React from "react";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import { Slide, toast } from "react-toastify";
import Cookies from "js-cookie";

const token = Cookies.get("access_token") || "";

class ExcelRequestImport extends React.Component {
  state = {
    cols: [],
    rows: [],
    loading: false,
    dataKosong: false,
    previewDetail: "",
  };

  componentDidMount() {
    const path = window.location.pathname;
    const pathSegments = path.split("/");

    const apiUrlReportSegment = pathSegments.slice(-2).join("/");
    const encodedPreviewDetail = pathSegments.slice(-2).join(" - ");

    const previewDetail = decodeURIComponent(encodedPreviewDetail);

    localStorage.setItem("apiUrlReport", apiUrlReportSegment);
    localStorage.setItem("previewDetail", previewDetail);

    if (apiUrlReportSegment) {
      this.fetchAndProcessExcelData(apiUrlReportSegment);
    }

    if (previewDetail) {
      this.setState({ previewDetail });
    }
  }

  fetchAndProcessExcelData = async (apiUrlReportSegment) => {
    this.setState({ loading: true, dataKosong: false });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/process_queue/reporting_non_projected/export/${apiUrlReportSegment}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        const base64String = this.arrayBufferToBase64(arrayBuffer);

        this.processExcelData(base64String);
      } else if (response.status === 400) {
        toast.error("Data sedang diproses", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 404) {
        this.setState({ dataKosong: true });
      } else {
        toast.error("Tidak Ditemukan Excel", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error("Error fetching or processing file:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  base64ToBlob = (base64, contentType = "") => {
    const sliceSize = 512;
    const byteCharacters = window.atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  processExcelData = (base64Data) => {
    const blob = this.base64ToBlob(
      base64Data,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    ExcelRenderer(blob, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const { cols, rows } = resp;
        this.setState({
          cols: cols,
          rows: rows,
        });
      }
    });
  };

  render() {
    const { cols, rows, previewDetail, loading, dataKosong } = this.state;

    return (
      <div className="excel-import-container">
        <style>
          {`
            .excel-import-container {
              width: 80%;
              margin: 0 auto; 
              padding: 15px; 
            }
            .excel-table {
              width: 100%;
              table-layout: fixed;
            }
            .excel-table-wrapper {
              margin: 15px 0px;
              max-height: 800px;
              overflow-y: scroll;
              border-radius: 5px;
            }
            .excel-table tr:first-of-type {
              display: none;
            }
            .excel-table tr {
              background-color: #F3FEFF;
              text-align: center;
            }
            .excel-table tr:nth-of-type(2) {
              background-color: #3d5662 !important;
              color: #fff;
            }
            .excel-table tr:nth-of-type(even) {
              background-color: #E6F9FB;
            }
            .excel-table td {
              padding: 10px 15px;
              width: 150px;
            }
            .excel-table td:first-of-type,
            .excel-table th:first-of-type {
              display: none; /* Hide the first column */
            }
            .preview-detail {
              font-weight: bold;
              font-size: 18px;
              margin-bottom: 10px;
            }
            .no-data {
              font-size: 16px;
              color: #333;
              text-align: center;
              margin-top: 20px;
            }
            .loading {
              text-align: center;
              font-size: 18px;
              color: #007bff;
              margin-top: 20px;
            }
          `}
        </style>
        {!dataKosong && previewDetail && (
          <div className="preview-detail">{previewDetail}</div>
        )}
        <div className="excel-table-wrapper">
          {loading ? (
            <div className="loading">Loading...</div>
          ) : dataKosong ? (
            <div className="no-data">Not Found</div>
          ) : (
            <OutTable data={rows} columns={cols} tableClassName="excel-table" />
          )}
        </div>
      </div>
    );
  }
}

export default ExcelRequestImport;
