import { createSlice } from "@reduxjs/toolkit"

const completenessProductSlice = createSlice({
  name: "completenessProduct",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
    selectedProductId: '',
    selectedProductName: '',
    selectedNotes: '',
  },
  reducers: {
    setCompletenessProducts: (state, action) => {
      state.data = action.payload;
    },
    setCompletenessProduct: (state, action) => {
      state.detail = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setSelectedProductId: (state, action) => {
      state.selectedProductId = action.payload;
    },
    setSelectedProductName: (state, action) => {
      state.selectedProductName = action.payload;
    },
    setSelectedNotes: (state, action) => {
      state.selectedNotes = action.payload;
    },
  }
})

export const {
  deleteCompletenessProduct,
  setCompletenessProducts,
  setCurrentPage,
  setItemsPerPage,
  setCompletenessProduct,
  setTotal,
  setSelectedProductId,
  setSelectedProductName,
  setSelectedNotes,
} = completenessProductSlice.actions;
export default completenessProductSlice.reducer;
