import React from 'react'
import {
  Modal,
  Box,
  Button
} from "@mui/material";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";

function ModalDeleteCondition({
  isLoading, modalStyle,
  openmmodalDeleteCondition,
  handleCloseModalDeleteCondition, handleDeleteCondition
}) {
  return (
    <Modal
      open={openmmodalDeleteCondition}
      onClose={handleCloseModalDeleteCondition}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={modalStyle}>
        <h2>Konfirmasi Hapus</h2>
        <p>Apakah anda yakin untuk menghapus data ini?</p>
        <div>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              width: 190,
              height: 50,
              mt: 2,
              color: "#b34469",
              borderColor: "#b34469",
            }}
            onClick={handleCloseModalDeleteCondition}
          >
            Cancel
          </Button>
          <BootstrapButton
            variant="contained"
            disableRipple
            sx={{ width: 182, mt: 2, height: 50,  color: "#FFF" }}
            onClick={handleDeleteCondition}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "DELETE"}
          </BootstrapButton>
        </div>
      </Box>
    </Modal>
  )
}

export default ModalDeleteCondition
