import { setCompletenessProducts, setTotal } from '../redux/slices/completenessProductSlice';
import { setCompletenessStores, setTotalStore } from '../redux/slices/completenessStoreSlice';

export const fetchCompletenessProduct = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing/product-master/check`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setCompletenessProducts(data.data));
      dispatch(setTotal(data.pagination_info.total));
    } else {
      dispatch(setCompletenessProducts([]));
      console.error(`Failed to fetch product masters:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching product masters:`, error);
  }
};

export const fetchCompletenessStoreGT = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing/store-master/check`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setCompletenessStores(data.data));
      dispatch(setTotalStore(data.pagination_info.total));
    } else {
      dispatch(setCompletenessStores([]));
      console.error(`Failed to fetch store masters:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching store masters:`, error);
  }
};
