// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reportDB {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.filter_4 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.tabelpivot {
    overflow: auto;
    margin: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/style/reportdb.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,YAAY;AAChB","sourcesContent":[".reportDB {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n}\n.filter_4 {\n    display: flex;\n    justify-content: center;\n    margin-top: 20px;\n}\n.tabelpivot {\n    overflow: auto;\n    margin: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
