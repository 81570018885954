import { createSlice } from "@reduxjs/toolkit"

const productMasterSlice = createSlice({
  name: "productMaster",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
    selectedProductGroupId: '',
    selectedBrand: '',
    selectedCategory: '',
    selectedPrincipal: '',
    productMasterfilterColumnName: [],
    filterColumnName: '',
    filterColumnOperator: 'LIKE',
    filterColumnValue: '',
  },
  reducers: {
    // addToProductMaster: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setProductMasters: (state, action) => {
      state.data = action.payload;
    },
    setProductMaster: (state, action) => {
      state.detail = action.payload;
    },
    editProductMaster: (state, action) => {
      const editedProductMasterIndex = state.data.findIndex(ProductMaster => ProductMaster.id === action.payload.id);
      if (editedProductMasterIndex !== -1) {
        state.data[editedProductMasterIndex] = {
          ...state.data[editedProductMasterIndex], // Copy the existing properties of the Product master
          ...action.payload.updates, // Merge in the custom properties from the action payload
        };
      }
    },
    deleteProductMaster: (state, action) => {
      state.data = state.data.filter(ProductMaster => ProductMaster.id !== action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setSelectedProductGroupId: (state, action) => {
      state.selectedProductGroupId = action.payload;
    },
    setSelectedBrand: (state, action) => {
      state.selectedBrand = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedPrincipal: (state, action) => {
      state.selectedPrincipal = action.payload;
    },
    setProductMasterColumn: (state, action) => {
      state.productMasterfilterColumnName = action.payload;
    },
    setFilterColumnName: (state, action) => {
      state.filterColumnName = action.payload;
    },
    setFilterColumnOperator: (state, action) => {
      state.filterColumnOperator = action.payload;
    },
    setFilterColumnValue: (state, action) => {
      state.filterColumnValue = action.payload;
    },
  }
})

export const {
  deleteProductMaster,
  setProductMasters,
  setCurrentPage,
  setItemsPerPage,
  setProductMaster,
  editProductMaster,
  setTotal,
  setSelectedProductGroupId,
  setSelectedBrand,
  setSelectedCategory,
  setSelectedPrincipal,
  setProductMasterColumn,
  setFilterColumnName,
  setFilterColumnOperator,
  setFilterColumnValue,
} = productMasterSlice.actions;
export default productMasterSlice.reducer;
