import React from 'react'
import {
  Modal,
  Box,
  Button,
  Tooltip,
  IconButton
} from "@mui/material";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";
import TextField from '@mui/material/TextField';
import CloseIcon from "@mui/icons-material/Close";
import DataGridAnswerItem from "./DataGridAnswerItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

function ModalViewAnswerItem({
  isLoading,
  modalviewansweritem,
  setmodalviewansweritem,
  handleCancleAnswerItems,
  setIsCreateAnswerItem,
  answerItem,
  handleEditClick,
  handleOpenDeleteModal,
  editedAnswerId,
  setEditedAnswerId,
  handleUpadateAnswer,
  title,
  editTopAnswerItem,
  editdownAnswerItem,
  isAllowEditAnswer
}) {
  return (
    <Modal
      open={modalviewansweritem}
      onClose={() => setmodalviewansweritem(false)}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={{
        ...style,
        width: '90vw',
        margin: "0 auto",
        overflow: "auto",
        overflowX: "hidden",
        height: '90vh',
      }}>
        <h1>{title}</h1>

        <Tooltip title="Close Modal">
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCancleAnswerItems}
            sx={{ position: "absolute", top: 20, right: 20 }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <TextField
            id="outlined-basic"
            label="Update Answer"
            variant="outlined"
            name='answer_name'
            value={editedAnswerId.answer_name}
            onChange={(e) =>
              setEditedAnswerId({
                ...editedAnswerId,
                answer_name: e.target.value,
              })}
            multiline
            minRows={1}
            sx={{ width: '240px', mt: 2, height: 50, color: "#5AB2FF", borderColor: "#5AB2FF", }}
            disabled={!isAllowEditAnswer}
          />
          {
            isAllowEditAnswer &&
            <Button
              variant="outlined"
              disableRipple
              sx={{ width: '240px', mt: 2, mb: 2, height: 50, color: "#5AB2FF", borderColor: "#5AB2FF", }}
              disabled={isLoading}
              onClick={handleUpadateAnswer}
            >
              {isLoading ? "Loading..." : "Update Answer"}
            </Button>
          }
        </div>
        <BootstrapButton
          onClick={() => setIsCreateAnswerItem(true)}
          variant="outlined"
          disableRipple
          disabled={isLoading}
          style={{ margin: "5px" }}
          title="Buat Project Question"
        >
          <p style={{ color: "#fff", fontSize: "12px" }}>Create Answer Item</p>
        </BootstrapButton>

        {answerItem && answerItem.length > 0 && (
          <DataGridAnswerItem
            loading={isLoading}
            rows={answerItem}
            handleEditClick={handleEditClick}
            handleOpenDeleteModal={handleOpenDeleteModal}
            editTopAnswerItem={editTopAnswerItem}
            editdownAnswerItem={editdownAnswerItem}
          />
        )}
      </Box>
    </Modal>
  )
}

export default ModalViewAnswerItem
