import React from 'react'
import {
  Modal,
  Box,
  Button
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";

function StatusDataModal({
  isLoading, modalStyle,
  statusModalOpen,
  handleCloseStatusModal, handleStatus, title
}) {
  return (
    <Modal
      open={statusModalOpen}
      onClose={handleCloseStatusModal}
      aria-labelledby="status-modal-title"
      aria-describedby="status-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={modalStyle}>
        <h2>Konfirmasi Perubahan</h2>
        <p>Apakah anda yakin untuk mengubah status data ini menjadi {title}?</p>
        <div>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              width: 190,
              height: 50,
              mt: 2,
              color: "#b34469",
              borderColor: "#b34469",
            }}
            onClick={handleCloseStatusModal}
          >
            Cancel
          </Button>
          <BootstrapButton
            variant="contained"
            disableRipple
            sx={{ width: 182, mt: 2, height: 50,  color: "#FFF" }}
            onClick={handleStatus}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "UPDATE"}
          </BootstrapButton>
        </div>
      </Box>
    </Modal>
  )
}

export default StatusDataModal
