import { setDataSurveys, setTotal, setDataSurveyColumn } from "../redux/slices/dataSurveySlice";

export const fetchDataSurveys = async (dispatch, accessToken, customParams = {}, surveyId) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(
        ([key, value]) => value !== '' && value !== 'all' && value !== undefined && value !== null
      )
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/survey/store/${surveyId}`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setDataSurveys(data.data));
      dispatch(setTotal(data.pagination_info.total));
    } else {
      dispatch(setDataSurveys([]));
      console.error(`Failed to fetch data surveys:`, response.statusText);
    }
  } catch (error) {
    dispatch(setDataSurveys([]));
    console.error(`Error fetching data surveys:`, error);
  }
};

export const fetchDataSurveyColumn = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '' && value !== undefined)
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/survey-columns`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setDataSurveyColumn(data.data));
    } else {
      dispatch(setDataSurveyColumn([]));
      console.error(`Failed to fetch data survey column:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching data survey column:`, error);
  }
};
