import React, { useState } from "react";
import { useNavigate, Link, Link as RouterLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { teal } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Cookies from "js-cookie";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemButton from "@mui/material/ListItemButton";

// external
import LogoSivina from "../assets/images/bg/logo.png";
import "../style/navbar.css";

function AdminMenu({ open, anchorEl, handleClose, handleClickActive }) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      {[
        { label: "◎ Dashboard", href: "/dashboard" },
        { label: "◎ Project Management", href: "/project-management" },
        { label: "◎ Store Master", href: "/store-master" },
        { label: "◎ Product Master", href: "/product-master" },
        { label: "◎ Audit Plan", href: "/audit-plan" },
        { label: "◎ User Management", href: "/user-management" },
        { label: "◎ Data Survey", href: "/data-survey" },
        { label: "◎ Data Processing", href: "/data-processing" },
        { label: "◎ Non Projected Reported", href: "/non-projected-reported" },
        { label: "◎ Projected Reported", href: "/projected-reported" },
        // { label: "◎ Map", href: "/map-project" },
      ].map((item, index) => (
        <MenuItem key={index} component={RouterLink} to={item.href} onClick={handleClickActive}>
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

function CoAdminMenu({ open, anchorEl, handleClose, handleClickActive }) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      {[
        { label: "◎ Dashboard", href: "/dashboard" },
        { label: "◎ Store Master", href: "/store-master" },
        { label: "◎ Product Master", href: "/product-master" },
        { label: "◎ Audit Plan", href: "/audit-plan" },
        { label: "◎ Data Survey", href: "/data-survey" },
        { label: "◎ Non Projected Reported", href: "/non-projected-reported" },
        { label: "◎ Projected Reported", href: "/projected-reported" },
        // { label: "◎ Map", href: "/map-project" },
      ].map((item, index) => (
        <MenuItem key={index} component={RouterLink} to={item.href} onClick={handleClickActive}>
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

function TeamLeaderMenu({ open, anchorEl, handleClose, handleClickActive }) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      {[
        { label: "◎ Dashboard", href: "/dashboard" },
        { label: "◎ Store Master", href: "/store-master" },
        { label: "◎ Audit Plan", href: "/audit-plan" },
        { label: "◎ Data Survey", href: "/data-survey" },
      ].map((item, index) => (
        <MenuItem key={index} component={RouterLink} to={item.href} onClick={handleClickActive}>
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

function SurveyorMenu({ open, anchorEl, handleClose, handleClickActive }) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <MenuItem component={RouterLink} to="/data-survey" onClick={handleClickActive}>
        ◎ Data Survey
      </MenuItem>
    </Menu>
  );
}

function QCOfficerMenu({ open, anchorEl, handleClose, handleClickActive }) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <MenuItem component={RouterLink} to="/data-survey" onClick={handleClickActive}>
        ◎ Data Survey
      </MenuItem>
    </Menu>
  );
}

function Navbar(data) {
  const theme = createTheme({
    typography: {
      gokil: {
        fontFamily: "Red Hat Display",
        fontWeight: 700,
        fontSize: 16,
      },
      button: {
        fontFamily: "Montserrat",
        fontWeight: 500,
      },
    },
  });

  const navigate = useNavigate();
  // set up state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // function handle click
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickActive = (event) => {
    event.preventDefault();
    const hrefValue = event.currentTarget.getAttribute("href");
    // Redirect using the useNavigate hook
    navigate(hrefValue);
  };

  const handleLogout = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/logout`,
        {
          method: "POST",
        }
      );

      if (response.status === 200) {
        Cookies.remove("access_token");
        window.location.replace("login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Inside your Navbar component
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const navbarMenuList = {
    'A': <AdminMenu open={open} anchorEl={anchorEl} handleClose={handleClose} handleClickActive={handleClickActive} />,
    'COA': <CoAdminMenu open={open} anchorEl={anchorEl} handleClose={handleClose} handleClickActive={handleClickActive} />,
    'TL': <TeamLeaderMenu open={open} anchorEl={anchorEl} handleClose={handleClose} handleClickActive={handleClickActive} />,
    '13': <SurveyorMenu open={open} anchorEl={anchorEl} handleClose={handleClose} handleClickActive={handleClickActive} />,
    '14': <QCOfficerMenu open={open} anchorEl={anchorEl} handleClose={handleClose} handleClickActive={handleClickActive} />,
  };

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "white",
        color: "black",
        boxShadow: 0,
        borderBottom: 1,
        borderColor: "grey.200",
      }}
    >
      <Toolbar>
        {/* icon */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <img src={LogoSivina} alt="Logo Sivina" width={"40rem"} />
        </IconButton>
        {/* title */}
        <ThemeProvider theme={theme}>
          <Typography variant="gokil" component="div" sx={{ flexGrow: 1 }}>
            DATA COLLECTION SYSTEM FOR RDC AND RA SURVEY
          </Typography>
          {/* button active */}
          <Link
            component={RouterLink}
            to={open ? "basic-menu" : undefined}
            style={{ textDecoration: 'none' }}
          >
            <ListItemButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                textTransform: "none",
                fontWeight: 700,
                m: 1,
                color: 'black',
              }}
            >
              {data.active}
            </ListItemButton>
          </Link>
          {/* button dashboard */}
          {navbarMenuList[data.role_code]}
        </ThemeProvider>
        <Stack direction="row" spacing={2} sx={{ ml: 1 }}>
          <Avatar sx={{ bgcolor: teal[300], m: 1 }} onClick={handleOpenModal}>
            {data.initial}
          </Avatar>
        </Stack>
      </Toolbar>
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}

export default Navbar;
