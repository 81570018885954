import React from 'react'
import {
    Modal,
    Box,
    Button
} from "@mui/material";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";
import TextField from '@mui/material/TextField';

function ModalCreateAnswerItem({
    isLoading, 
    isCreateAnswerItem,
    setIsCreateAnswerItem,
    modalStyle,
    newAnswerItem,
    handleinputansweritem,
    handleCreateAnswerItem
}) {
    return (
        <Modal
            open={isCreateAnswerItem}
            onClose={() => setIsCreateAnswerItem(false)}
            aria-labelledby="delete-modal-title"
            aria-describedby="delete-modal-description"
            sx={{ border: 0 }}
        >
            <Box sx={modalStyle}>
                <TextField
                    id="outlined-basic"
                    label="code"
                    variant="outlined"
                    name='code'
                    value={newAnswerItem.code}
                    onChange={handleinputansweritem}
                    multiline
                    minRows={1}
                    sx={{ width: "90%", mb: 2, mr: 2, mt: 2}}
                />
                <TextField
                    id="outlined-basic"
                    label="answer"
                    variant="outlined"
                    name='answer'
                    value={newAnswerItem.answer}
                    onChange={handleinputansweritem}
                    multiline
                    minRows={1}
                    sx={{ width: "90%", mb: 2, mr: 2, mt: 2 }}
                />
                <div>
                    <Button
                        variant="outlined"
                        disableRipple
                        sx={{
                            width: 190,
                            height: 50,
                            mt: 2,
                            color: "#b34469",
                            borderColor: "#b34469",
                            mr: 1
                        }}
                        onClick={() => setIsCreateAnswerItem(false)}
                    >
                        Cancel
                    </Button>
                    <BootstrapButton
                        variant="outlined"
                        disableRipple
                        sx={{ width: 190, mt: 2, height: 50, color: "#fff", borderColor: "#5AB2FF", ml: 1 }}
                        disabled={isLoading}
                        onClick={handleCreateAnswerItem}
                    >
                        <p>{isLoading ? "Loading..." : "Create Answer Item"}</p>
                    </BootstrapButton>
                </div>
            </Box>
        </Modal>
    )
}

export default ModalCreateAnswerItem
