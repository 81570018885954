import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import {
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function DataGridCondition({ isLoading, rows, handleEditCondition, handleOpenDeleteModalCondition }) {

  const columns = [
    {
      field: "condition_name",
      headerClassName: "super-app-theme--header",
      width: 250,
      headerName: "CONDITION",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      width: 214,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip
              title="Edit Condition"
              onClick={() => handleEditCondition(params)}
            >
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Delete Condition"
              onClick={() => handleOpenDeleteModalCondition(params.row.id)}
            >
              <IconButton>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <DataGrid
      sx={{
        mt: 2,
        ml: 2,
        mr: 2,
        "& .super-app-theme--header": {
          backgroundColor: "rgb(6,157,174)",
          color: "#FFF",
          border: 2,
          fontWeight: "bold",
          borderColor: "rgb(234,243,248)",
        },
        border: 2,
        borderColor: "rgb(234,243,248)",
        fontSize: 15,
      }}
      loading={isLoading}
      getRowId={(row) => row.id}
      rows={rows}
      columns={columns}
      editMode="row"
      getRowHeight={() => 'auto'}

    // rowModesModel={rowModesModel}
    // slotProps={{
    //   toolbar: { rows, setRowModesModel },
    // }}
    />
  )
}

export default DataGridCondition
