import axios from "axios";

export const fetchPackSize = async (
  token,
  setFilteredPackSize,
  selectedFilteredCategory
) => {
  const queryParams = new URLSearchParams();

  if (selectedFilteredCategory && selectedFilteredCategory !== "") {
    queryParams.append("category_master_id", selectedFilteredCategory);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/product-master-attribute?atribut=packsize` +
    (queryString ? `&${queryString}` : "");

  if (selectedFilteredCategory) {
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let result_data = response?.data?.data;
      if (response.status === 200) {
        setFilteredPackSize(result_data);
      } else if (response.status === 404) {
        setFilteredPackSize([]);
      } else console.log("Error");
    } catch (e) {
      setFilteredPackSize([]);
    }
  } else if (!selectedFilteredCategory) {
    setFilteredPackSize([]);
  }
};

export const fetchPackType = async (
  token,
  setFilteredPackType,
  selectedFilteredCategory
) => {
  const queryParams = new URLSearchParams();

  if (selectedFilteredCategory && selectedFilteredCategory !== "") {
    queryParams.append("category_master_id", selectedFilteredCategory);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/product-master-attribute?atribut=packtype` +
    (queryString ? `&${queryString}` : "");

  if (selectedFilteredCategory) {
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let result_data = response?.data?.data;
      if (response.status === 200) {
        setFilteredPackType(result_data);
      } else if (response.status === 404) {
        setFilteredPackType([]);
      } else console.log("Error");
    } catch (e) {
      setFilteredPackType([]);
    }
  } else if (!selectedFilteredCategory) {
    setFilteredPackType([]);
  }};

export const fetchFlavour = async (
  token,
  setFilteredFlavour,
  selectedFilteredCategory
) => {
  const queryParams = new URLSearchParams();

  if (selectedFilteredCategory && selectedFilteredCategory !== "") {
    queryParams.append("category_master_id", selectedFilteredCategory);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/product-master-attribute?atribut=flavour` +
    (queryString ? `&${queryString}` : "");

  if (selectedFilteredCategory) {
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let result_data = response?.data?.data;
      if (response.status === 200) {
        setFilteredFlavour(result_data);
      } else if (response.status === 404) {
        setFilteredFlavour([]);
      } else console.log("Error");
    } catch (e) {
      setFilteredFlavour([]);
    }
  } else if (!selectedFilteredCategory) {
    setFilteredFlavour([]);
  }};
