import React from 'react'
import {
    Modal,
    Box,
    Button
} from "@mui/material";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";
import TextField from '@mui/material/TextField';

function ModalEditCondition({
    isLoading,
    isUpdateModalCondition,
    setIsUpdateModalCondition,
    modalStyle,
    editedCondition,
    seteditedCondition,
    handleUpdateCondition
}) {
    return (
        <Modal
            open={isUpdateModalCondition}
            onClose={() => setIsUpdateModalCondition(false)}
            aria-labelledby="delete-modal-title"
            aria-describedby="delete-modal-description"
            sx={{ border: 0 }}
        >
            <Box sx={modalStyle}>
                <h1>Update Condition</h1>
                <TextField
                     label="Condition Name"
                    name="condition_name"
                    value={editedCondition.condition_name}
                    sx={{ mb: 2, width: "95%" }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        style: { fontSize: '1.5rem', fontWeight: 'bold' },
                    }}
                    onChange={(e) =>
                        seteditedCondition({
                            ...editedCondition,
                            condition_name: e.target.value,
                        })}
                />
                <div>
                    <Button
                        variant="outlined"
                        disableRipple
                        sx={{
                            width: 190,
                            height: 50,
                            mt: 2,
                            color: "#b34469",
                            borderColor: "#b34469",
                            mr: 1
                        }}
                        onClick={() => setIsUpdateModalCondition(false)}
                    >
                        Cancel
                    </Button>
                    <BootstrapButton
                        variant="outlined"
                        disableRipple
                        sx={{ width: 190, mt: 2, height: 50, color: "#000000", borderColor: "#000000", ml: 1 }}
                        disabled={isLoading}
                        onClick={handleUpdateCondition}
                    >
                        <p>{isLoading ? "Loading..." : "Update Answer Item"}</p>
                    </BootstrapButton>
                </div>
            </Box>
        </Modal>
    )
}

export default ModalEditCondition
