import { setProjectManagements, setTotal, setProjectManagementColumn } from "../redux/slices/projectManagementSlice";

export const fetchProjectManagements = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/project`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setProjectManagements(data.data));
      dispatch(setTotal(data.pagination_info.total));
    } else {
      dispatch(setProjectManagements([]));
      console.error(`Failed to fetch project managements:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching project managements:`, error);
  }
};

export const fetchProjectManagementColumn = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '' && value !== undefined)
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/project-columns`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setProjectManagementColumn(data.data));
    } else {
      dispatch(setProjectManagementColumn([]));
      console.error(`Failed to fetch project management column:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching project management column:`, error);
  }
};
