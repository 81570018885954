import { createSlice } from "@reduxjs/toolkit"

const projectSlice = createSlice({
  name: "project",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 1,
    data: [],
    detail: {},
    projectStatus: [
      {
        "id": "0",
        "label": "Inactive"
      },
      {
        "id": "1",
        "label": "Active"
      },
    ]
  },
  reducers: {
    // addToProject: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setProjects: (state, action) => {
      state.data = action.payload;
    },
    setProject: (state, action) => {
      state.detail = action.payload;
    },
    // editProject: (state, action) => {
    //     const editedProjectIndex = state.data.findIndex(Project => Project.id === action.payload.id);
    //     if (editedProjectIndex !== -1) {
    //       state.data[editedProjectIndex] = action.payload;
    //     }
    //   },
    deleteProject: (state, action) => {
      state.data = state.data.filter(Project => Project.id !== action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
  }
})

export const { deleteProject, setProjects, setCurrentPage, setTotalPages, setItemsPerPage, setProject } = projectSlice.actions;
export default projectSlice.reducer;
