import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import {
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function DataGridAnswerItem({ isLoading, rows, handleEditClick, handleOpenDeleteModal, editTopAnswerItem, editdownAnswerItem }) {

  const columns = [
    {
      field: "code",
      headerClassName: "super-app-theme--header",
      width: 210,
      headerName: "CODE",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "answer",
      headerClassName: "super-app-theme--header",
      width: 210,
      headerName: "ANSWER",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "answer_item_order",
      headerClassName: "super-app-theme--header",
      width: 250,
      headerName: "Answer Item Order",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      width: 140,
      headerName: "ACTIONS",
      width: 214,
      renderCell: (params) => {
        const currentIndex = rows.findIndex(row => row.id === params.row.id);
        const rowDown = rows[currentIndex + 1];
        const rowTop = rows[currentIndex - 1];
        return (
          <div>
            <Tooltip
              title="Edit Answer Item"
              onClick={() => handleEditClick(params)}
            >
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Delete Answer Item"
              onClick={() => handleOpenDeleteModal(params.row.id)}
            >
              <IconButton>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Edit Posisi Top"
              onClick={() => {
                if (rowTop) {
                  editTopAnswerItem(params.row.id, rowTop.id);
                } else {
                  console.log(`tidak ada baris berikutnya`);
                }
              }}
            >
              <IconButton>
                <ArrowUpwardIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Edit Posisi Down"
              onClick={() => {
                if (rowDown) {
                  editdownAnswerItem(params.row.id, rowDown.id);
                } else {
                  console.log(`tidak ada baris berikutnya`);
                }
              }}
            >
              <IconButton>
                <ArrowDownwardIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <DataGrid
      sx={{
        mt: 2,
        ml: 2,
        mr: 2,
        "& .super-app-theme--header": {
          backgroundColor: "rgb(6,157,174)",
          color: "#FFF",
          border: 2,
          fontWeight: "bold",
          borderColor: "rgb(234,243,248)",
        },
        border: 2,
        borderColor: "rgb(234,243,248)",
        fontSize: 15,
      }}
      loading={isLoading}
      getRowId={(row) => row.id}
      rows={rows}
      columns={columns}
      editMode="row"
      getRowHeight={() => 'auto'}

    // rowModesModel={rowModesModel}
    // slotProps={{
    //   toolbar: { rows, setRowModesModel },
    // }}
    />
  )
}

export default DataGridAnswerItem
