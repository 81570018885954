import { Button, Dialog, ImageList, ImageListItem, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, styled, Typography, ButtonGroup } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from '@mui/icons-material/Upload';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { BootstrapButton } from '../../Button/BootstrapButton';
import { VisuallyHiddenInput } from '../../Button/VisuallyHiddenInput';

export const ModalAddStoreMaster = ({
  open,
  onClose,
  title,
  buttonTitle,
  formData,
  handleSubmit,
  handleChange,
  isLoading,
  items,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <span>{title}</span>
        <IconButton style={{ float: "right" }} onClick={onClose}>
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                name="store_id"
                label="Kode Outlet"
                value={formData.store_id}
                sx={{ width: "100%" }}
                onChange={handleChange.general}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                name="store_name"
                label="Nama Outlet"
                value={formData.store_name}
                sx={{ width: "100%" }}
                onChange={handleChange.general}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-store_type_code">Tipe Toko*</InputLabel>
                <Select
                  required
                  labelId="select-label-store_type_code"
                  name="store_type_code"
                  id="select_store_type_code"
                  value={formData.store_type_code}
                  onChange={handleChange.general}
                >
                  {items.dataStoreType?.length > 0 &&
                    items.dataStoreType?.map((data, i) => {
                      return <MenuItem value={data.id} key={data.id}>
                        {data.name}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-province_code">Provinsi*</InputLabel>
                <Select
                  required
                  labelId="select-label-province_code"
                  name="province_code"
                  id="select_province_code"
                  value={formData.province_code}
                  onChange={async (e) => {
                    handleChange.general(e);
                    handleChange.province(e);
                  }}
                >
                  {items.dataProvince?.length > 0 &&
                    items.dataProvince?.map((data, i) => {
                      return <MenuItem value={data.code} key={data.code}>
                        {data.name}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-regency_code">Kota/Kabupaten*</InputLabel>
                <Select
                  required
                  labelId="select-label-regency_code"
                  name="regency_code"
                  id="select_regency_code"
                  value={formData.regency_code}
                  onChange={async (e) => {
                    handleChange.general(e);
                    handleChange.regency(e);
                  }}
                >
                  {items.dataRegency?.length > 0 &&
                    items.dataRegency?.map((data, i) => {
                      return <MenuItem value={data.code} key={data.code}>
                        {data.name}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-district_code">Kecamatan*</InputLabel>
                <Select
                  required
                  labelId="select-label-district_code"
                  name="district_code"
                  id="select_district_code"
                  value={formData.district_code}
                  onChange={async (e) => {
                    handleChange.general(e);
                    handleChange.district(e);
                  }}
                >
                  {items.dataDistrict?.length > 0 &&
                    items.dataDistrict?.map((data, i) => {
                      return <MenuItem value={data.code} key={data.code}>
                        {data.name}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-sub_district_code">Kelurahan*</InputLabel>
                <Select
                  required
                  labelId="select-label-sub_district_code"
                  name="sub_district_code"
                  id="select_sub_district_code"
                  value={formData.sub_district_code}
                  onChange={handleChange.general}
                >
                  {items.dataSubDistrict?.length > 0 &&
                    items.dataSubDistrict?.map((data, i) => {
                      return <MenuItem value={data.code} key={data.code}>
                        {data.name}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="store_address"
                label="Alamat"
                value={formData.store_address}
                sx={{ width: "100%" }}
                onChange={handleChange.general}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="store_latitude"
                label="Latitude"
                value={formData.store_latitude}
                sx={{ width: "100%" }}
                onChange={handleChange.general}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="store_longitude"
                label="Longitude"
                value={formData.store_longitude}
                sx={{ width: "100%" }}
                onChange={handleChange.general}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-jenis_outlet">Jenis Outlet*</InputLabel>
                <Select
                  required
                  labelId="select-label-jenis_outlet"
                  name="jenis_outlet"
                  id="select_jenis_outlet"
                  value={formData.jenis_outlet}
                  onChange={handleChange.general}
                >
                  {items.dataJenisOutlet?.length > 0 &&
                    items.dataJenisOutlet?.map((data, i) => {
                      return <MenuItem value={data.desc} key={data.desc}>
                        {data.desc}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-tipe_survey">Tipe Survey*</InputLabel>
                <Select
                  required
                  labelId="select-label-tipe_survey"
                  name="tipe_survey"
                  id="select_tipe_survey"
                  value={formData.tipe_survey}
                  onChange={handleChange.general}
                >
                  {items.dataTipeSurvey?.length > 0 &&
                    items.dataTipeSurvey?.map((data, i) => {
                      return <MenuItem value={data.desc} key={data.desc}>
                        {data.desc}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-tipe_jalan">Tipe Jalan*</InputLabel>
                <Select
                  required
                  labelId="select-label-tipe_jalan"
                  name="tipe_jalan"
                  id="select_tipe_jalan"
                  value={formData.tipe_jalan}
                  onChange={handleChange.general}
                >
                  {items.dataTipeJalan?.length > 0 &&
                    items.dataTipeJalan?.map((data, i) => {
                      return <MenuItem value={data.desc} key={data.desc}>
                        {data.desc}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-tipe_lokasi">Tipe Lokasi*</InputLabel>
                <Select
                  required
                  labelId="select-label-tipe_lokasi"
                  name="tipe_lokasi"
                  id="select_tipe_lokasi"
                  value={formData.tipe_lokasi}
                  onChange={handleChange.general}
                >
                  {items.dataTipeLokasi?.length > 0 &&
                    items.dataTipeLokasi?.map((data, i) => {
                      return <MenuItem value={data.desc} key={data.desc}>
                        {data.desc}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  width: 190,
                  height: 50,
                  mt: 2,
                  color: "#b34469",
                  borderColor: "#b34469",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={8} sm={4}>
              <BootstrapButton
                variant="contained"
                disableRipple
                sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : buttonTitle}
              </BootstrapButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export const ModalEditStoreMaster = ({
  open,
  onClose,
  title,
  buttonTitle,
  formData,
  handleSubmit,
  handleChange,
  isLoading,
  items,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <span>{title}</span>
        <IconButton style={{ float: "right" }} onClick={onClose}>
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                name="store_id"
                label="Kode Outlet"
                value={formData.store_id}
                sx={{ width: "100%" }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                name="store_name"
                label="Nama Outlet"
                value={formData.store_name}
                sx={{ width: "100%" }}
                onChange={handleChange.general}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-store_type_code">Tipe Toko*</InputLabel>
                <Select
                  required
                  labelId="select-label-store_type_code"
                  name="store_type_code"
                  id="select_store_type_code"
                  value={formData.store_type_code}
                  onChange={handleChange.general}
                >
                  {items.dataStoreType?.length > 0 &&
                    items.dataStoreType?.map((data, i) => {
                      return <MenuItem value={data.id} key={data.id}>
                        {data.name}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-province_code">Provinsi*</InputLabel>
                <Select
                  required
                  labelId="select-label-province_code"
                  name="province_code"
                  id="select_province_code"
                  value={formData.province_code}
                  onChange={async (e) => {
                    handleChange.general(e);
                    handleChange.province(e);
                  }}
                >
                  {items.dataProvince?.length > 0 &&
                    items.dataProvince?.map((data, i) => {
                      return <MenuItem value={data.code} key={data.code}>
                        {data.name}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-regency_code">Kota/Kabupaten*</InputLabel>
                <Select
                  required
                  labelId="select-label-regency_code"
                  name="regency_code"
                  id="select_regency_code"
                  value={formData.regency_code}
                  onChange={async (e) => {
                    handleChange.general(e);
                    handleChange.regency(e);
                  }}
                >
                  {items.dataRegency?.length > 0 &&
                    items.dataRegency?.map((data, i) => {
                      return <MenuItem value={data.code} key={data.code}>
                        {data.name}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-district_code">Kecamatan*</InputLabel>
                <Select
                  required
                  labelId="select-label-district_code"
                  name="district_code"
                  id="select_district_code"
                  value={formData.district_code}
                  onChange={async (e) => {
                    handleChange.general(e);
                    handleChange.district(e);
                  }}
                >
                  {items.dataDistrict?.length > 0 &&
                    items.dataDistrict?.map((data, i) => {
                      return <MenuItem value={data.code} key={data.code}>
                        {data.name}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-sub_district_code">Kelurahan*</InputLabel>
                <Select
                  required
                  labelId="select-label-sub_district_code"
                  name="sub_district_code"
                  id="select_sub_district_code"
                  value={formData.sub_district_code}
                  onChange={handleChange.general}
                >
                  {items.dataSubDistrict?.length > 0 &&
                    items.dataSubDistrict?.map((data, i) => {
                      return <MenuItem value={data.code} key={data.code}>
                        {data.name}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="store_address"
                label="Alamat"
                value={formData.store_address}
                sx={{ width: "100%" }}
                onChange={handleChange.general}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="store_latitude"
                label="Latitude"
                value={formData.store_latitude}
                sx={{ width: "100%" }}
                onChange={handleChange.general}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="store_longitude"
                label="Longitude"
                value={formData.store_longitude}
                sx={{ width: "100%" }}
                onChange={handleChange.general}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-jenis_outlet">Jenis Outlet*</InputLabel>
                <Select
                  required
                  labelId="select-label-jenis_outlet"
                  name="jenis_outlet"
                  id="select_jenis_outlet"
                  value={formData.jenis_outlet}
                  onChange={handleChange.general}
                >
                  {items.dataJenisOutlet?.length > 0 &&
                    items.dataJenisOutlet?.map((data, i) => {
                      return <MenuItem value={data.desc} key={data.desc}>
                        {data.desc}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-tipe_survey">Tipe Survey*</InputLabel>
                <Select
                  required
                  labelId="select-label-tipe_survey"
                  name="tipe_survey"
                  id="select_tipe_survey"
                  value={formData.tipe_survey}
                  onChange={handleChange.general}
                >
                  {items.dataTipeSurvey?.length > 0 &&
                    items.dataTipeSurvey?.map((data, i) => {
                      return <MenuItem value={data.desc} key={data.desc}>
                        {data.desc}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-tipe_jalan">Tipe Jalan*</InputLabel>
                <Select
                  required
                  labelId="select-label-tipe_jalan"
                  name="tipe_jalan"
                  id="select_tipe_jalan"
                  value={formData.tipe_jalan}
                  onChange={handleChange.general}
                >
                  {items.dataTipeJalan?.length > 0 &&
                    items.dataTipeJalan?.map((data, i) => {
                      return <MenuItem value={data.desc} key={data.desc}>
                        {data.desc}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label-tipe_lokasi">Tipe Lokasi*</InputLabel>
                <Select
                  required
                  labelId="select-label-tipe_lokasi"
                  name="tipe_lokasi"
                  id="select_tipe_lokasi"
                  value={formData.tipe_lokasi}
                  onChange={handleChange.general}
                >
                  {items.dataTipeLokasi?.length > 0 &&
                    items.dataTipeLokasi?.map((data, i) => {
                      return <MenuItem value={data.desc} key={data.desc}>
                        {data.desc}
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  width: 190,
                  height: 50,
                  mt: 2,
                  color: "#b34469",
                  borderColor: "#b34469",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={8} sm={4}>
              <BootstrapButton
                variant="contained"
                disableRipple
                sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : buttonTitle}
              </BootstrapButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export const ModalViewStoreMaster = ({
  open,
  onClose,
  title,
  dataSurveyDetail,
  dataSurveyDetailPhoto
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <span>{title}</span>
        <IconButton style={{ float: "right" }} onClick={onClose}>
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ padding: '20px' }}>
          <div>
            <p>{dataSurveyDetail?.store_name}</p>
            {dataSurveyDetailPhoto.length > 0 ?
              <ImageList sx={{ width: 400, height: 250 }} cols={2}>
                {dataSurveyDetailPhoto.map((item) => (
                  <ImageListItem key={item}>
                    <img
                      src={`${item.file}`}
                      alt={item.id}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              : 'Tidak ada foto'}
            <p>
              {dataSurveyDetail ? `${dataSurveyDetail.province_name}, ${dataSurveyDetail.regency_name}, ${dataSurveyDetail.district_name}, ${dataSurveyDetail.sub_district_name}` : null}
            </p>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const ModalImportStoreMaster = ({
  open,
  onClose,
  title,
  fileUrl,
  buttonTitle,
  handleSubmit,
  handleChange,
  handleExport,
  isLoading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <span>{title}</span>
        <IconButton style={{ float: "right" }} onClick={onClose}>
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ButtonGroup>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            color="warning"
            startIcon={<RuleFolderIcon />}
          >
            Worksheet
            <VisuallyHiddenInput onClick={handleExport.worksheet} />
          </Button>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            color="warning"
            startIcon={<NoteAltIcon />}
          >
            Template
            <VisuallyHiddenInput onClick={handleExport.template} />
          </Button>
        </ButtonGroup>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={12}>
              <Typography>
                {
                  fileUrl === '' ? 'Tidak ada file yang terpilih' : fileUrl
                }
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                startIcon={<UploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput type="file" name="file" required onChange={(event) => {
                  const file = event.target.files?.[0];
                  if (file) {
                    handleChange.excel(file);
                    handleChange.fileUrl(file.name)
                  } else {
                    handleChange.excel('');
                    handleChange.fileUrl("");
                  }
                }} />
              </Button>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  width: 190,
                  height: 50,
                  mt: 2,
                  color: "#b34469",
                  borderColor: "#b34469",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={8} sm={4}>
              <BootstrapButton
                variant="contained"
                disableRipple
                sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : buttonTitle}
              </BootstrapButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
