import './index.css';
import "./App.css"
import "./style/form.css"
import "./style/landing.css"
import "./style/store.css"
import "./style/master.css"
import "./style/data.css"
import "./style/area.css"
import "./style/tabel.css"
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Provider } from "react-redux";
import store from "./redux/store";
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import Home from './Pages/Home'
import Login from './Pages/Login'
import NotFound from './Pages/NotFound'
import ProjectManagement from './Pages/ProjectManagement'
// import StoreMaster from './Pages/StoreMaster'
import ProductMaster from './Pages/ProductMaster'
import DataSurvey from './Pages/DataSurvey'
import DataSurveyTwo from './Pages/DataSurvey/DataSurvey'
import ViewDataTrx from './Pages/DataSurvey/ViewDataTrx'
import AuditPlan from './Pages/AuditPlan'
import Dashboard from './Pages/Dashboard'
import UserManagement from './Pages/UserManagement'
import SetUpMasterProduct from './Pages/ProductMaster/SetUp/SetUpMasterProduct'
import SetUpStoreMaster from './Pages/StoreMaster/SetupStoreMaster/Setup'
import ProcessReportNonProjected from './Pages/Report/ReportNonProjected/process'
import ExcelRequestImport from './Pages/Report/ReportNonProjected/ExcelRequestImport'
import ReportProjected from './Pages/Report/ReportProjected/ReportProjected'
import ReportProjectedRawData from './Pages/Report/ReportProjected/ReportProjectedRawData'
import ProjectCustomQuestion from './Pages/ProjectManagement/ProjectCustomQuestion'
import ProjectCustomQuestionStore from './Pages/ProjectManagement/ProjectCustomQuestionStore'
// import Product from './Pages/Product'

import 'react-toastify/dist/ReactToastify.min.css';
import StoreMasterTwo from './Pages/StoreMasterTwo';
import DataProcessing from './Pages/DataProcessing';
import DataProcessingDev from './Pages/DataProcessingDev';
import { CompletenessProduct, CompletenessStoreGT} from './Pages/DataProcessing/DataProcessingCompletenessCheck';
import PublicPhotoStore from './Pages/PublicPhotoStore';
import PublicPhotoProduct from './Pages/PublicPhotoProduct';
import ProductMasterTwo from './Pages/ProductMasterTwo';
import Map from './Pages/Map';
import MapStore from './Pages/Map/MapStore';
import AuditPlanTwo from './Pages/AuditPlanTwo';
import DataSurveyThree from './Pages/DataSurveyTwo';
import ProjectManagementTwoMaster from './Pages/ProjectManagementTwoMaster';
import CrosscodingBrand from './Pages/DataProcessingDev/Crosscoding/Brand';
import CrosscodingProduct from './Pages/DataProcessingDev/Crosscoding/Product';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFound />
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/dashboard",
    element: <Dashboard />
  },
  {
    path: "/project-management",
    element: <ProjectManagement /> // only show adhoc for custom question
  },
  {
    path: "/v1/project-management/master",
    element: <ProjectManagementTwoMaster /> // for master edit (able to edit rdc)
  },
  {
    path: "/project-management/custom-question-sku/:projectId",
    element: <ProjectCustomQuestion />
  },
  {
    path: "/project-management/custom-question-store/:projectId",
    element: <ProjectCustomQuestionStore />
  },
  {
    path: "/store-master",
    element: <StoreMasterTwo />
  },
  // {
  //   path: "/v1/store-master",
  //   element: <StoreMaster />
  // },
  {
    path: "/product-master",
    element: <ProductMaster />
  },
  {
    path: "/v1/product-master",
    element: <ProductMasterTwo />
  },
  {
    path: "/data-survey",
    element: <DataSurveyTwo />
  },
  {
    path: "/v1/data-survey",
    element: <DataSurvey />
  },
  {
    path: "/v2/data-survey",
    element: <DataSurveyThree />
  },
  {
    path: "/data-survey/:surveyId/:storeId",
    element: <ViewDataTrx />
  },
  {
    path: "/audit-plan",
    element: <AuditPlan />
  },
  {
    path: "/v1/audit-plan",
    element: <AuditPlanTwo />
  },
  {
    path: "/user-management",
    element: <UserManagement />
  },
  {
    path: "/data-processing",
    element: <DataProcessing />
  },
  {
    path: "/dev/data-processing",
    element: <DataProcessingDev />
  },
  {
    path: "/dev/data-processing/crosscode-brand",
    element: <CrosscodingBrand />
  },
  {
    path: "/dev/data-processing/crosscode-product",
    element: <CrosscodingProduct />
  },
  {
    path: "/non-projected-reported",
    element: <ProcessReportNonProjected />
  },
  {
    path: "/data-processing/completeness-check-store/:id",
    element: <CompletenessStoreGT />
  },
  {
    path: "/data-processing/completeness-check-product/:id",
    element: <CompletenessProduct />
  },
  {
    path: "/non-projected-reported/preview/:selectedReportGroup/:selectedReport",
    element: <ExcelRequestImport />
  },
  {
    path: "/projected-reported",
    element: <ReportProjected />
  },
  {
    path: "/projected-reported-tabular",
    element: <ReportProjectedRawData />
  },
  {
    path: "/setup-product-master",
    element: <SetUpMasterProduct />
  },
  {
    path: "/setup-store-master",
    element: <SetUpStoreMaster />
  },
  {
    path: "/public-photo/store/:storeId",
    element: <PublicPhotoStore />
  },
  {
    path: "/public-photo/product/:productId",
    element: <PublicPhotoProduct />
  },
  // {
  //   path: "/map-project",
  //   element: <Map />
  // },
  {
    path: "/map-store-master",
    element: <MapStore />
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
