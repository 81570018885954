// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width:430px) {
    .searchbar-user {
        width: 80%;
    }

    .button-user {
        margin-top: 20px;
        margin-left: -15px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/style/usermanagement.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;IACd;;IAEA;QACI,gBAAgB;QAChB,kBAAkB;IACtB;AACJ","sourcesContent":["@media only screen and (max-width:430px) {\n    .searchbar-user {\n        width: 80%;\n    }\n\n    .button-user {\n        margin-top: 20px;\n        margin-left: -15px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
