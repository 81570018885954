import { MapContainer, TileLayer, GeoJSON, Marker, Popup, LayersControl } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default function MapLayout({
  position,
  zoom,
  scrollWheelZoom,
  geoJSONData,
  onEachFeature,
  styleFeature,
  styleContainer,
  markersData,
  renderDataDetail,
  pathOptions
}) {
  
  return (
    <MapContainer
      center={position}
      zoom={zoom}
      scrollWheelZoom={scrollWheelZoom}
      style={styleContainer}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenStreetMap.HOT">
          <TileLayer
            attribution='&copy; OpenStreetMap contributors, Tiles style by Humanitarian OpenStreetMap Team hosted by OpenStreetMap France'
            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenTopoMap">
        <TileLayer
            attribution='Map data: © OpenStreetMap contributors, SRTM | Map style: © OpenTopoMap (CC-BY-SA)'
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Tanpa Peta">
          <TileLayer url="" />
        </LayersControl.BaseLayer>

        <LayersControl.Overlay checked name="GeoJSON Layer">
          {geoJSONData && <GeoJSON data={geoJSONData} onEachFeature={onEachFeature} style={styleFeature} pathOptions={pathOptions} />}
        </LayersControl.Overlay>

        {/* <LayersControl.Overlay checked name="Markers"> */}
        {markersData?.data?.map((data) => {
          const isValidLocation =
            data.longitude !== null && !isNaN(data.longitude) && !isNaN(data.latitude);

          if (!isValidLocation) return null;

          // Create the icon instance inside the Marker component
          const icon = L.icon({
            iconUrl: data.icon.options.iconUrl,
            iconSize: data.icon.options.iconSize,
            className: data.icon.options.className,
          });

          return (
            <Marker
              position={[data.longitude, data.latitude]}
              key={data.id}
              icon={icon}
            >
              <Popup>{renderDataDetail(data)}</Popup>
            </Marker>
          );
        })}
        {/* </LayersControl.Overlay> */}
      </LayersControl>
    </MapContainer>
  );
}
