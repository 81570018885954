import { React, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";


function EditDataModal({
  isLoading, editedUser,
  editModalOpen, setEditedUser, modalStyle,
  handleUpdateUser, handleCloseEditModal
}) {
  // password management
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal
      open={editModalOpen}
      onClose={handleCloseEditModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box
        sx={modalStyle}
        style={{
          maxHeight: "400px",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <h2>Edit User</h2>
        <div>
        <TextField
          variant="filled"
          name="full_name"
          label="Full Name"
          value={editedUser.full_name}
          onChange={(e) =>
            setEditedUser({
              ...editedUser,
              full_name: e.target.value,
            })
          }
          sx={{ width: "100%", mb: 2 }}
        />
        <FormControl sx={{ mb: 2, width: "100%" }} variant="filled">
          <InputLabel htmlFor="filled-adornment-password-edit">
            Password
          </InputLabel>
          <FilledInput
            name="password"
            autoComplete="off"
            id="filled-adornment-password-edit"
            type={showPassword ? "text" : "password"}
            value={editedUser.password ?? ''}
            onChange={(e) =>
              setEditedUser({
                ...editedUser,
                password: e.target.value,
              })
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        </div>
        <Button
          variant="outlined"
          disableRipple
          sx={{
            width: 190,
            height: 50,
            mt: 2,
            color: "#b34469",
            borderColor: "#b34469",
          }}
          onClick={handleCloseEditModal}
        >
          Cancel
        </Button>
        <BootstrapButton
          variant="contained"
          disableRipple
          sx={{ width: 190, height: 50, mt: 2, ml: 0.3 }}
          onClick={handleUpdateUser}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "UPDATE"}
        </BootstrapButton>
      </Box>
    </Modal>
  )
}

export default EditDataModal
