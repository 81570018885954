// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-customquestion {
    width:  47vw;
}
.doubleBox-customquestion {
    display: flex;
    justify-content: center; 
    margin-bottom: 20px;
    gap: 10px;
    margin-top: 20px;
}
.create-and-update-button {
    float: right;
    margin: 10px;
}
@media only screen and (max-width: 810px) {
    .create-and-update-button {
        float: right;
        margin: 10px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
  }
@media only screen and (max-width: 750px) {
    .doubleBox-customquestion {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-items: center;
    }
    .box-customquestion {
        margin-right: 20px;
    }
    .box-customquestion {
        width: 90vw;
    }
  }`, "",{"version":3,"sources":["webpack://./src/style/customquestion.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI;QACI,YAAY;QACZ,YAAY;QACZ,aAAa;QACb,qCAAqC;IACzC;EACF;AACF;IACI;QACI,aAAa;QACb,qCAAqC;QACrC,qBAAqB;QACrB,mBAAmB;IACvB;IACA;QACI,kBAAkB;IACtB;IACA;QACI,WAAW;IACf;EACF","sourcesContent":[".box-customquestion {\n    width:  47vw;\n}\n.doubleBox-customquestion {\n    display: flex;\n    justify-content: center; \n    margin-bottom: 20px;\n    gap: 10px;\n    margin-top: 20px;\n}\n.create-and-update-button {\n    float: right;\n    margin: 10px;\n}\n@media only screen and (max-width: 810px) {\n    .create-and-update-button {\n        float: right;\n        margin: 10px;\n        display: grid;\n        grid-template-columns: repeat(1, 1fr);\n    }\n  }\n@media only screen and (max-width: 750px) {\n    .doubleBox-customquestion {\n        display: grid;\n        grid-template-columns: repeat(1, 1fr);\n        justify-items: center;\n        align-items: center;\n    }\n    .box-customquestion {\n        margin-right: 20px;\n    }\n    .box-customquestion {\n        width: 90vw;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
