import { setProvinces, setTotal } from "../redux/slices/provinceSlice";

export const fetchProvince = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/area/provinces`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      dispatch(setProvinces(data.data));
      dispatch(setTotal(data.pagination_info.total));
    } else {
      console.error(`Failed to fetch provinces:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching provinces:`, error);
  }
};

export const fetchRegency = async (accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/area/regencies`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      console.error(`Failed to fetch regencies:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching regencies:`, error);
  }
};

export const fetchDistrict = async (accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/area/districts`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      console.error(`Failed to fetch districts:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching districts:`, error);
  }
};

export const fetchSubDistrict = async (accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/area/sub-districts`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      console.error(`Failed to fetch sub-districts:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching sub-districts:`, error);
  }
};
