import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapButton } from '../../Button/BootstrapButton';

export const ModalConfirmation = ({
  open,
  onClose,
  title,
  description,
  isLoading,
  handleClick,
  confirmationText,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <span>{title}</span>
        <IconButton style={{ float: "right" }} onClick={onClose}>
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disableRipple
          sx={{
            width: 190,
            height: 50,
            mt: 2,
            color: "#b34469",
            borderColor: "#b34469",
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <BootstrapButton
          variant="contained"
          disableRipple
          sx={{ width: 182, mt: 2, height: 50, color: "#FFF" }}
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : confirmationText}
        </BootstrapButton>
      </DialogActions>
    </Dialog>
  );
};
