import React from 'react'
import {
  Modal,
  Box,
  Button,
  Tooltip,
  IconButton
} from "@mui/material";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";
import TextField from '@mui/material/TextField';
import CloseIcon from "@mui/icons-material/Close";

function ModalUpdateVersion({
  updateVersionLog,
  setUpdateVersionLog,
  isLoading,
  isUpdateVersion,
  setIsUpdateVersion,
  modalStyle,
  handleUpdateVersion
}) {
  return (
    <Modal
      open={isUpdateVersion}
      onClose={() => setIsUpdateVersion(false)}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={modalStyle}>
        <Tooltip title="Close Modal">
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setIsUpdateVersion(false)}
            sx={{ position: "absolute", top: 20, right: 20 }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <h1>Update Versi ?</h1>
        <TextField
          id="outlined-basic"
          label="Log"
          variant="outlined"
          name='log'
          value={updateVersionLog}
          onChange={(e) => setUpdateVersionLog(e.target.value)}
          multiline
          minRows={1}
          sx={{ width: "90%", mb: 2, mr: 2, mt: 2 }}
        />
        <Button
          variant="outlined"
          disableRipple
          sx={{
            width: 190,
            height: 50,
            mt: 2,
            color: "#b34469",
            borderColor: "#b34469",
            mr: 1
          }}
          onClick={() => setIsUpdateVersion(false)}
        >
          Cancel
        </Button>
        <BootstrapButton
          variant="outlined"
          disableRipple
          sx={{ width: 190, mt: 2, height: 50, color: "#000000", borderColor: "#000000", ml: 1 }}
          disabled={isLoading}
          onClick={handleUpdateVersion}
        >
          <p>{isLoading ? "Loading..." : "Update Versi"}</p>
        </BootstrapButton>
      </Box>
    </Modal>
  )
}

export default ModalUpdateVersion
