import React from 'react'
import {
  TextField,
  Autocomplete,
} from "@mui/material";

function SearchBarAudit({id, value, data, handleChange, label, width}) {
  return (
    <Autocomplete
      disablePortal
      id={id}
      size="small"
      color="secondary"
      sx={{
        width: {width},
        height: 40,
        marginRight: 1,
        marginTop: 1,
      }}
      value={value}
      options={data}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label={label} />
      )}
    />
  )
}

export default SearchBarAudit
