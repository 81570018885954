import { createSlice } from "@reduxjs/toolkit"

const storeTypeSlice = createSlice({
  name: "storeType",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
  },
  reducers: {
    // addToStoreType: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setStoreTypes: (state, action) => {
      state.data = action.payload;
    },
    setStoreType: (state, action) => {
      state.detail = action.payload;
    },
    editStoreType: (state, action) => {
      const editedStoreTypeIndex = state.data.findIndex(StoreType => StoreType.id === action.payload.id);
      if (editedStoreTypeIndex !== -1) {
        state.data[editedStoreTypeIndex] = {
          ...state.data[editedStoreTypeIndex], // Copy the existing properties of the store Type
          ...action.payload.updates, // Merge in the custom properties from the action payload
        };
      }
    },
    deleteStoreType: (state, action) => {
      state.data = state.data.filter(StoreType => StoreType.id !== action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
  }
})

export const { deleteStoreType, setStoreTypes, setCurrentPage, setItemsPerPage, setStoreType, editStoreType, setTotal } = storeTypeSlice.actions;
export default storeTypeSlice.reducer;
