import React from 'react'

// page not found
function NotFound() {
  return (
    <div style={{'padding': '10rem'}}>
      <h1>404 NOT FOUND</h1>
    </div>
  )
}

export default NotFound