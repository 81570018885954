import { createSlice } from "@reduxjs/toolkit";

const tipeProjectSlice = createSlice({
  name: "tipeProject",
  initialState: {
    tipe_projects: [],
    detail: {},
  },
  reducers: {
    setTipeProject: (state, action) => {
      state.tipe_projects = action.payload; 
    },
  }
});

export const {
  setTipeProject
} = tipeProjectSlice.actions;

export default tipeProjectSlice.reducer;
