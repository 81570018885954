import React from 'react'
import {
    Modal,
    Box,
    Button,
    Tooltip,
    IconButton
} from "@mui/material";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";
import TextField from '@mui/material/TextField';
import DataGridAnswer from "./DataGridAnswer";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 200,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
};

function ModalCreateAnswer({
    isLoading, 
    modalCreateAnswer,
    setModalCreateAnswer,
    modalStyle,
    createnewanswer,
    handleinputnewAnswer,
    handlecreatenewanswer,
    answer,
    handleDeleteAnswerId
}) {
    return (
        <Modal
        open={modalCreateAnswer}
        onClose={() => setModalCreateAnswer(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}>
                  <Box sx={{
                ...style,
                width: '90vw',
                margin: "0 auto",
                overflow: "auto",
                overflowX: "hidden",
                height: '90vh',
            }}>
            <div style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <h1>Create Answer</h1>
            </div>

            <Tooltip title="Close Modal">
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setModalCreateAnswer(false)}
                    sx={{ position: "absolute", top: 20, right: 20 }}
                >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
            <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
            }}>
                <TextField
                    id="outlined-basic"
                    label="New Answer"
                    variant="outlined"
                    name='answer_name'
                    value={createnewanswer.answer_name}
                    onChange={handleinputnewAnswer}
                    multiline
                    minRows={1}
                    sx={{ width: 420, mb: 2, mr: 2, mt: 2 }}
                />
                <Button
                    variant="outlined"
                    disableRipple
                    sx={{ width: 420, mt: 2, height: 50, color: "#5AB2FF", borderColor: "#5AB2FF", }}
                    disabled={isLoading}
                    onClick={handlecreatenewanswer}
                >
                    Create
                </Button>
                </div>
                <DataGridAnswer
                    isLoading={isLoading}
                    rows={answer}
                    handleOpenDeleteModal={handleDeleteAnswerId}
                />
        </Box>
    </Modal>
    )
}

export default ModalCreateAnswer
