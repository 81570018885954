// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbar {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.button-download {
    height: 40px;
    margin-right: 1px;
}

/*  is_negative true */
.negative-row {
    background-color: #FFF67E;
}

/*  is_negative false */
.positive-row {
    background-color: #fff;
}

@media only screen and (max-width:850px) {
    .searchbar {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width:750px) {
    .searchbar {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width:450px) {
    .searchbar {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .button-download {
        height: 35px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/style/datasurvey.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;AACzC;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA,sBAAsB;AACtB;IACI,yBAAyB;AAC7B;;AAEA,uBAAuB;AACvB;IACI,sBAAsB;AAC1B;;AAEA;IACI;QACI,aAAa;QACb,qCAAqC;IACzC;AACJ;;AAEA;IACI;QACI,aAAa;QACb,qCAAqC;IACzC;AACJ;;AAEA;IACI;QACI,aAAa;QACb,qCAAqC;IACzC;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".searchbar {\n    display: grid;\n    grid-template-columns: repeat(5, 1fr);\n}\n\n.button-download {\n    height: 40px;\n    margin-right: 1px;\n}\n\n/*  is_negative true */\n.negative-row {\n    background-color: #FFF67E;\n}\n\n/*  is_negative false */\n.positive-row {\n    background-color: #fff;\n}\n\n@media only screen and (max-width:850px) {\n    .searchbar {\n        display: grid;\n        grid-template-columns: repeat(4, 1fr);\n    }\n}\n\n@media only screen and (max-width:750px) {\n    .searchbar {\n        display: grid;\n        grid-template-columns: repeat(3, 1fr);\n    }\n}\n\n@media only screen and (max-width:450px) {\n    .searchbar {\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n    }\n\n    .button-download {\n        height: 35px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
