import { createSlice } from "@reduxjs/toolkit"

const tipeLokasiSlice = createSlice({
  name: "tipeLokasi",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
  },
  reducers: {
    // addToTipeLokasi: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setTipeLokasis: (state, action) => {
      state.data = action.payload;
    },
    setTipeLokasi: (state, action) => {
      state.detail = action.payload;
    },
    editTipeLokasi: (state, action) => {
      const editedTipeLokasiIndex = state.data.findIndex(TipeLokasi => TipeLokasi.id === action.payload.id);
      if (editedTipeLokasiIndex !== -1) {
        state.data[editedTipeLokasiIndex] = {
          ...state.data[editedTipeLokasiIndex], // Copy the existing properties of the store Type
          ...action.payload.updates, // Merge in the custom properties from the action payload
        };
      }
    },
    deleteTipeLokasi: (state, action) => {
      state.data = state.data.filter(TipeLokasi => TipeLokasi.id !== action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
  }
})

export const { deleteTipeLokasi, setTipeLokasis, setCurrentPage, setItemsPerPage, setTipeLokasi, editTipeLokasi, setTotal } = tipeLokasiSlice.actions;
export default tipeLokasiSlice.reducer;
