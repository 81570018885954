import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import { Link as RouterLink } from "react-router-dom";

function DataGridUserManagement({
  isLoading,
  rows,
  handleEditClick,
  handleOpenStatusModal,
  handleOpenProjectCustom,
}) {
  const columns = [
    {
      field: "project_name",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "NAMA PROJEK",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "project_type_name",
      headerClassName: "super-app-theme--header",
      width: 140,
      headerName: "TIPE PROJEK",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    // {
    //   field: "project_start_at",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "START",
    //
    //   align: "left",
    //   headerAlign: "center",
    //   editable: false,
    // },
    // {
    //   field: "project_end_at",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "END",
    //
    //   align: "left",
    //   headerAlign: "center",
    //   editable: false,
    // },
    {
      field: "audit_plan_master_name",
      headerClassName: "super-app-theme--header",
      width: 140,
      headerName: "AUDIT PLAN",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "product_group_master_name",
      headerClassName: "super-app-theme--header",
      width: 140,
      headerName: "PRODUK GRUP",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "period",
      headerClassName: "super-app-theme--header",
      width: 140,
      headerName: "PERIOD",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "prev_period",
      headerClassName: "super-app-theme--header",
      width: 140,
      headerName: "PREV PERIOD",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "project_start_at",
      headerClassName: "super-app-theme--header",
      width: 140,
      headerName: "PROJECT START",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "project_end_at",
      headerClassName: "super-app-theme--header",
      width: 140,
      headerName: "PROJECT END",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "project_status",
      headerClassName: "super-app-theme--header",
      width: 140,
      headerName: "STATUS",
      align: "centeryy",
      headerAlign: "center",
      editable: false,
      filterable: false,
      renderCell: (params) => {
        let statusText = "";

        switch (params.value) {
          case "1":
            statusText = (
              <div
                style={{ background: "green", color: "white", padding: "10px" }}
              >
                Active
              </div>
            );
            break;
          case "3":
            statusText = (
              <div
                style={{
                  background: "red",
                  color: "white",
                  padding: "10px",
                }}
              >
                Inactive
              </div>
            );
            break;
          default:
            statusText = params.value;
            break;
        }

        return statusText;
      },
    },
    {
      field: "actions",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      width: 140,
      headerName: "ACTIONS",
      width: 214,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip
              title="Edit Project"
              onClick={() => handleEditClick(params)}
            >
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Edit Project Status"
              onClick={() => handleOpenStatusModal(params.row)}
            >
              <IconButton>
                <AutorenewIcon />
              </IconButton>
            </Tooltip>

            {['ADHOC', 'RDC', 'RDC_MT', 'RDC_PHARMACY'].includes(params.row.project_type_name) && (
              <Tooltip
                title="Set Custom Question Sku"
                component={RouterLink}
                to={`custom-question-sku/${params.row.id}`}
                onClick={() => handleOpenProjectCustom(params.row)}
              >
                <IconButton>
                  <SettingsSuggestOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            {/* {["ADHOC", "RDC", "RDC_MT", "RDC_PHARMACY"].includes(
              params.row.project_type_name
            ) && (
              <Tooltip
                title="Set Custom Question Store"
                component={RouterLink}
                to={`/project-management/custom-question-store/${params.row.id}`}
                onClick={() => handleOpenProjectStore(params.row)}
              >
                <IconButton>
                  <StoreIcon />
                </IconButton>
              </Tooltip>
            )} */}
          </div>
        );
      },
    },
  ];

  return (
    <DataGrid
      sx={{
        mt: 2,
        ml: 2,
        mr: 2,
        "& .super-app-theme--header": {
          backgroundColor: "rgb(6,157,174)",
          color: "#FFF",
          border: 2,
          fontWeight: "bold",
          borderColor: "rgb(234,243,248)",
        },
        border: 2,
        borderColor: "rgb(234,243,248)",
        fontSize: 15,
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "period", sort: "asc" }],
        },
      }}
      loading={isLoading}
      getRowId={(row) => row.id}
      rows={rows}
      columns={columns}
      editMode="row"
      getRowHeight={() => "auto"}
      // rowModesModel={rowModesModel}
      // slotProps={{
      //   toolbar: { rows, setRowModesModel },
      // }}
    />
  );
}

export default DataGridUserManagement;
