import { React, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import { fetchStoreMasterByStoreId } from './DataUtil';

function AddDataModal({
  accessToken,
  isLoading, newAuditPlanData,
  isModalOpen, setIsModalOpen,
  modalStyle,
  dataAuditPlanMaster, dataUserSurveyorOptions, dataUserQcOptions,
  handleInputChange, handleSubmit
}) {
  const [dataStoreMasterSpecific, setDataStoreMasterSpecific] = useState({});
  const [isModalOpenDetailStore, setIsModalOpenDetailStore] = useState(false);
  // management look specific store master
  const handleModalStoreMasterSpecific = async () => {
    const data = await fetchStoreMasterByStoreId(accessToken, newAuditPlanData.store_id)
    if (data) {
      setDataStoreMasterSpecific(data)
      setIsModalOpenDetailStore(true);
    } else {
      alert('Kode Outlet tidak ditemukan atau tidak aktif')
    }
  };
  const handleCloseModalStoreMasterSpecific = () => {
    setDataStoreMasterSpecific({})
    setIsModalOpenDetailStore(false)
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={modalStyle}
          style={{
            maxHeight: "600px",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <h2>Add Audit Plan</h2>
          <FormControl sx={{ mb: 2, width: "100%" }} variant='filled'>
            <InputLabel htmlFor="filled-adornment-store-id">
              Kode Outlet
            </InputLabel>
            <FilledInput
              name="store_id"
              autoComplete="off"
              id="filled-adornment-store-id"
              type={"text"}
              value={newAuditPlanData.store_id}
              onChange={handleInputChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle store id visibility"
                    onClick={handleModalStoreMasterSpecific}
                    edge="end"
                  >
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <TextField
            name="audit_plan"
            label="Audit Plan"
            select
            sx={{ width: "100%", mb: 2 }}
            value={newAuditPlanData.audit_plan}
            onChange={handleInputChange}
            variant='filled'
          >
            {/* be careful this set as label not id */}
            {dataAuditPlanMaster?.map((data) => (
              <MenuItem key={data.label} value={data.label}>
                {data.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="user_name_surveyor"
            label="User Full Name Surveyor"
            select
            sx={{ width: "100%", mb: 2 }}
            value={newAuditPlanData.user_name_surveyor}
            onChange={handleInputChange}
            variant='filled'
          >
            {dataUserSurveyorOptions?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="user_name_qc"
            label="User Full Name QC"
            select
            sx={{ width: "100%", mb: 2 }}
            value={newAuditPlanData.user_name_qc}
            onChange={handleInputChange}
            variant='filled'
          >
            <MenuItem key={'-'} value={''}>
              -
            </MenuItem>
            {dataUserQcOptions?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="audit_start_at"
            type="date"
            label="Audit Start Date"
            sx={{ width: "100%", mb: 2 }}
            InputLabelProps={{ shrink: true }}
            value={newAuditPlanData.audit_start_at}
            onChange={handleInputChange}
            variant='filled'
          />
          <TextField
            name="audit_end_at"
            type="date"
            label="Audit End Date"
            sx={{ width: "100%" }}
            InputLabelProps={{ shrink: true }}
            value={newAuditPlanData.audit_end_at}
            onChange={handleInputChange}
            variant='filled'
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              disableRipple
              sx={{
                width: 182,
                mt: 2,
                mr: 2,
                height: 50,
                color: "#b34469",
                borderColor: "#b34469",
              }}
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <BootstrapButton
              variant="contained"
              disableRipple
              sx={{ width: 182, mt: 2, height: 50 }}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "SUBMIT"}
            </BootstrapButton>
          </div>
        </Box>
      </Modal>
      <Modal
        open={isModalOpenDetailStore}
        onClose={handleCloseModalStoreMasterSpecific}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={modalStyle}
          style={{
            maxHeight: "400px",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <h2>Store Master Detail</h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div><b>Kode Outlet</b> : {dataStoreMasterSpecific.store_id}</div>
              <div><b>Nama Outlet</b> : {dataStoreMasterSpecific.store_name}</div>
              <div><b>Tipe Outlet</b> : {dataStoreMasterSpecific.store_type}</div>
              <div><b>Provinsi</b> : {dataStoreMasterSpecific.province_name}</div>
              <div><b>Kab/Kota</b> : {dataStoreMasterSpecific.regency_name}</div>
              <div><b>Kel</b> : {dataStoreMasterSpecific.sub_district_name}</div>
              <div><b>Kec</b> : {dataStoreMasterSpecific.district_name}</div>
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  width: 182,
                  mt: 2,
                  mr: 2,
                  height: 50,
                  color: "#b34469",
                  borderColor: "#b34469",
                }}
                onClick={handleCloseModalStoreMasterSpecific}
              >
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default AddDataModal
