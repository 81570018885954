// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectedMenuItem {
    position: relative;
    padding-left: 24px;
  }
  
  .selectedMenuItem::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #069DAE;
  }
  .reporting-textfield {
      width: 400px;
  }
  .content-text {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .container-textfield {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
  }
  @media only screen and (max-width:450px) {
    .reporting-textfield {
      width: 300px;
  }
}
  @media only screen and (max-width:310px) {
    .reporting-textfield {
      width: 220px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/style/reporting.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,OAAO;IACP,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;EAC3B;EACA;MACI,YAAY;EAChB;EACA;IACE,aAAa;IACb,qCAAqC;EACvC;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;EAClB;EACA;IACE;MACE,YAAY;EAChB;AACF;EACE;IACE;MACE,YAAY;EAChB;AACF","sourcesContent":[".selectedMenuItem {\n    position: relative;\n    padding-left: 24px;\n  }\n  \n  .selectedMenuItem::before {\n    content: \"\";\n    position: absolute;\n    top: 50%;\n    left: 0;\n    transform: translateY(-50%);\n    width: 12px;\n    height: 12px;\n    border-radius: 50%;\n    background-color: #069DAE;\n  }\n  .reporting-textfield {\n      width: 400px;\n  }\n  .content-text {\n    display: grid;\n    grid-template-columns: repeat(1, 1fr);\n  }\n  .container-textfield {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    margin-top: 40px;\n  }\n  @media only screen and (max-width:450px) {\n    .reporting-textfield {\n      width: 300px;\n  }\n}\n  @media only screen and (max-width:310px) {\n    .reporting-textfield {\n      width: 220px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
