// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
    .button-add {
        height: 40px;
        width: 150px;
    }
 }

  @media only screen and (max-width:430px)
 {
    .button-add {
        height: 40px;
        width: 100px;
    }
 }
  @media only screen and (max-width:300px)
 {
    .button-add {
        height: 40px;
        width: 80px;
    }
 }
`, "",{"version":3,"sources":["webpack://./src/style/projectmanagement.css"],"names":[],"mappings":"AAAA;IACI;QACI,YAAY;QACZ,YAAY;IAChB;CACH;;EAEC;;IAEE;QACI,YAAY;QACZ,YAAY;IAChB;CACH;EACC;;IAEE;QACI,YAAY;QACZ,WAAW;IACf;CACH","sourcesContent":["@media only screen and (max-width: 600px) {\n    .button-add {\n        height: 40px;\n        width: 150px;\n    }\n }\n\n  @media only screen and (max-width:430px)\n {\n    .button-add {\n        height: 40px;\n        width: 100px;\n    }\n }\n  @media only screen and (max-width:300px)\n {\n    .button-add {\n        height: 40px;\n        width: 80px;\n    }\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
