import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import {
    Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function DataGridListQuestion({ isLoading, rows, handleclickdata,handleEditbyswitchTop, handleEditbyswitchDown, projectTypeId }) {

    const columns = [
        {
            field: "title",
            headerClassName: "super-app-theme--header",
            flex: 1,
            headerName: "TITLE",
            align: "center",
            headerAlign: "center",
            editable: false,
        },
        {
            field: "question",
            headerClassName: "super-app-theme--header",
            flex: 2,
            headerName: "QUESTION",
            align: "left",
            headerAlign: "center",
            editable: false,
        },
        {
            field: "actions",
            align: "center",
            headerAlign: "center",
            headerClassName: "super-app-theme--header",
            headerName: "ACTIONS",
            flex: 1,
            renderCell: (params) => {
                const currentIndex = rows.findIndex(row => row.id === params.row.id);
                const rowDown = rows[currentIndex + 1];
                const rowTop = rows[currentIndex - 1];
                if(projectTypeId == 'adhoc'){
                  return (
                      <div>
                        <Tooltip
                              title="Edit Posisi Top"
                          onClick={() => {
                              if (rowTop) {
                                  handleEditbyswitchTop(params.row.id, rowTop.id);
                              } else {
                                  console.log(`tidak ada baris berikutnya`);
                              }
                          }}
                          >
                              <IconButton>
                                  <ArrowUpwardIcon />
                              </IconButton>
                          </Tooltip>
                          <Tooltip
                              title="Edit Posisi Down"
                              onClick={() => {
                                  if (rowDown) {
                                      handleEditbyswitchDown(params.row.id, rowDown.id);
                                  } else {
                                      console.log(`tidak ada baris berikutnya`);
                                  }
                              }}
                          >
                              <IconButton>
                                  <ArrowDownwardIcon />
                              </IconButton>
                          </Tooltip>
                      </div>
                  );
                }
            },
        },
    ];

    return (
        <DataGrid
            sx={{
                ml: 2,
                mr: 2,
                "& .super-app-theme--header": {
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    border: 2,
                    fontWeight: "bold",
                    borderColor: "rgb(234,243,248)",
                },
                border: 2,
                borderColor: "rgb(234,243,248)",
                fontSize: 15,
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
            }}
            loading={isLoading}
            getRowId={(row) => row.id}
            rows={rows}
            columns={columns}
            editMode="row"
            getRowHeight={() => 'auto'}
            // ... other props
            onRowClick={(params, event) => {
                // Check if the click was on an element with the 'actions' field
                if (event.target.closest('.MuiDataGrid-cell[data-field="actions"]')) {
                    // If it was, do nothing
                    return;
                }
                // Otherwise, handle the click for other rows
                handleclickdata(params.row);
            }}
        />
    )
}

export default DataGridListQuestion
