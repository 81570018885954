export async function fetchDataUserManagement(token, setIsLoading, setFilteredRows, selectedRoleCode = 'all', selectedStatus = 'all') {
  setFilteredRows([])
  setIsLoading(true);

  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  // Add selectedRoleCode to the query if it's provided
  if (selectedRoleCode && selectedRoleCode !== 'all') {
    queryParams.append('role_code', selectedRoleCode);
  }
  
  // Add selectedStatus to the query if it's provided
  if (selectedStatus && selectedStatus !== 'all') {
    queryParams.append('user_status', selectedStatus);
  }

  // Construct the full URL with query parameters
  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/users_with_roles` + (queryString ? `?${queryString}` : '');

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setFilteredRows(data.data); // Initialize filteredRows with the retrieved data
    } else {
      setFilteredRows([])
      // Handle error
    }
  } catch (error) {
    // Handle error
  }
  
  setIsLoading(false);
}

export async function fetchExportDataUserManagement(token, setIsLoading, selectedRoleCode = 'all', selectedStatus = 'all') {
  setIsLoading(true);

  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  // Add selectedRoleCode to the query if it's provided
  if (selectedRoleCode && selectedRoleCode !== 'all') {
    queryParams.append('role_code', selectedRoleCode);
  }

  // Add selectedStatus to the query if it's provided
  if (selectedStatus && selectedStatus !== '1') {
    queryParams.append('user_status', selectedStatus);
  }

  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/export` + (queryString ? `?${queryString}` : '');
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Result User Management.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else if (response.status === 404) {
      alert('data not found')
    }else{
      alert('something went wrong went export data')
    }
  } catch (error) {
    // Handle error
  }
  
  setIsLoading(false);
}

export const fetchExportTemplateUserManagement = async (accessToken, title, url, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/${url}`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;
    
    const response = await fetch(fullUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      return blob;
    } else {
      console.error(`Failed to export ${title} store:`, response.statusText);
      return;
    }
  } catch (error) {
    console.error(`Error exporting ${title} store:`, error);
  }
};