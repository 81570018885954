import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import {
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AutorenewIcon from "@mui/icons-material/Autorenew";

function DataGridUserManagement({isLoading, rows, handleEditClick, handleOpenStatusModal}) {
  
  const columns = [
    {
      field: "user_name",
      headerClassName: "super-app-theme--header",
      headerName: "USER NAME",
      width: 160,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "full_name",
      headerClassName: "super-app-theme--header",
      headerName: "FULL NAME",
      width: 150,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "role_name",
      headerClassName: "super-app-theme--header",
      headerName: "ROLE NAME",
      width: 150,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "STATUS",
      width: 150,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => {
        let statusText = "";

        switch (params.value) {
          case "0":
            statusText = (
              <div
                style={{
                  background: "red",
                  color: "white",
                  padding: "10px",
                }}
              >
                Inactive
              </div>
            );
            break;
          case "1":
            statusText = (
              <div
                style={{ background: "green", color: "white", padding: "10px" }}
              >
                Active
              </div>
            );
            break;
          default:
            statusText = params.value;
            break;
        }

        return statusText;
      },
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit User" onClick={() => handleEditClick(params)}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {
            params.row.role_code === '13'  ||  params.row.role_code === '14' ?
            <Tooltip
              title="Edit User Status"
              onClick={() => handleOpenStatusModal(params.row)}
            >
              <IconButton>
                <AutorenewIcon />
              </IconButton>
            </Tooltip>
            : null
          }
        </div>
      ),
    },
  ];

  return (
    <DataGrid
      sx={{
        mt: 2,
        ml: 2,
        mr: 2,
        "& .super-app-theme--header": {
          backgroundColor: "rgb(6,157,174)",
          color: "#FFF",
          border: 2,
          fontWeight: "bold",
          borderColor: "rgb(234,243,248)",
        },
        border: 2,
        borderColor: "rgb(234,243,248)",
        fontSize: 15,
      }}
      getRowHeight={() => 'auto'}
      loading={isLoading}
      getRowId={(row) => row.id}
      rows={rows}
      columns={columns}
      editMode="row"
      // rowModesModel={rowModesModel}
      // slotProps={{
      //   toolbar: { rows, setRowModesModel },
      // }}
    />
  )
}

export default DataGridUserManagement
