// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiButton-contained  {
    font-size: 18px !important;
    background-color: red;
  }

`, "",{"version":3,"sources":["webpack://./src/style/navbar.css"],"names":[],"mappings":"AAEA;IACI,0BAA0B;IAC1B,qBAAqB;EACvB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');\n\n.MuiButton-contained  {\n    font-size: 18px !important;\n    background-color: red;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
