// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width:430px) {

    .button-setup {
        margin-top: 10px;
        margin-left: -10px;
    }
}

@media only screen and (max-width:486px) {

    .button-setup {
        margin-top: 10px;
        margin-left: -10px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/style/setup.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,gBAAgB;QAChB,kBAAkB;IACtB;AACJ;;AAEA;;IAEI;QACI,gBAAgB;QAChB,kBAAkB;IACtB;AACJ","sourcesContent":["@media only screen and (max-width:430px) {\n\n    .button-setup {\n        margin-top: 10px;\n        margin-left: -10px;\n    }\n}\n\n@media only screen and (max-width:486px) {\n\n    .button-setup {\n        margin-top: 10px;\n        margin-left: -10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
