import Cookies from "js-cookie";
import { createSlice } from "@reduxjs/toolkit"

const generalSlice = createSlice({
  name: "General",
  initialState: {
    accessToken: Cookies.get("access_token") || "",
  },
  reducers: {
  }
})

export const { } = generalSlice.actions;
export default generalSlice.reducer;
