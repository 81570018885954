import { createSlice } from "@reduxjs/toolkit"

const projectManagementSlice = createSlice({
  name: "projectManagement",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
    selectedProjectStatus: '',
    selectedPeriod: '',
    projectManagementfilterColumnName: [],
    filterColumnName: '',
    filterColumnOperator: 'LIKE',
    filterColumnValue: '',
  },
  reducers: {
    // addToprojectManagement: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setProjectManagements: (state, action) => {
      state.data = action.payload;
    },
    setProjectManagement: (state, action) => {
      state.detail = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setSelectedProjectStatus: (state, action) => {
      state.selectedProjectStatus = action.payload;
    },
    setSelectedPeriod: (state, action) => {
      state.selectedPeriod = action.payload;
    },
    setProjectManagementColumn: (state, action) => {
      state.projectManagementfilterColumnName = action.payload;
    },
    setFilterColumnName: (state, action) => {
      state.filterColumnName = action.payload;
    },
    setFilterColumnOperator: (state, action) => {
      state.filterColumnOperator = action.payload;
    },
    setFilterColumnValue: (state, action) => {
      state.filterColumnValue = action.payload;
    },
  }
})

export const {
  setProjectManagements,
  setCurrentPage,
  setItemsPerPage,
  setProjectManagement,
  setTotal,
  setSelectedProjectStatus,
  setSelectedPeriod,
  setProjectManagementColumn,
  setFilterColumnName,
  setFilterColumnOperator,
  setFilterColumnValue,
} = projectManagementSlice.actions;
export default projectManagementSlice.reducer;
