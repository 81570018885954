import { createSlice } from "@reduxjs/toolkit"

const completenessStoreSlice = createSlice({
  name: "completenessStore",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
    selectedStoreId: '',
    selectedStoreName: '',
    selectedNotes: '',
  },
  reducers: {
    setCompletenessStores: (state, action) => {
      state.data = action.payload;
    },
    setCompletenessStore: (state, action) => {
      state.detail = action.payload;
    },
    setCurrentPageStore: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPageStore: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setTotalStore: (state, action) => {
      state.total = action.payload;
    },
    setSelectedStoreId: (state, action) => {
      state.selectedStoreId = action.payload;
    },
    setSelectedStoreName: (state, action) => {
      state.selectedStoreName = action.payload;
    },
    setSelectedNotes: (state, action) => {
      state.selectedNotes = action.payload;
    },
  }
})

export const {
  deleteCompletenessStore,
  setCompletenessStores,
  setCurrentPageStore,
  setItemsPerPageStore,
  setCompletenessStore,
  setTotalStore,
  setSelectedStoreId,
  setSelectedStoreName,
  setSelectedNotes,
} = completenessStoreSlice.actions;
export default completenessStoreSlice.reducer;
