import React, { useState } from 'react'
import "../style/login.css";
import logo from "../assets/images/bg/logo.png";
import Cookies from 'js-cookie';

function Login() {
  const [user_name, setUser_name] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bodyForm = {
      'user_name': user_name,
      'password': password
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/login`,
        {
          method: "POST",
          body: JSON.stringify(bodyForm),
          headers: { 'Content-Type': 'application/json' }
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        const access_token = data.data.access_token || ''
        Cookies.set('access_token', access_token)
        setMessage('login successful')
        window.location.replace("dashboard")
      } else if(response.status === 400 || response.status === 422 || response.status === 404 || response.status === 403) {
        const data = await response.json();
        setMessage(data.detail)
      }else{
        setMessage('something went wrong, try again later')
      }
    } catch (error) {
      console.error(error)
      setMessage('something went wrong, try again later')
    }
};

return (
  <div className="container-out">
    <div className="login-container">
      <div className="kiri">
        <img className="login-logo" src={logo} alt="logo" />
      </div>
      <div className="kanan">
        <p className="title">
          Application For{" "}
          <span className="retail-color">
            Retail Audit and Observation Survey.
          </span>
        </p>

        {message? (
          <p>{message}</p>
        ): null}

        <div className="login-bar">
          <form onSubmit={handleSubmit} action="">
            <input
              type="text"
              value={user_name}
              onChange={(e) => setUser_name(e.target.value)}
              className="username"
              placeholder="username"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="password"
              placeholder="password"
            />
            <button type="submit" className="login-button">Sign In</button>
          </form>
        </div>
      </div>
    </div>
    <div className="copyright">Copyright 2023 PT Exorty Indonesia</div>
  </div>
)
}

export default Login
