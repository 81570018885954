import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Slide, toast } from "react-toastify";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";

const ModalUploadRetailer = ({ open, onClose, uploadRetailerId }) => {
  const CancelButton = styled(Button)({
    backgroundColor: "#DE3436",
    color: "white",
    "&:hover": {
      backgroundColor: "#DC1F21",
    },
  });

  const ConfirmButton = styled(Button)({
    backgroundColor: "#469F3F",
    color: "white",
    "&:hover": {
      backgroundColor: "#32932B",
    },
  });

  const accessToken = Cookies.get("access_token") || "";

  const [dataRetailer, setDataRetailer] = useState([]);
  const [selectedRetailer, setSelectedRetailer] = useState("");
  const [file, setFile] = useState(null);

  async function fetchRetailer() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing-raw/rta_mt/retailer`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        setDataRetailer(data);
      } else {
        setDataRetailer([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleDownloadTemplate = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing-raw/rta_mt/template/retailer/export`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Template_Data_Retailer_PERIOD.xlsx";
        link.click();
      } else {
        console.error("Failed to download the template.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file || !selectedRetailer) {
      alert("Please select a retailer and upload a file.");
      return;
    }

    const params = {};

    if (selectedRetailer) {
      params.retailer = selectedRetailer;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing-single/data_preparation/${uploadRetailerId}/process/rta_mt`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
          params: params,
        }
      );

      if (response.status === 200) {
        toast.success("Berhasil upload retailer data");
        handleClose();
      } else if (response.status === 400) {
        const errorMessage = await response.json();
        toast.error(
          `Failed to upload the file: ${
            errorMessage.detail || "Error details not available"
          }`
        );
      } else {
        console.error("Failed to upload the file.");
        toast.error("Failed to upload the file.");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while uploading the file.");
    }
  };

  const handleClose = () => {
    setSelectedRetailer("");
    setFile(null);
    onClose();
  };

  const handleRetailerChange = (event) => {
    setSelectedRetailer(event.target.value);
  };

  useEffect(() => {
    fetchRetailer();
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: "white",
          border: "0px solid #000",
          boxShadow: 24,
          padding: "16px 32px 24px",
          borderRadius: "5px",
        }}
      >
        <Typography sx={{ mt: 2, fontSize: "18px", fontWeight: "600", color: "#383838" }}>
          Upload Retailer Data
        </Typography>
        <TextField
          id="outlined-select-retailer"
          label="Retailer"
          select
          sx={{ height: 40, mt: 2 }}
          size="small"
          value={selectedRetailer}
          onChange={handleRetailerChange}
          className="reporting-textfield"
        >
          {dataRetailer?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.retailer}
            </MenuItem>
          ))}
        </TextField>

        <Box
          mt={4}
          p={2}
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={2}
          >
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              Pilih file excel untuk di upload:
            </Typography>

            {file && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {file.name}
              </Typography>
            )}

            <Button
              variant="outlined"
              component="label"
              sx={{
                borderColor: "#1976d2",
                color: "#1976d2",
                width: "100%",
                "&:hover": {
                  borderColor: "#115293",
                  backgroundColor: "rgba(25, 118, 210, 0.1)",
                },
              }}
            >
              Choose File
              <input
                type="file"
                accept=".xlsx"
                hidden
                onChange={handleFileChange}
              />
            </Button>

            <Button
              variant="outlined"
              component="label"
              sx={{
                borderColor: "#1976d2",
                color: "#1976d2",
                width: "100%",
                "&:hover": {
                  borderColor: "#115293",
                  backgroundColor: "rgba(25, 118, 210, 0.1)",
                },
              }}
            >
              Download Excel Template
            </Button>
          </Box>
        </Box>

        <Box mt={4} display="flex" justifyContent="flex-end">
          <CancelButton onClick={handleClose} style={{ marginRight: "8px" }}>
            Tidak
          </CancelButton>
          <ConfirmButton onClick={handleUpload}>Ya</ConfirmButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalUploadRetailer;
