import { createSlice } from "@reduxjs/toolkit"

const tipeSurveySlice = createSlice({
  name: "tipeSurvey",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
  },
  reducers: {
    // addToTipeSurvey: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setTipeSurveys: (state, action) => {
      state.data = action.payload;
    },
    setTipeSurvey: (state, action) => {
      state.detail = action.payload;
    },
    editTipeSurvey: (state, action) => {
      const editedTipeSurveyIndex = state.data.findIndex(TipeSurvey => TipeSurvey.id === action.payload.id);
      if (editedTipeSurveyIndex !== -1) {
        state.data[editedTipeSurveyIndex] = {
          ...state.data[editedTipeSurveyIndex], // Copy the existing properties of the store Type
          ...action.payload.updates, // Merge in the custom properties from the action payload
        };
      }
    },
    deleteTipeSurvey: (state, action) => {
      state.data = state.data.filter(TipeSurvey => TipeSurvey.id !== action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
  }
})

export const { deleteTipeSurvey, setTipeSurveys, setCurrentPage, setItemsPerPage, setTipeSurvey, editTipeSurvey, setTotal } = tipeSurveySlice.actions;
export default tipeSurveySlice.reducer;
