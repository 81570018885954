import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  TableConsistency,
  TableConsistencyStoreGT,
  TableConsistencyStoreMT,
} from "../Tables/TableDataProcessing/TableConsistency";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ConsistencyProductModal = ({
  open,
  onClose,
  onConsistencyStoreGT,
  isLoading,
  itemsPerPageList,
  handleExportProductCompare,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "right", mb: 3 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid item sx={{ float: "left", mb: 3 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1 }}
          >
            List Inconsistency Product Master
          </Typography>
        </Grid>
        <Grid item sx={{ float: "right", mb: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            href="/setup-product-master"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Setup Product Master"}
          </Button>
          <Button
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
            }}
            onClick={onConsistencyStoreGT}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Go to Store Inconsistency Check"}
          </Button>
        </Grid>
        <TableConsistency itemsPerPageList={itemsPerPageList} />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
            }}
            onClick={handleExportProductCompare}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Export Data"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const ConsistencyProductModalMT = ({
  open,
  onClose,
  onConsistencyStoreMT,
  isLoading,
  itemsPerPageList,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "right", mb: 3 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid item sx={{ float: "left", mb: 3 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1 }}
          >
            List Inconsistency Product Master MT
          </Typography>
        </Grid>
        <Grid item sx={{ float: "right", mb: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            href="/setup-store-master"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Setup Store Master"}
          </Button>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
            }}
            onClick={onConsistencyStoreMT}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Go To Store Inconsistency MT"}
          </Button>
        </Grid>
        <TableConsistency itemsPerPageList={itemsPerPageList} />
      </Box>
    </Modal>
  );
};

export const ConsistencyStoreGTModal = ({
  projectType,
  open,
  onClose,
  onConsistencyProduct,
  isLoading,
  itemsPerPageList,
  handleExportStoreCompare,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "right", mb: 3 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid item sx={{ float: "left", mb: 3 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1 }}
          >
            List Inconsistency Store Master
          </Typography>
        </Grid>
        <Grid item sx={{ float: "right", mb: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            href="/setup-store-master"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Setup Store Master"}
          </Button>
          <Button
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
            }}
            onClick={onConsistencyProduct}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Go to Product Inconsistency Check"}
          </Button>
        </Grid>
        <TableConsistencyStoreGT
          itemsPerPageList={itemsPerPageList}
          projectType={projectType}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
            }}
            onClick={handleExportStoreCompare}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Export Data"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const ConsistencyStoreMTModal = ({
  open,
  onClose,
  onConsistencyProduct,
  isLoading,
  itemsPerPageList,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "right", mb: 3 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid item sx={{ float: "left", mb: 3 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ flexGrow: 1 }}
          >
            List Inconsistency Store Master MT
          </Typography>
        </Grid>
        <Grid item sx={{ float: "right", mb: 3 }}>
          <Button
            className="button-pros"
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
              marginRight: "10px",
            }}
            href="/setup-product-master"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Setup Product Master"}
          </Button>
          <Button
            variant="contained"
            style={{
              color: "#FFF",
              height: "40px",
              backgroundColor: "#074173",
            }}
            onClick={onConsistencyProduct}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Go To Product Inconsistency MT"}
          </Button>
        </Grid>
        <TableConsistencyStoreMT itemsPerPageList={itemsPerPageList} />
      </Box>
    </Modal>
  );
};
