import { Slide, toast } from 'react-toastify';

export const fetchProcess = async (token, endpoint, formData = {}) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/${endpoint}`;
    
    const fullUrl = apiUrl;
    const response = await fetch(fullUrl, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 201 || response.status === 200 || response.status === 202) {
      toast.success('data akan diproses kedalam antrian', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else if (response.status === 400) {
      const message = await response.json();
      toast.error(message?.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else if (response.status === 404) {
      toast.error('data tidak ditemukan', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else {
      toast.error('ada masalah saat menyimpan data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
  } catch (error) {
    console.error('Error creating process queue:', error);
  }
};

export const fetchExport = async (token, endpoint, fileName) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/${endpoint}`;
    let queryString = '';

    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200 || response.status === 201) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } else if (response.status === 400 || response.status === 404) {
      const data = await response.json();
      toast.error(data.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
     } else if (response.status === 422) {
      toast.error('Penulisan tidak sesuai', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else {
      toast.error('Internal server error', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
      console.error('Failed to export process queue:', response.statusText);
    }

  } catch (error) {
    console.error(`Error fetching reporting ${endpoint}:`, error);
  }
};
