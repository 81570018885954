// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width:600px)
 {
    .form-raw-data .MuiFormControl-root{
        grid-template-columns: repeat(2, 1fr);
        width: 200px;
    }

    .content-text{
        margin-top: 10px;
        margin-bottom: -25px;
    }
 }

@media only screen and (max-width:500px)
 {
    .form-raw-data .MuiFormControl-root{
        grid-template-columns: repeat(2, 1fr);
        width: 150px;
    }

    .content-text{
        margin-top: 10px;
        margin-bottom: -25px;
    }
 }

@media only screen and (max-width:400px)
 {
    .form-raw-data .MuiFormControl-root{
        grid-template-columns: repeat(2, 1fr);
        width: 120px;
    }

    .content-text{
        margin-top: 10px;
        margin-bottom: -25px;
    }
 }
`, "",{"version":3,"sources":["webpack://./src/style/reportdbrawdata.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,qCAAqC;QACrC,YAAY;IAChB;;IAEA;QACI,gBAAgB;QAChB,oBAAoB;IACxB;CACH;;AAED;;IAEI;QACI,qCAAqC;QACrC,YAAY;IAChB;;IAEA;QACI,gBAAgB;QAChB,oBAAoB;IACxB;CACH;;AAED;;IAEI;QACI,qCAAqC;QACrC,YAAY;IAChB;;IAEA;QACI,gBAAgB;QAChB,oBAAoB;IACxB;CACH","sourcesContent":["@media only screen and (max-width:600px)\n {\n    .form-raw-data .MuiFormControl-root{\n        grid-template-columns: repeat(2, 1fr);\n        width: 200px;\n    }\n\n    .content-text{\n        margin-top: 10px;\n        margin-bottom: -25px;\n    }\n }\n\n@media only screen and (max-width:500px)\n {\n    .form-raw-data .MuiFormControl-root{\n        grid-template-columns: repeat(2, 1fr);\n        width: 150px;\n    }\n\n    .content-text{\n        margin-top: 10px;\n        margin-bottom: -25px;\n    }\n }\n\n@media only screen and (max-width:400px)\n {\n    .form-raw-data .MuiFormControl-root{\n        grid-template-columns: repeat(2, 1fr);\n        width: 120px;\n    }\n\n    .content-text{\n        margin-top: 10px;\n        margin-bottom: -25px;\n    }\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
