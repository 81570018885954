import React from 'react'
import { DataGrid } from '@mui/x-data-grid';

function DataGridDashboard({ isLoading, rows }) {

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "NO",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "AREA",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "total_plan",
      headerClassName: "super-app-theme--header",
            width: 120,
      headerName: "Total Plan",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "audit_not_started",
      headerClassName: "super-app-theme--header",
                 width: 120,
      headerName: "Not Started",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "audit_on_progress",
      headerClassName: "super-app-theme--header",
                 width: 120,
      headerName: "On Progress",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "audit_valid",
      headerClassName: "super-app-theme--header",
                 width: 120,
      headerName: "Valid",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "audit_closed",
      headerClassName: "super-app-theme--header",
                 width: 120,
      headerName: "Closed",
      align: "left",
      headerAlign: "center",
      editable: false,
    },
  ];

  return (
    <DataGrid
      sx={{
        mt: 2,
        ml: 2,
        mr: 2,
        "& .super-app-theme--header": {
          backgroundColor: "rgb(6,157,174)",
          color: "#FFF",
          border: 2,
          fontWeight: "bold",
          borderColor: "rgb(234,243,248)",
        },
        border: 2,
        borderColor: "rgb(234,243,248)",
        fontSize: 15,
      }}
      loading={isLoading}
      getRowId={(row) => row.id}
      rows={rows}
      columns={columns}
      editMode="row"
      getRowHeight={() => 'auto'}

    // rowModesModel={rowModesModel}
    // slotProps={{
    //   toolbar: { rows, setRowModesModel },
    // }}
    />
  )
}

export default DataGridDashboard
