import { setJenisOutlets, setTotal as setTotalJenisOutlet } from '../redux/slices/jenisOutletSlice';
import { setStoreTypes, setTotal as setTotalStoreType } from '../redux/slices/storeTyoeSlice';
import { setTipeSurveys, setTotal as setTotalTipeSurvey } from '../redux/slices/tipeSurveySlice';
import { setTipeJalans, setTotal as setTotalTipeJalan } from '../redux/slices/tipeJalanSlice';
import { setTipeLokasis, setTotal as setTotalTipeLokasi } from '../redux/slices/tipeLokasiSlice';

export const fetchStoreType = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/store-type`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      dispatch(setStoreTypes(data.data));
      dispatch(setTotalStoreType(data.pagination_info.total));
    } else {
      console.error(`Failed to fetch store types:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching store types:`, error);
  }
};

export const fetchJenisOutlet = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/jenis-outlet`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      dispatch(setJenisOutlets(data.data));
      dispatch(setTotalJenisOutlet(data.pagination_info.total));
    } else {
      console.error(`Failed to fetch jenis outlets:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching jenis outlets:`, error);
  }
};

export const fetchTipeSurvey = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/tipe-survey`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      dispatch(setTipeSurveys(data.data));
      dispatch(setTotalTipeSurvey(data.pagination_info.total));
    } else {
      console.error(`Failed to fetch tipe surveys:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching tipe surveys:`, error);
  }
};

export const fetchTipeJalan = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/tipe-jalan`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      dispatch(setTipeJalans(data.data));
      dispatch(setTotalTipeJalan(data.pagination_info.total));
    } else {
      console.error(`Failed to fetch tipe jalans:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching tipe jalans:`, error);
  }
};

export const fetchTipeLokasi = async (dispatch, accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== '')
    );

    const queryString = Object.keys(filteredParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filteredParams[key])}`)
      .join('&');

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/tipe-lokasi`;
    const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

    const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (response.ok) {
      const data = await response.json();
      dispatch(setTipeLokasis(data.data));
      dispatch(setTotalTipeLokasi(data.pagination_info.total));
    } else {
      console.error(`Failed to fetch tipe lokasis:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching tipe lokasis:`, error);
  }
};
