import React from "react";
import { Modal, Box, Button } from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";

function ImportDataModal({
  isLoading,
  modalAdd,
  setModalAdd,
  modalStyle,
  handleFileChange,
  handleDownloadTemplateFile,
  handleUpload,
}) {
  return (
    <Modal
      open={modalAdd}
      onClose={() => setModalAdd(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={modalStyle}>
        <h2>Import New Audit Plan</h2>
        {/* Content in modal */}
        <input type="file" onChange={handleFileChange} /> {/* File input */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              width: 120,
              mt: 4,
              textTransform: "none",
              color: "#069DAE",
              borderColor: "#069DAE",
            }}
            onClick={handleDownloadTemplateFile}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Template"}
          </Button>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              width: 120,
              mt: 4,
              textTransform: "none",
              color: "#b34469",
              borderColor: "#b34469",
            }}
            onClick={() => setModalAdd(false)}
          >
            Cancel
          </Button>
          <BootstrapButton
            variant="contained"
            disableRipple
            sx={{ width: 120, mt: 4 }}
            onClick={handleUpload} // Upload button
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "UPLOAD"}
          </BootstrapButton>
        </div>
      </Box>
    </Modal>
  );
}

export default ImportDataModal;
