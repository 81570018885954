import { React } from "react";
import {
  Modal,
  Box,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";

function ViewDataModal({
  isLoading,
  dataSurveyDetail,
  modalStyle,
  handleCloseViewer,
  modalView,
  dataSurveyDetailPhoto,
  selectedImage,
  handleImageClick,
}) {
  if (isLoading) {
    return '';
  }

  return (
    <Modal
      open={modalView}
      onClose={handleCloseViewer}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={{
        ...modalStyle,
        width: 1000,
        height: 600,
        margin: "0 auto",
        maxHeight: "1500px",
        overflow: "auto",
        overflowX: "hidden",
        display: "flex",
        justifyContent: "space-around",
      }}>
        <div>
          {/* Content in view modal of  viewer */}
          <h4>PRODUCT PHOTOS</h4>
          <div>
            {dataSurveyDetail ? 
              <div>
                <div>{dataSurveyDetail.product_id}</div>
                <div>{dataSurveyDetail.product_name}</div>
              </div>
            : null}
            {dataSurveyDetailPhoto.length > 0 ? (
              <ImageList sx={{ width: 800, height: 450 }} cols={3}>
                {dataSurveyDetailPhoto.map((item) => (
                  <ImageListItem key={item.id} onClick={() => handleImageClick(item)}>
                    <img src={`${item.file}`} alt={item.id} loading="lazy" />
                    {selectedImage === item && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          background: 'rgba(255, 255, 255, 0.8)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{
                          padding: '20px'
                        }}>
                          {
                            item.detail.survey_name && <p>Project : {item.detail.survey_name}</p>
                          }
                          {
                            item.detail.store_code && <p>Kode Outlet : {item.detail.store_code}</p>
                          }
                          {
                            item.detail.store_name && <p>Nama Outlet : {item.detail.store_name}</p>
                          }
                        </div>
                      </div>
                    )}
                  </ImageListItem>
                ))}
              </ImageList>
            ) : (
              'Tidak ada foto'
            )}

          </div>
          <BootstrapButton
            variant="contained"
            disableRipple
            sx={{ width: 400, mt: 2 }}
            onClick={handleCloseViewer}
          >
            Close
          </BootstrapButton>
        </div>
      </Box>
    </Modal>
  )
}

export default ViewDataModal
