import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { fetchStorePhoto } from '../../services/storeMasterService';
import { ImageList, ImageListItem, Paper, Card, CardContent, Typography } from '@mui/material';

function PublicPhotoStore() {
  const { storeId } = useParams();
  const [storePhotoDetail, setStorePhotoDetail] = useState([]);
  const [storeDetail, setStoreDetail] = useState({});

  const fetchPhotoStore = async () => {
    // consume store master specific
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/public-data/store/${storeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setStoreDetail(data.data);

        // set photo detail
        setStorePhotoDetail(
          fetchStorePhoto('', storeId)
        )

      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPhotoStore();
  }, [])

  return (
    <Card sx={{ minWidth: 275, padding: 5 }} component={Paper}>
      <h4>STORE PHOTOS</h4>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {storeDetail?.store_id}
        </Typography>
        <Typography variant="h5" component="div">
          {storeDetail?.store_name}
        </Typography>
        <Typography variant="body2">
          {`${storeDetail.province_name ?? ''} ${storeDetail.regency_name ?? ''} ${storeDetail.district_name ?? ''} ${storeDetail.sub_district_name ?? ''}`}
        </Typography>

        {storePhotoDetail.length > 0 ?
          <ImageList sx={{ width: 400, height: 250 }} cols={2}>
            {storePhotoDetail.map((item) => (
              <ImageListItem key={item}>
                <img
                  src={`${item.file}`}
                  alt={item.id}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
          : ''}
      </CardContent>
    </Card>
  );

}

export default PublicPhotoStore
