import { createSlice } from "@reduxjs/toolkit"

const jenisOutletSlice = createSlice({
  name: "jenisOutlet",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
  },
  reducers: {
    // addToJenisOutlet: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setJenisOutlets: (state, action) => {
      state.data = action.payload;
    },
    setJenisOutlet: (state, action) => {
      state.detail = action.payload;
    },
    editJenisOutlet: (state, action) => {
      const editedJenisOutletIndex = state.data.findIndex(JenisOutlet => JenisOutlet.id === action.payload.id);
      if (editedJenisOutletIndex !== -1) {
        state.data[editedJenisOutletIndex] = {
          ...state.data[editedJenisOutletIndex], // Copy the existing properties of the store Type
          ...action.payload.updates, // Merge in the custom properties from the action payload
        };
      }
    },
    deleteJenisOutlet: (state, action) => {
      state.data = state.data.filter(JenisOutlet => JenisOutlet.id !== action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
  }
})

export const { deleteJenisOutlet, setJenisOutlets, setCurrentPage, setItemsPerPage, setJenisOutlet, editJenisOutlet, setTotal } = jenisOutletSlice.actions;
export default jenisOutletSlice.reducer;
