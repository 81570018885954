import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import "./Pivot.css";
import { fetchProfile } from "../../../../utils/fetchProfile";
import Navbar from "../../../../Components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRawDataNew,
  fetchPeriod,
  fetchMarketBreakDown,
  fetchLevel,
  fetchFact,
  fetchTypeProjectNew,
} from "../../../../services/reportDBRawDataService";
import {
  setSelectedPeriod,
  setSelectedMarketBreakDown,
  setSelectedFact,
  setSelectedLevel,
  setSelectedTypeProjectData,
} from "../../../../redux/slices/reportDBRawDataSlice";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { BootstrapButton } from "../../../../Components/Button/BootstrapButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import "../../../../style/reportdb.css";
import { MenuItem } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ReportProjected() {
  const dispatch = useDispatch();
  const accessToken = Cookies.get("access_token") || "";
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const [dataReportProjectedRawData, setDataReportProjectedRawData] = useState(
    []
  );

  //tipe_project
  const [selectedTypeProject, setSelectedTypeProject] = useState("");
  const dataTypeProject = useSelector((state) => state.rawData.type_projects);

  //Period
  const [selectedPeriodCheckbox, setSelectedPeriodCheckbox] = useState([]);
  const periods = useSelector((state) => state.rawData.periods);

  //Market Breakdown
  const [selectedMarketBreakDownCheckbox, setSelectedMarketBreakDownCheckbox] =
    useState([]);
  const market_breakdowns = useSelector(
    (state) => state.rawData.market_breakdowns
  );

  //Level
  const [selectedLevelCheckbox, setSelectedLevelCheckbox] = useState([]);
  const levels = useSelector((state) => state.rawData.levels);

  //Fact
  const [selectedFactCheckbox, setSelectedFactCheckbox] = useState([]);
  const facts = useSelector((state) => state.rawData.facts);

  // fetch data for first time page
  useEffect(() => {
    fetchProfile(accessToken, setDataProfile, []);
    fetchTypeProjectNew(dispatch, accessToken, setSelectedTypeProject);
  }, []);

  useEffect(() => {
    // Fetch initial data when type project changes
    if (selectedTypeProject) {
      fetchFact(
        dispatch,
        accessToken,
        selectedTypeProject,
        setSelectedFactCheckbox
      );
      fetchPeriod(
        dispatch,
        accessToken,
        selectedTypeProject,
        setSelectedPeriodCheckbox
      );
      fetchLevel(
        dispatch,
        accessToken,
        selectedTypeProject,
        setSelectedLevelCheckbox
      );
      fetchMarketBreakDown(
        dispatch,
        accessToken,
        selectedTypeProject,
        setSelectedMarketBreakDownCheckbox
      );
    }
  }, [selectedTypeProject]);

  const handleTypeProject = (event) => {
    const value = event.target.value;
    // get selected type project
    setSelectedTypeProject(value);

    dispatch(setSelectedTypeProjectData(value));
    // get api for data fact, period, level, market breakdown based selected type project
    fetchFact(dispatch, accessToken, value);
    fetchPeriod(dispatch, accessToken, value);
    fetchLevel(dispatch, accessToken, value);
    fetchMarketBreakDown(dispatch, accessToken, value);
    // reset every change of type project for selected fact, period, level and market breakdown
    setSelectedFactCheckbox([]);
    setSelectedPeriodCheckbox([]);
    setSelectedLevelCheckbox([]);
    setSelectedMarketBreakDownCheckbox([]);
    // reset data of report db raw data
    setDataReportProjectedRawData([]);
  };

  useEffect(() => {
    setDataReadyShow(
      selectedFactCheckbox,
      selectedPeriodCheckbox,
      selectedLevelCheckbox,
      selectedMarketBreakDownCheckbox
    );
  }, [
    selectedFactCheckbox,
    selectedPeriodCheckbox,
    selectedLevelCheckbox,
    selectedMarketBreakDownCheckbox,
  ]);

  const setDataReadyShow = (
    selectedFactCheckbox,
    selectedPeriodCheckbox,
    selectedLevelCheckbox,
    selectedMarketBreakDownCheckbox
  ) => {
    // validate when all column is fill (fact, period, level and market breakdown)
    if (
      selectedFactCheckbox.length > 0 &&
      selectedPeriodCheckbox.length > 0 &&
      selectedLevelCheckbox.length > 0 &&
      selectedMarketBreakDownCheckbox.length > 0
    ) {
      // call api report db raw data
      fetchData(
        selectedTypeProject,
        selectedFactCheckbox,
        selectedPeriodCheckbox,
        selectedLevelCheckbox,
        selectedMarketBreakDownCheckbox
      );
    } else {
      // reset data of report db raw data
      setDataReportProjectedRawData([]);
    }
  };

  const handleFact = (event) => {
    // array
    const value = event.target.value;
    setSelectedFactCheckbox(value);
    // change from array to string
    const optionListString = value.toString();
    dispatch(setSelectedFact(optionListString));
    // trigger when fact changed
  };

  const handlePeriod = (event) => {
    const { value } = event.target;
    let updatedSelectedPeriodCheckbox = [];

    if (value.includes("selectAll")) {
      updatedSelectedPeriodCheckbox =
        selectedPeriodCheckbox.length === periods.length
          ? []
          : periods.map((period) => period.id);
    } else {
      updatedSelectedPeriodCheckbox = value.filter(
        (val) => val !== "selectAll"
      );
    }

    setSelectedPeriodCheckbox(updatedSelectedPeriodCheckbox);

    // Ubah dari array menjadi string
    const optionListString = updatedSelectedPeriodCheckbox.toString();
    dispatch(setSelectedPeriod(optionListString));
  };

  const handleLevel = (event) => {
    const { value } = event.target;
    let updatedSelectedLevelCheckbox = [];

    if (value.includes("selectAll")) {
      updatedSelectedLevelCheckbox =
        selectedLevelCheckbox.length === levels.length
          ? []
          : levels.map((level) => level.id);
    } else {
      updatedSelectedLevelCheckbox = value.filter((val) => val !== "selectAll");
    }

    setSelectedLevelCheckbox(updatedSelectedLevelCheckbox);

    const optionListString = updatedSelectedLevelCheckbox.toString();
    dispatch(setSelectedLevel(optionListString));
  };

  const handleMarketBreakDown = (event) => {
    const { value } = event.target;
    let updatedSelectedMarketBreakDownCheckbox = [];

    if (value.includes("selectAll")) {
      updatedSelectedMarketBreakDownCheckbox =
        selectedMarketBreakDownCheckbox.length === market_breakdowns.length
          ? []
          : market_breakdowns.map((market_breakdown) => market_breakdown.id);
    } else {
      updatedSelectedMarketBreakDownCheckbox = value.filter(
        (val) => val !== "selectAll"
      );
    }

    setSelectedMarketBreakDownCheckbox(updatedSelectedMarketBreakDownCheckbox);

    const optionListString = updatedSelectedMarketBreakDownCheckbox.toString();
    dispatch(setSelectedMarketBreakDown(optionListString));
  };

async function fetchData(
  selectedTypeProject,
  selectedFactCheckbox,
  selectedPeriodCheckbox,
  selectedLevelCheckbox,
  selectedMarketBreakDownCheckbox
) {
  setLoading(true);

  let page = 1;
  let allData = [];
  let shouldFetch = true;

  const numberFormat = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2
  });
  while (shouldFetch) {
    try {
      const data = await fetchRawDataNew(accessToken, {
        db_set: selectedTypeProject,
        period: selectedPeriodCheckbox,
        market_breakdown: selectedMarketBreakDownCheckbox,
        level: selectedLevelCheckbox,
        fact: selectedFactCheckbox,
        size: 100, 
        page: page, 
      });

      if (!data || data.length === 0) {
        shouldFetch = false;
        break;
      }

      const formattedData = data.map(item => ({
        ...item,
        FactValue: numberFormat.format(parseFloat(item.FactValue)),
      }));

      allData = [...allData, ...formattedData];

      page++;
    } catch (error) {
      console.error("Error fetching data:", error);
      shouldFetch = false;
    }
  }

  setDataReportProjectedRawData(allData);

  setPivotState((prevState) => ({
    ...prevState,
    pivotState: {
      ...prevState.pivotState,
      data: allData, 
    },
  }));

  setLoading(false);
}

  
  const [pivotState, setPivotState] = useState({
    // mode: 'demo',
    // filename: 'Pivot Table Report DB',
    pivotState: {
      data: [],
      rows: ["Group", "rpt_shortdesc"],
      cols: ["Market_Breakdown", "Period"],
      aggregatorName: "List Unique Values",
      vals: ["FactValue"],
      plotlyOptions: { width: 900, height: 500 },
      plotlyConfig: {},
      // tableOptions: {
      //   clickCallback: function (e, value, filters, pivotData) {
      //     var names = [];
      //     pivotData.forEachMatchingRecord(filters, function (
      //       record
      //     ) {
      //       names.push(record.Meal);
      //     });
      //     alert(names.join('\n'));
      //   },
      // },
    },
  });

  const exportToPDF = () => {
    setIsLoading(true);

    const inputElements = document.getElementsByClassName("pvtOutput");

    if (inputElements.length === 0) {
      console.error('Element with class "pvtOutput" not found');
      setIsLoading(false);
      return;
    }

    const input = inputElements[0];

    html2canvas(input, { scale: 1.5 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.7);
        const imgWidth = canvas.width * 0.75;
        const imgHeight = canvas.height * 0.75;
        const pdf = new jsPDF("p", "mm", "a4");

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const fileNameParts = [];
        if (selectedTypeProject) fileNameParts.push(` ${selectedTypeProject}`);
        if (selectedFactCheckbox)
          fileNameParts.push(` - Fact ${selectedFactCheckbox}`);
        if (selectedPeriodCheckbox)
          fileNameParts.push(` - Period ${selectedPeriodCheckbox}`);
        if (selectedLevelCheckbox)
          fileNameParts.push(` - Product Level ${selectedLevelCheckbox}`);
        if (selectedMarketBreakDownCheckbox)
          fileNameParts.push(
            ` - Market Breakdown ${selectedMarketBreakDownCheckbox}`
          );

        const summaryFilter = fileNameParts.map((part) =>
          part
            .replace(/_/g, " ")
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase())
        );

        const title = `Report Projected ${summaryFilter.join("")}`;

        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);

        const titleLines = pdf.splitTextToSize(title, pageWidth - 20);

        let yPosition = 6;
        titleLines.forEach((line) => {
          const textWidth = pdf.getTextWidth(line);
          const xPosition = (pageWidth - textWidth) / 2;
          pdf.text(line, xPosition, yPosition);
          yPosition += 6;
        });

        const imageTopPosition = yPosition + 10;
        const imageHeight = (imgHeight * pageWidth) / imgWidth;

        if (imageHeight <= pageHeight - imageTopPosition) {
          pdf.addImage(
            imgData,
            "JPEG",
            0,
            imageTopPosition,
            pageWidth,
            imageHeight
          );
        } else {
          let heightLeft = imageHeight;
          let position = imageTopPosition;

          while (heightLeft > 0) {
            pdf.addImage(imgData, "JPEG", 0, position, pageWidth, imageHeight);
            heightLeft -= pageHeight;
            if (heightLeft > 0) {
              pdf.addPage();
              position = 0;
            }
          }
        }

        pdf.save(`${title}.pdf`);
      })
      .catch((error) => {
        console.error("Error exporting to PDF:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const goToTabular = () => {
    window.location.replace("/projected-reported-tabular");
  };

  return (
    <div>
      <Navbar
        active="Projected Reported"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div style={{ padding: "2rem" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <ButtonGroup style={{ color: "white" }}>
              <BootstrapButton
                onClick={goToTabular}
                style={{
                  color: "white",
                  borderColor: "white",
                  height: "40px",
                  width: "fit-content",
                }}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Go to Tabular Report View"}
              </BootstrapButton>
              <BootstrapButton
                onClick={exportToPDF}
                style={{
                  color: "white",
                  borderColor: "white",
                  height: "40px",
                  width: "100px",
                }}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Export"}
              </BootstrapButton>
            </ButtonGroup>
          </div>
        </div>

        <div
          style={{
            display: "block",
          }}
        >
          <div
            className="container-textfield"
            style={{ marginBottom: "30px", marginTop: "-50px" }}
          >
            <div className="content-text" style={{ textAlign: "center" }}>
              <h4
                style={{
                  fontSize: 20,
                  fontFamily: ["Montserrat"].join(","),
                }}
              >
                Projected Report - Pivot
              </h4>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "10px",
          }}
        >
          <div className="form-raw-data">
            <FormControl sx={{ mb: 2, mt: 1, mr: 2, width: 250 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: "16px" }}
              >
                DB Set Name
              </InputLabel>
              <Select
                id="type-project-autocomplete"
                label="Tipe Project"
                select
                onChange={handleTypeProject}
                value={selectedTypeProject}
              >
                {dataTypeProject.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 2, mt: 1, mr: 2, width: 250 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: "16px" }}
              >
                Fact
              </InputLabel>
              <Select
                id="fact-autocomplete"
                select
                label="Fact"
                value={selectedFactCheckbox}
                onChange={(event) =>
                  handleFact(event, { id: event.target.value })
                }
              >
                {facts.map((fact) => (
                  <MenuItem key={fact.id} value={fact.id}>
                    {fact.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 2, mt: 1, mr: 2, width: 250 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: "16px" }}
              >
                Period
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                label="Period"
                id="demo-multiple-checkbox"
                multiple
                value={selectedPeriodCheckbox}
                onChange={handlePeriod}
                input={<OutlinedInput label="Period" />}
                renderValue={(selected) => selected.join(",")}
                MenuProps={MenuProps}
              >
                {/* Option for Select All */}
                <MenuItem key="selectAll" value="selectAll">
                  <Checkbox
                    checked={selectedPeriodCheckbox.length === periods.length}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {/* Other Period options */}
                {periods.map((period) => (
                  <MenuItem key={period.id} value={period.id}>
                    <Checkbox
                      checked={selectedPeriodCheckbox.indexOf(period.id) > -1}
                    />
                    <ListItemText primary={period.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 2, mt: 1, mr: 2, width: 250 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: "16px" }}
              >
                Level
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                label="Level"
                id="demo-multiple-checkbox"
                multiple
                value={selectedLevelCheckbox}
                onChange={handleLevel}
                // input={<OutlinedInput label="level" />}
                renderValue={(selected) => selected.join(",")}
                MenuProps={MenuProps}
              >
                <MenuItem key="selectAll" value="selectAll">
                  <Checkbox
                    checked={selectedLevelCheckbox.length === levels.length}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>

                {levels.map((level) => (
                  <MenuItem key={level.id} value={level.id}>
                    <Checkbox
                      checked={selectedLevelCheckbox.indexOf(level.id) > -1}
                    />
                    <ListItemText primary={level.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 2, mt: 1, mr: 2, width: 250 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: "16px" }}
              >
                Market Break Down
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                label="Market_Breakdown"
                id="demo-multiple-checkbox"
                multiple
                value={selectedMarketBreakDownCheckbox}
                onChange={handleMarketBreakDown}
                renderValue={(selected) => selected.join(",")}
                MenuProps={MenuProps}
              >
                <MenuItem key="selectAll" value="selectAll">
                  <Checkbox
                    checked={
                      selectedMarketBreakDownCheckbox.length ===
                      market_breakdowns.length
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>

                {market_breakdowns.map((market_breakdown) => (
                  <MenuItem
                    key={market_breakdown.id}
                    value={market_breakdown.id}
                  >
                    <Checkbox
                      checked={
                        selectedMarketBreakDownCheckbox.indexOf(
                          market_breakdown.id
                        ) > -1
                      }
                    />
                    <ListItemText primary={market_breakdown.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="tabelpivot">
          {loading && "Sedang memuat data"}
          {dataReportProjectedRawData.length > 0 && (
            <PivotTableUI
              {...pivotState.pivotState}
              onChange={(s) =>
                setPivotState((prevState) => ({ ...prevState, pivotState: s }))
              }
              unusedOrientationCutoff={Infinity}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportProjected;
