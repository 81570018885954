import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import { fetchProfile } from "../../utils/fetchProfile";
import Navbar from "../../Components/Navbar";
import TablePreparation from "./Table/TablePreparation";
import TablePeriod from "./Table/TablePeriod";
import TableLogDB from "./Table/TableLogDB";

import { Slide, toast } from "react-toastify";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";

import { BootstrapButton } from "../../Components/Button/BootstrapButton";

import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
} from "@mui/material";

import { ModalGenerateDB } from "../../Components/Fragments/Modals/ModalGenerateDB";

function DataProcessing() {
  const accessToken = Cookies.get("access_token") || "";

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const [dataDBSet, setDataDBSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [selectedProjectType, setSelectedProjectType] = useState("rta_gt");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedPreviousPeriod, setSelectedPreviousPeriod] = useState("");
  const [selectedDBSet, setSelectedDBSet] = useState("");
  const [selectedPeriods, setSelectedPeriods] = useState("");

  const [preparationData, setPreparationData] = useState([]);
  const [dataLogDB, setDataLogDB] = useState([]);
  const [dataPeriod, setDataPeriod] = useState([]);

  useEffect(() => {
    fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, [
      "COA",
      "TL",
      "13",
      "14",
    ]);
  }, []);

  const handleCheckboxChange = (event, projectType) => {
    let updatedSelectedOptions = selectedDBSet.split(",").filter(Boolean);
    if (updatedSelectedOptions.includes(projectType)) {
      updatedSelectedOptions = updatedSelectedOptions.filter(
        (item) => item !== projectType
      );
    } else {
      updatedSelectedOptions.push(projectType);
    }

    setSelectedDBSet(updatedSelectedOptions.join(","));
  };

  const handlePeriodsClick = (row) => {
    let updatedSelectedPeriods = selectedPeriods.split(",").filter(Boolean);

    if (updatedSelectedPeriods.includes(String(row.period))) {
      updatedSelectedPeriods = updatedSelectedPeriods.filter(
        (item) => item !== String(row.period)
      );
    } else {
      updatedSelectedPeriods.push(String(row.period));
    }

    setSelectedPeriods(updatedSelectedPeriods.join(","));
  };

  async function fetchPeriodOptions(selectedProjectType) {
    setIsLoading(true);

    setPeriodOptions([]);

    let projectType = selectedProjectType.toLowerCase();

    if (projectType) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/reporting/period?project_type_id=${projectType}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const { data } = await response.json();
          const formattedPeriods = data.map((period) => ({
            period: period.name.toString(),
            id: period.id.toString(),
          }));
          setPeriodOptions(formattedPeriods);
        } else {
          setPeriodOptions([]);
        }
      } catch (error) {
        console.error(error);
      }
    } else if (!projectType) {
      setPeriodOptions([]);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchPeriodOptions(selectedProjectType);
  }, [selectedProjectType]);

  const handleCreateDataPreparation = async () => {
    const formData = {
      project_type: selectedProjectType,
      period: selectedPeriod,
      prev_period: "0",
      need_transfer_data: "false",
    };

    if (formData) setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing-single/data_preparation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        toast.success("Berhasil generate DB!");
        handleRefreshDataPreparation();
        handleRefresh();
      } else {
        const message = await response.json();
        toast.error(message.detail);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  async function fetchPreparationData(selectedProjectType, selectedPeriod) {
    setIsLoading(true);
    let projectType = selectedProjectType.toLowerCase();

    if (projectType && selectedPeriod) {
      try {
        let url = `${process.env.REACT_APP_API_URL}/api/v1/data-processing/data_preparation?project_type=${projectType}&period=${selectedPeriod}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          const { data } = await response.json();
          setPreparationData(data);
        } else if (response.status === 404) {
          handleCheck();
        } else {
          setPreparationData([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setPreparationData([]);
      setIsLoading(false);
    }
  }

  async function fetchDBSet() {
    setIsLoading(true);
    if (selectedProjectType) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/data-processing/db-set`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const { data } = await response.json();
          setDataDBSet(data);
        } else {
          setDataDBSet([]);
        }
      } catch (error) {
        console.error(error);
      }
    } else if (!accessToken) {
      setDataDBSet([]);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchDBSet();
  }, []);

  async function fetchLogDB() {
    setIsLoading(true);
    if (selectedProjectType) {
      let queryString = "";

      if (selectedDBSet && selectedDBSet !== "") {
        queryString += `db_set_ids=${selectedDBSet}`;
      }

      if (selectedPeriods && selectedPeriods !== "") {
        queryString += (queryString ? "&" : "") + `periods=${selectedPeriods}`;
      }

      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing?project_type=${selectedProjectType}` +
        (queryString ? `&${queryString}` : "");

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          const { data } = await response.json();
          setDataLogDB(data);
        } else {
          setDataLogDB([]);
        }
      } catch (error) {
        console.error(error);
      }
    } else if (!accessToken) {
      setDataLogDB([]);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchLogDB(selectedProjectType, selectedDBSet, selectedPeriods);
  }, [selectedProjectType, selectedDBSet, selectedPeriods]);

  async function fetchPeriod() {
    setIsLoading(true);
    if (selectedProjectType) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/data-processing/period?project_type=${selectedProjectType}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const { data } = await response.json();
          setDataPeriod(data);
        } else {
          setDataPeriod([]);
        }
      } catch (error) {
        console.error(error);
      }
    } else if (!accessToken) {
      setDataPeriod([]);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchPeriod(selectedProjectType);
  }, [selectedProjectType]);

  const handleRefresh = () => {
    if (selectedProjectType && selectedPeriod) {
      fetchPreparationData(selectedProjectType, selectedPeriod);
    } else {
      setPreparationData([]);
    }
  };

  const handleRefreshDataPreparation = () => {
    fetchLogDB(selectedProjectType, selectedDBSet, selectedPeriods);
    fetchPeriod(selectedProjectType);
  };

  useEffect(() => {
    fetchPreparationData(selectedProjectType, selectedPeriod);
  }, [selectedProjectType, selectedPeriod]);

  const handleGenerate = async () => {
    const formData = {
      project_type: selectedProjectType,
      db_set_ids: selectedDBSet,
      periods: selectedPeriods,
    };

    if (formData) setIsLoading(true);
    {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/data-processing`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          toast.success("Berhasil generate DB!");
          handleCloseModal();
          handleRefreshDataPreparation();
        } else {
          const message = await response.json();
          toast.error(message.detail);
        }
      } catch (error) {
        console.error("Error:", error);
      }
      setIsLoading(false);
    }
  };

  const previousPeriodOptions = periodOptions.filter(
    (option) => option.period < selectedPeriod
  );

  const handleProjectTypeChange = (event) => {
    setSelectedProjectType(event.target.value);
    setSelectedPeriod("");
    setSelectedPreviousPeriod("");
    setSelectedDBSet("");
    setSelectedPeriods("");
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
    setSelectedPreviousPeriod("");
  };

  const handlePreviousPeriodChange = (event) => {
    setSelectedPreviousPeriod(event.target.value);
  };

  const handleCheck = async () => {
    // validation
    if (!selectedProjectType || !selectedPeriod) {
      toast.error(
        "pilih project type dan period untuk membuat log preparation"
      );
      setPreparationData([]);
    } else {
      handleCreateDataPreparation();
    }
  };

  const filteredDBSet = selectedProjectType
    ? dataDBSet.filter((item) => item.project_type === selectedProjectType)
    : dataDBSet;

  const [modalGenerate, setModalGenerate] = useState(false);

  const handleModalGenerate = () => {
    setModalGenerate(true);
  };

  const handleCloseModal = () => {
    setModalGenerate(false);
  };

  const goToCrosscoding = () => {
    window.open("/dev/data-processing/crosscode-brand", "_blank");
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Data Processing"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allowed to access this page</div>
      </div>
    );
  }
  return (
    <div>
      <Navbar
        active="Data Processing"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div className="content" style={{ padding: "2rem" }}>
        <TextField
          id="outlined-select-currency"
          label="Tipe Project"
          select
          sx={{
            height: 40,
          }}
          size="small"
          value={selectedProjectType}
          onChange={handleProjectTypeChange}
          className="reporting-textfield"
        >
          <MenuItem key="rta_gt" value="rta_gt">
            RTA GT
          </MenuItem>
          {/* <MenuItem key="rta_mt" value="rta_mt">
            RTA MT
          </MenuItem> */}
          <MenuItem key="rdc_gt" value="rdc_gt">
            RDC GT
          </MenuItem>
          <MenuItem key="rdc_mt" value="rdc_mt">
            RDC MT
          </MenuItem>
          <MenuItem key="rdc_pharmacy" value="rdc_pharmacy">
            RDC PHARMACY
          </MenuItem>
        </TextField>

        {selectedProjectType === "rta_mt" && (
          <BootstrapButton
            onClick={goToCrosscoding}
            style={{
              color: "white",
              borderColor: "white",
              height: "40px",
              width: "fit-content",
              marginLeft: "10px",
            }}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Crosscoding"}
          </BootstrapButton>
        )}

        {/* Data Preparation */}
        <p style={{ fontWeight: "600", fontSize: "18px", marginTop: "20px" }}>
          Data Preparation
        </p>
        <Card
          sx={{
            mt: 1,
            padding: 3,
            position: "relative",
            border: "1px solid #ccc",
            borderRadius: "8px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item sx={{ float: "left", ml: "90px" }}>
              <TextField
                id="outlined-select-currency"
                label="Current Period"
                select
                sx={{ height: 40 }}
                size="small"
                value={selectedPeriod}
                onChange={handlePeriodChange}
                className="reporting-textfield"
              >
                {periodOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.period}
                  </MenuItem>
                ))}
              </TextField>
              {selectedProjectType === "rta_gt" && (
                <TextField
                  id="outlined-select-currency"
                  label="Previous Period"
                  select
                  sx={{ height: 40, ml: 2 }}
                  size="small"
                  value={selectedPreviousPeriod}
                  onChange={handlePreviousPeriodChange}
                  className="reporting-textfield"
                >
                  {previousPeriodOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.period}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>

            <Grid item sx={{ float: "right" }}>
              <Button
                className="button-pros"
                variant="contained"
                style={{
                  color: "#FFF",
                  height: "40px",
                  backgroundColor: "#069dae",
                }}
                onClick={handleRefresh}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Refresh Status..."}
              </Button>
            </Grid>
          </Grid>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <p style={{ marginRight: "50px", marginTop: 0 }}>Steps</p>
            <div style={{ flex: 1 }}>
              <TablePreparation
                preparationData={preparationData}
                handleRefresh={handleRefresh}
                isLoading={isLoading}
                projectType={selectedProjectType}
                currentPeriod={selectedPeriod}
                previousPeriod={selectedPreviousPeriod}
              />
            </div>
          </div>
        </Card>

        {/* Data Processing */}
        <p style={{ fontWeight: "600", fontSize: "18px", marginTop: "30px" }}>
          Data Processing
        </p>
        <Card
          sx={{
            mt: 1,
            padding: 3,
            position: "relative",
            border: "1px solid #ccc",
            borderRadius: "8px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item sx={{ float: "left", ml: "0px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ margin: "0px 35px 4px 0px", color: "#000" }}>
                  DB SET
                </p>
                <FormControl
                  sx={{
                    width: "42.5vh",
                    mb: 2,
                    pl: "10px",
                    height: "100px",
                    overflowY: "auto",
                    border: "solid 1px #a9a9a9",
                    borderRadius: "3px",
                  }}
                >
                  {filteredDBSet.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      style={{
                        height: "30px",
                        paddingLeft: "10px",
                        backgroundColor: selectedDBSet.includes(item.id)
                          ? "#e0e0e0"
                          : "inherit",
                      }}
                      control={
                        <Checkbox
                          sx={{ display: "none" }}
                          onChange={(event) =>
                            handleCheckboxChange(event, item.id)
                          }
                          checked={selectedDBSet.includes(item.id)}
                          value={item.id}
                        />
                      }
                      label={item.base_name}
                    />
                  ))}
                </FormControl>
              </div>
            </Grid>

            <Grid item sx={{ float: "right" }}>
              <Button
                variant="contained"
                style={{
                  color: "#FFF",
                  height: "40px",
                  backgroundColor: "#069dae",
                  marginRight: "10px",
                }}
                onClick={handleModalGenerate}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Generate DB ..."}
              </Button>
              <Button
                className="button-pros"
                variant="contained"
                style={{
                  color: "#FFF",
                  height: "40px",
                  backgroundColor: "#069dae",
                }}
                onClick={handleRefreshDataPreparation}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Refresh Status ..."}
              </Button>
            </Grid>
          </Grid>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "flex-start",
                width: "30%",
              }}
            >
              <p style={{ marginRight: "50px", marginTop: 0 }}>Period</p>
              <div style={{ flex: 1 }}>
                <TablePeriod
                  handlePeriodsClick={handlePeriodsClick}
                  isLoading={isLoading}
                  dataPeriod={dataPeriod}
                  selectedPeriods={selectedPeriods}
                />
              </div>
            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "flex-start",
                width: "68%",
              }}
            >
              <p style={{ marginRight: "50px", marginTop: 0 }}>
                Generate DB Log
              </p>
              <div style={{ flex: 1 }}>
                <TableLogDB isLoading={isLoading} dataLogDB={dataLogDB} />
              </div>
            </div>
          </div>
        </Card>

        <ModalGenerateDB
          modalGenerate={modalGenerate}
          handleCloseModal={handleCloseModal}
          handleGenerate={handleGenerate}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default DataProcessing;
