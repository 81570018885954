import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const alternatingRowStyles = {
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#EAFAFC",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#F7FBFC",
  },
};

export const RawDataModal = ({
  open,
  onClose,
  currentPeriod,
  accessToken,
  remarkDataValidationRtaGT,
}) => {
  const [dataValidation, setDataValidation] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editRowData, setEditRowData] = useState({});

  const fetchDataValidation = async (accessToken, currentPeriod) => {
    if (currentPeriod) {
      const queryParams = new URLSearchParams();

      if (currentPeriod && currentPeriod !== "") {
        queryParams.append("period", currentPeriod);
      }

      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing-raw/rta_gt` +
        (queryString ? `?${queryString}` : "");

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        let result_data = response?.data?.data;
        setDataValidation(result_data);
      } catch (e) {
        setDataValidation([]);
      }
    }
  };

  const handleExport = async () => {
    try {
      const queryParams = new URLSearchParams();
      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing-raw/rta_gt/export?period=${currentPeriod}` +
        (queryString ? `&${queryString}` : "");

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `Result of Raw Data RTA GT ${currentPeriod}.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success(`Result of Raw Data RTA GT ${currentPeriod} berhasil di export!`);
      } else {
        const message = await response.json();
        toast.error(
          message.detail || "An error occurred while exporting data."
        );
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while exporting data.");
    }
  };

  useEffect(() => {
    if (open) {
      fetchDataValidation(accessToken, currentPeriod);
    }
  }, [open, currentPeriod]);

  useEffect(() => {
    const total =
      Number(editRowData.stok_gudang || 0) +
      Number(editRowData.stok_display || 0);
    setEditRowData((prevData) => ({ ...prevData, stok_total: total }));
  }, [editRowData.stok_gudang, editRowData.stok_display]);

  useEffect(() => {
    const salesUnit =
      Number(editRowData.stok_awal || 0) +
      Number(editRowData.pembelian || 0) -
      Number(editRowData.stok_total || 0);
    setEditRowData((prevData) => ({ ...prevData, sales_unit: salesUnit }));
  }, [editRowData.stok_awal, editRowData.pembelian, editRowData.stok_total]);

  const handleEditClick = (row) => {
    setEditRowData(row);
    setEditModalOpen(true);
  };

  const handleEditSave = async () => {
    try {
      const { id, stok_gudang, stok_display, pembelian } = editRowData;
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-processing-raw/rta_gt/${id}`;
      const queryParams = new URLSearchParams({
        stok_gudang,
        stok_display,
        pembelian,
      }).toString();

      const response = await fetch(`${apiUrl}?${queryParams}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const message = await response.json();
      if (response.status === 200) {
        toast.success("Data berhasil diubah!");
        setEditModalOpen(false);
        fetchDataValidation(accessToken, currentPeriod);
      } else if (response.status === 422) {
        toast.error(message.detail);
      } else if (response.status === 404) {
        toast.error(message.detail);
      } else {
        toast.error(message.detail);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const parseNumber = (formattedNumber) => {
    return formattedNumber.replace(/\./g, "");
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header wrap-header",
      cellClassName: "wrap-cell",
      align: "left",
      headerAlign: "center",
      field: "product_name",
      headerName: "NAMA PRODUK",
      flex: 2,
    },
    {
      headerClassName: "super-app-theme--header wrap-header",
      cellClassName: "wrap-cell",
      align: "left",
      headerAlign: "center",
      field: "store_name",
      headerName: "NAMA TOKO",
      flex: 2,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          <div>{params.row.store_name}</div>
          <div>{params.row.regency?.name}</div>
        </div>
      ),
    },
    {
      headerClassName: "super-app-theme--header wrap-header",
      cellClassName: "wrap-cell",
      align: "left",
      headerAlign: "center",
      field: "stok",
      headerName: "STOK",
      flex: 2,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          <div>- Stok Awal : {formatNumber(params.row.stok_awal)}</div>
          <div>- Stok Gudang : {formatNumber(params.row.stok_gudang)}</div>
          <div>- Stok Display : {formatNumber(params.row.stok_display)}</div>
          <div>- Stok Total : {formatNumber(params.row.stok_total)}</div>
        </div>
      ),
    },
    {
      headerClassName: "super-app-theme--header wrap-header",
      cellClassName: "wrap-cell",
      align: "left",
      headerAlign: "center",
      field: "harga_final",
      headerName: "HARGA FINAL",
      flex: 1,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      headerClassName: "super-app-theme--header wrap-header",
      cellClassName: "wrap-cell",
      align: "left",
      headerAlign: "center",
      field: "pembelian",
      headerName: "PEMBELIAN",
      flex: 1,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      headerClassName: "super-app-theme--header wrap-header",
      cellClassName: "wrap-cell",
      align: "left",
      headerAlign: "center",
      field: "sales_unit",
      headerName: "SALES UNIT",
      flex: 1,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      headerClassName: "super-app-theme--header wrap-header",
      cellClassName: "wrap-cell",
      align: "left",
      headerAlign: "center",
      field: "salesvalue",
      headerName: "SALES VALUE",
      flex: 1,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      headerClassName: "super-app-theme--header wrap-header",
      cellClassName: "wrap-cell",
      align: "left",
      headerAlign: "center",
      field: "prev_salesvalue",
      headerName: "PREV SALES VALUE",
      flex: 1,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      headerClassName: "super-app-theme--header wrap-header",
      cellClassName: "wrap-cell",
      field: "actions",
      headerName: "ACTION",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <ToastContainer />
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box sx={{ ...style, maxHeight: "80vh", overflow: "auto" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ flexGrow: 1 }}
            >
              {remarkDataValidationRtaGT}
            </Typography>
            <div>
              <Button
                className="button-pros"
                variant="contained"
                style={{
                  color: "#FFF",
                  height: "40px",
                  backgroundColor: "#069dae",
                }}
                onClick={handleExport}
              >
                Export Data
              </Button>
            </div>
          </Box>
          {dataValidation.length > 0 && (
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: "period", sort: "asc" }],
                },
              }}
              sx={{
                mt: 2,
                "& .super-app-theme--header": {
                  backgroundColor: "#81D7D8",
                  color: "#000000",
                  border: 2,
                  fontWeight: "bold",
                  borderColor: "#FFF",
                },
                "& .wrap-header .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  textAlign: "center",
                  lineHeight: "1.2",
                },
                "& .wrap-cell .MuiDataGrid-cellContent": {
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  textAlign: "left",
                  lineHeight: "1.2",
                },
                ...alternatingRowStyles,
              }}
              className="alternating-row-grid"
              getRowId={(row) => row.id}
              rows={dataValidation}
              columns={columns}
              editMode="row"
              getRowHeight={() => 100}
            />
          )}
        </Box>
      </Modal>

      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
        sx={{ border: 0 }}
      >
        <Box sx={{ ...style, maxHeight: "85vh", overflow: "auto" }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <Typography
              id="edit-modal-title"
              variant="h6"
              component="h2"
              sx={{ flexGrow: 1 }}
            >
              Edit Data
            </Typography>
            <IconButton onClick={() => setEditModalOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Kode Produk : {editRowData.product_id}
              <br />
              Nama Produk : {editRowData.product_name}
              <br />
              Kode Toko : {editRowData.store_id}
              <br />
              Nama Toko : {editRowData.store_name}
            </Typography>
            <TextField
              label="Stok Awal"
              type="text"
              value={
                editRowData.stok_awal !== undefined &&
                editRowData.stok_awal !== null
                  ? formatNumber(editRowData.stok_awal)
                  : "0"
              }
              disabled
            />
            <TextField
              label="Stok Gudang"
              type="text"
              value={
                editRowData.stok_gudang !== undefined &&
                editRowData.stok_gudang !== null
                  ? formatNumber(editRowData.stok_gudang)
                  : "0"
              }
              onChange={(e) =>
                setEditRowData({
                  ...editRowData,
                  stok_gudang: parseNumber(e.target.value),
                })
              }
            />
            <TextField
              label="Stok Display"
              type="text"
              value={
                editRowData.stok_display !== undefined &&
                editRowData.stok_display !== null
                  ? formatNumber(editRowData.stok_display)
                  : "0"
              }
              onChange={(e) =>
                setEditRowData({
                  ...editRowData,
                  stok_display: parseNumber(e.target.value),
                })
              }
            />
            <TextField
              label="Stok Total"
              type="text"
              value={
                editRowData.stok_total !== undefined &&
                editRowData.stok_total !== null
                  ? formatNumber(editRowData.stok_total)
                  : "0"
              }
              disabled
            />
            <TextField
              label="Harga Jual"
              type="text"
              value={
                editRowData.harga_jual !== undefined &&
                editRowData.harga_jual !== null
                  ? formatNumber(editRowData.harga_jual)
                  : "0"
              }
              disabled
            />
            <TextField
              label="Pembelian"
              type="text"
              value={
                editRowData.pembelian !== undefined &&
                editRowData.pembelian !== null
                  ? formatNumber(editRowData.pembelian)
                  : "0"
              }
              onChange={(e) =>
                setEditRowData({
                  ...editRowData,
                  pembelian: parseNumber(e.target.value),
                })
              }
            />
            <TextField
              label="Sales Unit"
              type="text"
              value={
                editRowData.sales_unit !== undefined &&
                editRowData.sales_unit !== null
                  ? formatNumber(editRowData.sales_unit)
                  : "0"
              }
              disabled
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditSave}
            >
              UPDATE
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
