import {
  setRawData,
  setTotal,
  setPeriod,
  setMarketBreakDown,
  setLevel,
  setFact,
  setTypeProjects,
} from "../redux/slices/reportDBRawDataSlice";

export const fetchRawData = async (
  dispatch,
  accessToken,
  customParams = {}
) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== "")
    );

    Object.keys(filteredParams).forEach(key => {
      if (Array.isArray(filteredParams[key])) {
        filteredParams[key] = filteredParams[key].join(",");
      }
    });

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/report_db`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filteredParams),
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(setRawData(data.data));
      dispatch(setTotal(data.pagination_info.total));
    } else {
      dispatch(setRawData([]));
      console.error(`Failed to fetch store masters:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching store masters:`, error);
  }
};


export const fetchPeriod = async (
  dispatch,
  accessToken,
  typeProject,
  setSelectedPeriodCheckBox
) => {
  try {
    const url = typeProject
      ? `${
          process.env.REACT_APP_API_URL
        }/api/v1/report_db/period?db_set=${encodeURIComponent(typeProject)}`
      : `${process.env.REACT_APP_API_URL}/api/v1/report_db/period`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      if (Array.isArray(data.data)) {
        const formattedPeriod = data.data.map((item) => ({
          label: item.toString(),
          id: item.toString(),
        }));
        dispatch(setPeriod(formattedPeriod));
        if (formattedPeriod.length > 0) {
          setSelectedPeriodCheckBox([formattedPeriod[0].id]);
        }
      } else {
        dispatch(setPeriod([]));
        console.error("Invalid data format for periods:", data);
      }
    } else {
      dispatch(setPeriod([]));
      console.error(`Failed to fetch periods:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching periods:`, error);
  }
};

export const fetchMarketBreakDown = async (
  dispatch,
  accessToken,
  typeProject,
  setSelectedMarketBreakDownCheckBox
) => {
  try {
    const url = typeProject
      ? `${
          process.env.REACT_APP_API_URL
        }/api/v1/report_db/market_breakdown?db_set=${encodeURIComponent(
          typeProject
        )}`
      : `${process.env.REACT_APP_API_URL}/api/v1/report_db/market_breakdown`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      if (Array.isArray(data.data)) {
        const formattedMarketBreakDown = data.data.map((item) => ({
          label: item.toString(),
          id: item.toString(),
        }));
        dispatch(setMarketBreakDown(formattedMarketBreakDown));
        if (formattedMarketBreakDown.length > 0) {
          setSelectedMarketBreakDownCheckBox([formattedMarketBreakDown[0].id]);
        }
      } else {
        dispatch(setMarketBreakDown([]));
        console.error("Invalid data format for market break downs:", data);
      }
    } else {
      dispatch(setMarketBreakDown([]));
      console.error(`Failed to fetch market break downs:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching market break downs:`, error);
  }
};

export const fetchLevel = async (
  dispatch,
  accessToken,
  typeProject,
  setSelectedLevelCheckBox
) => {
  try {
    const url = typeProject
      ? `${
          process.env.REACT_APP_API_URL
        }/api/v1/report_db/level?db_set=${encodeURIComponent(typeProject)}`
      : `${process.env.REACT_APP_API_URL}/api/v1/report_db/level`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      if (Array.isArray(data.data)) {
        const formattedLevel = data.data.map((item) => ({
          label: item.toString(),
          id: item.toString(),
        }));
        dispatch(setLevel(formattedLevel));
        if (formattedLevel.some(item => item.id === "4_Product")) {
          setSelectedLevelCheckBox(["4_Product"]);
        } else if (formattedLevel.length > 0) {
          setSelectedLevelCheckBox([formattedLevel[0].id]);
        }
        
        
      } else {
        dispatch(setLevel([]));
        console.error("Invalid data format for levels:", data);
      }
    } else {
      dispatch(setLevel([]));
      console.error(`Failed to fetch levels:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching levels:`, error);
  }
};

export const fetchFact = async (
  dispatch,
  accessToken,
  typeProject,
  setSelectedFactCheckBox
) => {
  try {
    const url = typeProject
      ? `${
          process.env.REACT_APP_API_URL
        }/api/v1/report_db/fact?db_set=${encodeURIComponent(typeProject)}`
      : `${process.env.REACT_APP_API_URL}/api/v1/report_db/fact`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      if (Array.isArray(data.data)) {
        const formattedFact = data.data.map((item) => ({
          label: item.toString(),
          id: item.toString(),
        }));
        dispatch(setFact(formattedFact));
        if (formattedFact.length > 0) {
          setSelectedFactCheckBox(formattedFact[0].id);
        }
      } else {
        dispatch(setFact([]));
        console.error("Invalid data format for facts:", data);
      }
    } else {
      dispatch(setFact([]));
      console.error(`Failed to fetch facts:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching facts:`, error);
  }
};

export const fetchTypeProjectNew = async (
  dispatch,
  accessToken,
  setSelectedTypeProject
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/report_db/db_set`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();

      if (Array.isArray(data.data)) {
        const formattedTypeProject = data.data.map((item) => ({
          label: item.toString(), // Mengonversi angka menjadi string
          id: item.toString(),
        }));
        dispatch(setTypeProjects(formattedTypeProject));

        if (formattedTypeProject.length > 0) {
          setSelectedTypeProject(formattedTypeProject[0].id);
        }
      } else {
        console.error("Invalid data format for type project :", data);
      }
    } else {
      console.error(`Failed to fetch type project :`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching type project :`, error);
  }
};

export const fetchRawDataNew = async (accessToken, customParams = {}) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(customParams).filter(([key, value]) => value !== "")
    );

    Object.keys(filteredParams).forEach((key) => {
      if (Array.isArray(filteredParams[key])) {
        filteredParams[key] = filteredParams[key].join(",");
      }
    });

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/report_db`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filteredParams),
    });

    if (response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      console.error(`Failed to fetch report db raw data:`, response.statusText);
      return [];
    }
  } catch (error) {
    console.error(`Error fetching report db raw data:`, error);
  }
};

