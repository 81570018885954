import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setItemsPerPage } from '../../../redux/slices/reportDBRawDataSlice';
import { fetchRawData } from '../../../services/reportDBRawDataService';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';

export const TableDBRawData = ({
  itemsPerPageList,
  selectedTypeProject,
  selectedFact,
  selectedPeriod,
  selectedLevel,
  selectedMarketBreakDown
}) => {
  // Redux selectors and dispatch
  const dispatch = useDispatch();
  const items = useSelector((state) => state.rawData.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector((state) => state.rawData.currentPage);
  const itemsPerPage = useSelector((state) => state.rawData.itemsPerPage);
  const total = useSelector((state) => state.rawData.total);

  const columns = [
    { id: 1, label: 'Period' },
    { id: 2, label: 'Market_Breakdown' },
    { id: 3, label: 'rptID' },
    { id: 4, label: 'rpt_shortdesc' },
    { id: 5, label: 'rpt_longdesc' },
    { id: 6, label: 'Level' },
    { id: 7, label: 'Fact' },
    { id: 8, label: 'FactValue' },
    { id: 9, label: 'Group' },
    { id: 10, label: 'Principal' },
    { id: 11, label: 'Brand' },
    { id: 12, label: 'PackSize' },
    { id: 13, label: 'PackType' },
    { id: 14, label: 'Flavour' },
    { id: 15, label: 'Product' },
  ]

  const fetchData = (currentPage, itemsPerPage) => {
    try {
      dispatch(setCurrentPage(currentPage));
      const customParams = {
        size: itemsPerPage,
        page: currentPage,
        db_set: selectedTypeProject,
        fact: selectedFact,
        period: selectedPeriod,
        level: selectedLevel,
        market_breakdown: selectedMarketBreakDown,
      };
      fetchRawData(dispatch, accessToken, customParams);
    } catch (error) {
      console.error(`Error fetching data:`, error);
    }
  };

  const handlePageChange = (event, page) => {
    fetchData(page + 1, itemsPerPage);
  };
  
  const handleChangeItemPerPageSelect = (e) => {
    dispatch(setItemsPerPage(e.target.value));
    fetchData(1, e.target.value);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 500 }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {
                columns.map((column) => {
                  return <TableCell
                    key={column.id}
                    style={{
                      whiteSpace: 'nowrap',
                      minWidth: column.minWidth,
                      backgroundColor: "rgb(6,157,174)",
                      color: "#FFF",
                      fontWeight: "bold",
                      position: "sticky", // Membuat header menjadi sticky
                      top: 0, // Membuat header tetap di atas
                      zIndex: 1000, // Menetapkan zIndex agar tetap di depan
                    }}
                  >
                    {column.label}
                  </TableCell>
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((item) => (
                <TableRow key={item.id}>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {item[column.label]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* pagination */}
      {items.length > 0 &&
        <TablePagination
          rowsPerPageOptions={itemsPerPageList}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
        />
      }
    </Paper>
  );
};
