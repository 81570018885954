import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";

const ModalProcessPreparationStep = ({ open, onClose, onProcess, isLoading, selectedProcessData}) => {
  const CancelButton = styled(Button)({
    backgroundColor: "#DE3436",
    color: "white",
    "&:hover": {
      backgroundColor: "#DC1F21",
    },
  });

  const ConfirmButton = styled(Button)({
    backgroundColor: "#469F3F",
    color: "white",
    "&:hover": {
      backgroundColor: "#32932B",
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: "white",
          border: "0px solid #000",
          boxShadow: 24,
          padding: "16px 32px 24px",
          borderRadius: "5px",
        }}
      >
        {selectedProcessData ? (
          <>
            <Typography sx={{ mt: 2, fontSize: "18px", color: "#383838" }}>
              Apakah anda yakin untuk memproses <br />Step {selectedProcessData.position} - {selectedProcessData.description}?
            </Typography>
            <Box mt={4} display="flex" justifyContent="flex-end">
              <CancelButton
                onClick={onClose}
                style={{ marginRight: "8px" }}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Tidak"}
              </CancelButton>
              <ConfirmButton onClick={onProcess} disabled={isLoading}>
                {isLoading ? "Loading..." : "Ya"}
              </ConfirmButton>
            </Box>
          </>
        ) : (
          <Typography sx={{ mt: 2, fontSize: "18px", color: "#383838" }}>
            Data preparation step is not selected.
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ModalProcessPreparationStep;
