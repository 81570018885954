import { createSlice } from "@reduxjs/toolkit"

const storeMasterSlice = createSlice({
  name: "storeMaster",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
    storeMasterStatus: [
      {
        "id": "0",
        "label": "Inactive"
      },
      {
        "id": "1",
        "label": "Active"
      },
    ],
    storeMasterfilterColumnName: [],
    storeMasterfilterColumnOperator: [
      {
        'id': 'LIKE',
        'name': 'Contains'
      }
    ],
    selectedStatusId: '',
    selectedTipeSurvey: '',
    selectedProvinceId: '',
    selectedRegencyId: '',
    selectedDistrictId: '',
    selectedSubDistrictId: '',
    filterColumnName: '',
    filterColumnOperator: 'LIKE',
    filterColumnValue: '',
  },
  reducers: {
    // addToStoreMaster: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setStoreMasters: (state, action) => {
      state.data = action.payload;
    },
    setStoreMaster: (state, action) => {
      state.detail = action.payload;
    },
    editStoreMaster: (state, action) => {
      const editedStoreMasterIndex = state.data.findIndex(StoreMaster => StoreMaster.id === action.payload.id);
      if (editedStoreMasterIndex !== -1) {
        state.data[editedStoreMasterIndex] = {
          ...state.data[editedStoreMasterIndex], // Copy the existing properties of the store master
          ...action.payload.updates, // Merge in the custom properties from the action payload
        };
      }
    },
    deleteStoreMaster: (state, action) => {
      state.data = state.data.filter(StoreMaster => StoreMaster.id !== action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setSelectedTipeSurvey: (state, action) => {
      state.selectedTipeSurvey = action.payload;
    },
    setSelectedStatusId: (state, action) => {
      state.selectedStatusId = action.payload;
    },
    setSelectedProvinceId: (state, action) => {
      state.selectedProvinceId = action.payload;
    },
    setSelectedRegencyId: (state, action) => {
      state.selectedRegencyId = action.payload;
    },
    setSelectedDistrictId: (state, action) => {
      state.selectedDistrictId = action.payload;
    },
    setSelectedSubDistrictId: (state, action) => {
      state.selectedSubDistrictId = action.payload;
    },
    setFilterColumnName: (state, action) => {
      state.filterColumnName = action.payload;
    },
    setFilterColumnOperator: (state, action) => {
      state.filterColumnOperator = action.payload;
    },
    setFilterColumnValue: (state, action) => {
      state.filterColumnValue = action.payload;
    },
    setStoreMasterColumn: (state, action) => {
      state.storeMasterfilterColumnName = action.payload;
    },
  }
})

export const {
  deleteStoreMaster,
  setStoreMasters,
  setCurrentPage,
  setItemsPerPage,
  setStoreMaster,
  editStoreMaster,
  setTotal,
  setSelectedTipeSurvey,
  setSelectedStatusId,
  setSelectedProvinceId,
  setSelectedRegencyId,
  setSelectedDistrictId,
  setSelectedSubDistrictId,
  setFilterColumnName,
  setFilterColumnOperator,
  setFilterColumnValue,
  setStoreMasterColumn
} = storeMasterSlice.actions;
export default storeMasterSlice.reducer;
