import { createSlice } from "@reduxjs/toolkit"

const consistencyStoreSlice = createSlice({
  name: "consistencyStore",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
    selectedStoreId: '',
    selectedStoreName: '',
    selectedAtribute: '',
    selectedValueSivina: '',
    selectedValueErri: '',
    selectedLabelSivina: '',
    selectedLabelErri: '',
    selectedConsistenciesStore: {},
  },
  reducers: {
    setConsistencyStores: (state, action) => {
      state.data = action.payload;
    },
    setConsistencyStore: (state, action) => {
      state.detail = action.payload;
    },
    setCurrentPageStore: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPageStore: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setTotalStore: (state, action) => {
      state.total = action.payload;
    },
    setSelectedProductId: (state, action) => {
      state.selectedProductId = action.payload;
    },
    setSelectedProductName: (state, action) => {
      state.selectedProductName = action.payload;
    },
    setSelectedAtribute: (state, action) => {
      state.selectedAtribute = action.payload;
    },
    setSelectedValueSivina: (state, action) => {
      state.selectedValueSivina = action.payload;
    },
    setSelectedValueErri: (state, action) => {
      state.selectedValueErri = action.payload;
    },
    setSelectedLabelSivina: (state, action) => {
      state.selectedLabelSivina = action.payload;
    },
    setSelectedLabelErri: (state, action) => {
      state.selectedLabelErri = action.payload;
    },
    setSelectedConsistencyStore: (state, action) => {
      const { id, value } = action.payload;
      state.selectedConsistenciesStore[id] = value;
    },
  }
})

export const {
  deleteConsistencyStore,
  setConsistencyStores,
  setConsistencyStore,
  setCurrentPageStore,
  setItemsPerPageStore,
  setTotalStore,
  setSelectedStoreId,
  setSelectedStoreName,
  setSelectedAtribute,
  setSelectedValueSivina,
  setSelectedValueErri,
  setSelectedLabelSivina,
  setSelectedLabelErri,
  setSelectedConsistencyStore,
} = consistencyStoreSlice.actions;
export default consistencyStoreSlice.reducer;
