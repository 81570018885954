import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import {
    Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function DataGridSkuRetailDistribusi({ isLoading, rows, handleclickdata}) {

    const columns = [
        {
            field: "title",
            headerClassName: "super-app-theme--header",
            flex: 1,
            headerName: "TITLE",
            align: "center",
            headerAlign: "center",
            editable: false,
        },
        {
            field: "question",
            headerClassName: "super-app-theme--header",
            flex: 2,
            headerName: "QUESTION",
            align: "left",
            headerAlign: "center",
            editable: false,
        },
    ];

    return (
        <DataGrid
            sx={{
                ml: 2,
                mr: 2,
                "& .super-app-theme--header": {
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    border: 2,
                    fontWeight: "bold",
                    borderColor: "rgb(234,243,248)",
                },
                border: 2,
                borderColor: "rgb(234,243,248)",
                fontSize: 15,
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-row': {
                    height: 40,
                },
            }}
            loading={isLoading}
            getRowId={(row) => row.id}
            rows={rows}
            columns={columns}
            editMode="row"
            getRowHeight={() => 'auto'}
            // ... other props
            // onRowClick={(params, event) => {
            //     // Check if the click was on an element with the 'actions' field
            //     if (event.target.closest('.MuiDataGrid-cell[data-field="actions"]')) {
            //         // If it was, do nothing
            //         return;
            //     }
            //     // Otherwise, handle the click for other rows
            //     handleclickdata(params.row);
            // }}
        />
    )
}

export default DataGridSkuRetailDistribusi
