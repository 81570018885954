const reportRTAGT = "RTA GT"
const reportRDCGT = "RDC GT"
const reportRDCMT = "RDC MT"
const reportRDCPharmacy = "RDC PHARMACY"

export const dataReportGroup = [
  {
    title: reportRTAGT,
  },
  {
    title: reportRDCGT,
  },
  {
    title: reportRDCMT,
  },
  {
    title: reportRDCPharmacy,
  }
]

export const dataLevel = [
  {
    title: 'Kota',
  },
  {
    title: 'Kecamatan',
  },
  {
    title: 'Kelurahan',
  }
]

export const dataReportDetail = [
  {
    title: 'Detail Survey RDC',
    report_group: reportRDCGT,
    report_initial: 'Report 1',
    project_type: 'rdc',
    survey_types: [
      {
        'title': 'RDC Reguler',
      },
      {
        'title': 'RDC RTD',
      },
    ],
    input_period_first_disabled: false,
    input_period_second_disabled: false,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'Detail Survey RA',
    report_group: reportRTAGT,
    report_initial: 'Report 2',
    project_type: 'rak2',
    input_period_first_disabled: false,
    input_period_second_disabled: false,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'Out Of Range Report',
    report_group: reportRTAGT,
    report_initial: 'Report 3',
    project_type: 'rak2',
    input_period_first_disabled: false,
    input_period_second_disabled: true,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'Market Share Non Projected Report',
    report_group: reportRTAGT,
    report_initial: 'Report 4',
    project_type: 'rak2',
    input_period_first_disabled: false,
    input_period_second_disabled: false,
    input_period_third_disabled: false,
    input_survey_type_disabled: false,
    input_level_disabled: false,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'RDC GT Non Projected Report',
    report_group: reportRDCGT,
    report_initial: 'Report 5',
    project_type: 'rdc',
    input_period_first_disabled: false,
    input_period_second_disabled: false,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: false,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'RDC MT Non Projected Report by Storetype',
    report_group: reportRDCMT,
    report_initial: 'Report 6',
    project_type: 'mt',
    input_period_first_disabled: false,
    input_period_second_disabled: true,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: false,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'RDC Price Report by Area',
    report_group: reportRDCGT,
    report_initial: 'Report 7',
    project_type: 'rdc',
    input_period_first_disabled: false,
    input_period_second_disabled: true,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'RDC Selling Speed Non Projected Report',
    report_group: reportRDCGT,
    report_initial: 'Report 8',
    project_type: 'rdc',
    input_period_first_disabled: false,
    input_period_second_disabled: true,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'Report Ketepatan Penjualan',
    report_group: reportRDCGT,
    report_initial: 'Report 9',
    project_type: 'rdc',
    input_period_first_disabled: false,
    input_period_second_disabled: true,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'Report Undup Gabungan Produk',
    report_group: reportRDCGT,
    report_initial: 'Report 10',
    project_type: 'rdc',
    input_period_first_disabled: false,
    input_period_second_disabled: true,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'Distribusi Grosir Report',
    report_group: reportRTAGT,
    report_initial: 'Report 11',
    project_type: 'rdc',
    input_period_first_disabled: false,
    input_period_second_disabled: false,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: false,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'Display Report',
    report_group: reportRDCGT,
    report_initial: 'Report 12',
    project_type: 'rdc',
    input_period_first_disabled: false,
    input_period_second_disabled: false,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'Report RDC MT Price By Area',
    report_group: reportRDCMT,
    report_initial: 'Report 13',
    project_type: 'mt',
    input_period_first_disabled: false,
    input_period_second_disabled: true,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'RDC MT Non Projected Report',
    report_group: reportRDCMT,
    report_initial: 'Report 14',
    project_type: 'mt',
    input_period_first_disabled: false,
    input_period_second_disabled: false,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'Report RDC MT Price by Tipe Lokasi',
    report_group: reportRDCMT,
    report_initial: 'Report 15',
    project_type: 'mt',
    input_period_first_disabled: false,
    input_period_second_disabled: true,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'RDC Pharmacy Non Projected Report',
    report_group: reportRDCPharmacy,
    report_initial: 'Report 16',
    project_type: 'pharmacy',
    input_period_first_disabled: false,
    input_period_second_disabled: false,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: true,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
  {
    title: 'RDC Pharmacy Non Projected Report by Storetype',
    report_group: reportRDCPharmacy,
    report_initial: 'Report 17',
    project_type: 'pharmacy',
    input_period_first_disabled: false,
    input_period_second_disabled: true,
    input_period_third_disabled: true,
    input_survey_type_disabled: false,
    input_level_disabled: false,
    input_province_disabled: false,
    input_regency_disabled: false,
    input_district_disabled: false,
    input_sub_district_disabled: false,
    input_region_disabled: false,
    input_wilayah_disabled: false,
  },
]
