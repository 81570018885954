import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";

import { MenuItem, TextField } from "@mui/material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from "@mui/material";

import {
  setCurrentPage,
  setItemsPerPage,
  setSelectedConsistency,
} from "../../../../redux/slices/consistencyProductSlice";
import {
  setCurrentPageStore,
  setItemsPerPageStore,
  setSelectedConsistencyStore,
} from "../../../../redux/slices/consistencyStoreSlice";
import {
  fetchConsistencyProduct,
  fetchConsistencyStore,
} from "../../../../services/consistencyService";

const tableCellStyle = {
  whiteSpace: "normal",
  wordWrap: "break-word",
  wordBreak: "break-word",
  backgroundColor: "black",
  color: "#FFF",
  fontWeight: "bold",
  position: "sticky",
  top: 0,
  zIndex: 1000,
  minWidth: 120,
  textAlign: "center",
};

export const TableConsistency = ({ itemsPerPageList }) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.consistencyProduct.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector(
    (state) => state.consistencyProduct.currentPage
  );
  const itemsPerPage = useSelector(
    (state) => state.consistencyProduct.itemsPerPage
  );
  const total = useSelector((state) => state.consistencyProduct.total);

  const selectedProductId = useSelector(
    (state) => state.consistencyProduct.selectedProductId
  );
  const selectedProductName = useSelector(
    (state) => state.consistencyProduct.selectedProductName
  );
  const selectedAtribute = useSelector(
    (state) => state.consistencyProduct.selectedAtribute
  );
  const selectedValueSivina = useSelector(
    (state) => state.consistencyProduct.selectedValueSivina
  );
  const selectedValueErri = useSelector(
    (state) => state.consistencyProduct.selectedValueErri
  );
  const selectedLabelSivina = useSelector(
    (state) => state.consistencyProduct.selectedLabelSivina
  );
  const selectedLabelErri = useSelector(
    (state) => state.consistencyProduct.selectedLabelErri
  );
  const selectedConsistencies = useSelector(
    (state) => state.consistencyProduct.selectedConsistencies
  );

  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("product_id");
  const [sortOrder, setSortOrder] = useState("asc");

  function fetchData(currentPage, itemsPerPage, sortBy, sortOrder) {
    dispatch(setCurrentPage(currentPage));
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      product_id: selectedProductId,
      product_name: selectedProductName,
      atribute: selectedAtribute,
      value_sivina: selectedValueSivina,
      value_erri: selectedValueErri,
      label_sivina: selectedLabelSivina,
      label_erri: selectedLabelErri,
      sort_by: sortBy,
      sort_order: sortOrder,
    };
    fetchConsistencyProduct(dispatch, accessToken, customParams);
  }

  useEffect(() => {
    fetchData(currentPage || 1, itemsPerPage, sortBy, sortOrder);
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedProductId,
    selectedProductName,
    selectedAtribute,
    selectedValueSivina,
    selectedValueErri,
    selectedLabelSivina,
    selectedLabelErri,
    sortBy,
    sortOrder,
  ]);

  const handlePageChange = (event, page) => {
    fetchData(page + 1, itemsPerPage, sortBy, sortOrder);
  };

  const handleChangeItemPerPageSelect = (e) => {
    dispatch(setItemsPerPage(e.target.value));
    fetchData(1, e.target.value, sortBy, sortOrder);
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === "asc";
    setSortOrder(isSorting ? "desc" : "asc");
    setSortBy(column);
  };

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: "#FFF !important",
          "&:hover": {
            color: "#FFF",
          },
          "& .MuiTableSortLabel-icon": {
            color: "#FFF !important",
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  async function fetchEditConsistency(id, option, previousValue) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing/product-master/compare/${id}?option=${option}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ option }),
        }
      );

      if (response.status === 200) {
        toast.success("Data berhasil diubah", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        dispatch(setSelectedConsistency({ id, value: option }));
      } else if (response.status === 400) {
        const message = await response.json();
        console.error(message.detail);
        toast.error(`${message.detail}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        dispatch(setSelectedConsistency({ id, value: previousValue }));
      } else {
        throw new Error("Error updating consistency");
      }
    } catch (error) {
      console.error("Error fetching consistency:", error);
      toast.error("Ada masalah saat menyimpan data", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
      // Revert to previous value if the update fails
      dispatch(setSelectedConsistency({ id, value: previousValue }));
    }
  }

  const handleConsistencyChange = async (id, event) => {
    const option = event.target.value;
    const previousValue = selectedConsistencies[id] || "";
    dispatch(setSelectedConsistency({ id, value: option }));
    await fetchEditConsistency(id, option, previousValue);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer component={Paper} style={{ maxHeight: "450px" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {sortTableCell("Kode Produk", "product_id")}
              {sortTableCell("Nama Produk", "product_name")}
              {sortTableCell("Atribut", "atribute")}
              {sortTableCell("Sivina", "label_sivina")}
              {sortTableCell("ERRI", "label_erri")}
              <TableCell style={tableCellStyle}>Pilihan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{row.product_id}</TableCell>
                  <TableCell align="center">{row.product_name}</TableCell>
                  <TableCell align="center">{row.atribute}</TableCell>
                  <TableCell align="center">{row.label_sivina}</TableCell>
                  <TableCell align="center">{row.label_erri}</TableCell>
                  <TableCell align="center">
                    <TextField
                      id={`outlined-select-consistency-${row.id}`}
                      select
                      sx={{ height: 40, width: 150 }}
                      size="small"
                      value={selectedConsistencies[row.id] || ""}
                      onChange={(event) =>
                        handleConsistencyChange(row.id, event)
                      }
                      className="reporting-textfield"
                    >
                      <MenuItem value="sivina">{row.label_sivina}</MenuItem>
                      <MenuItem value="erri">{row.label_erri}</MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {isLoading ? "Loading..." : "No Data Available"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {items.length > 0 && (
        <TablePagination
          rowsPerPageOptions={itemsPerPageList}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
        />
      )}
    </Paper>
  );
};

export const TableConsistencyStoreGT = ({ itemsPerPageList, projectType }) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.consistencyStore.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector(
    (state) => state.consistencyStore.currentPage
  );
  const itemsPerPage = useSelector(
    (state) => state.consistencyStore.itemsPerPage
  );
  const total = useSelector((state) => state.consistencyStore.total);

  const selectedStoreId = useSelector(
    (state) => state.consistencyStore.selectedStoreId
  );
  const selectedStoreName = useSelector(
    (state) => state.consistencyStore.selectedStoreName
  );
  const selectedAtribute = useSelector(
    (state) => state.consistencyStore.selectedAtribute
  );
  const selectedValueSivina = useSelector(
    (state) => state.consistencyStore.selectedValueSivina
  );
  const selectedValueErri = useSelector(
    (state) => state.consistencyStore.selectedValueErri
  );
  const selectedConsistenciesStore = useSelector(
    (state) => state.consistencyStore.selectedConsistenciesStore
  );

  const [sortBy, setSortBy] = useState("store_id");
  const [sortOrder, setSortOrder] = useState("asc");

  function fetchData(currentPage, itemsPerPage, sortBy, sortOrder) {
    dispatch(setCurrentPageStore(currentPage));
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      store_id: selectedStoreId,
      store_name: selectedStoreName,
      atribute: selectedAtribute,
      value_sivina: selectedValueSivina,
      value_erri: selectedValueErri,
      type: projectType,
      sort_by: sortBy,
      sort_order: sortOrder,
    };
    fetchConsistencyStore(dispatch, accessToken, customParams);
  }

  useEffect(() => {
    fetchData(currentPage || 1, itemsPerPage, sortBy, sortOrder, projectType);
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedStoreId,
    selectedStoreName,
    selectedAtribute,
    selectedValueSivina,
    selectedValueErri,
    sortBy,
    sortOrder,
  ]);

  const handlePageChange = (event, page) => {
    fetchData(page + 1, itemsPerPage, sortBy, sortOrder);
  };

  const handleChangeItemPerPageSelect = (e) => {
    dispatch(setItemsPerPageStore(e.target.value));
    fetchData(1, e.target.value, sortBy, sortOrder);
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === "asc";
    setSortOrder(isSorting ? "desc" : "asc");
    setSortBy(column);
  };

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: "#FFF !important",
          "&:hover": {
            color: "#FFF",
          },
          "& .MuiTableSortLabel-icon": {
            color: "#FFF !important",
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  async function fetchEditConsistency(id, option, previousValue) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing/store-master/compare/${id}?option=${option}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ option }),
        }
      );

      if (response.status === 200) {
        toast.success("Data berhasil diubah", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        dispatch(setSelectedConsistencyStore({ id, value: option }));
      } else if (response.status === 400) {
        const message = await response.json();
        console.error(message.detail);
        toast.error(`${message.detail}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        dispatch(setSelectedConsistency({ id, value: previousValue }));
      } else {
        throw new Error("Error updating consistency");
      }
    } catch (error) {
      console.error("Error fetching consistency:", error);
      toast.error("Ada masalah saat menyimpan data", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
      // Revert to previous value if the update fails
      dispatch(setSelectedConsistencyStore({ id, value: previousValue }));
    }
  }

  const handleConsistencyChange = async (id, event) => {
    const option = event.target.value;
    const previousValue = selectedConsistenciesStore[id] || "";
    dispatch(setSelectedConsistencyStore({ id, value: option }));
    await fetchEditConsistency(id, option, previousValue);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer component={Paper} style={{ maxHeight: "450px" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {sortTableCell("Kode Outlet", "store_id")}
              {sortTableCell("Nama Outlet", "store_name")}
              {sortTableCell("Atribut", "atribute")}
              {sortTableCell("Sivina", "label_sivina")}
              {sortTableCell("ERRI", "label_erri")}
              <TableCell style={tableCellStyle}>Pilihan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{row.store_id}</TableCell>
                  <TableCell align="center">{row.store_name}</TableCell>
                  <TableCell align="center">{row.atribute}</TableCell>
                  <TableCell align="center">{row.label_sivina}</TableCell>
                  <TableCell align="center">{row.label_erri}</TableCell>
                  <TableCell align="center">
                    <TextField
                      id={`outlined-select-consistency-${row.id}`}
                      select
                      sx={{ height: 40, width: 150 }}
                      size="small"
                      value={selectedConsistenciesStore[row.id] || ""}
                      onChange={(event) =>
                        handleConsistencyChange(row.id, event)
                      }
                      className="reporting-textfield"
                    >
                      <MenuItem value="sivina">{row.label_sivina}</MenuItem>
                      <MenuItem value="erri">{row.label_erri}</MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Tidak ada data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {items.length > 0 && (
        <TablePagination
          component="div"
          count={total}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
          rowsPerPageOptions={itemsPerPageList}
        />
      )}
    </Paper>
  );
};

export const TableConsistencyStoreMT = ({ itemsPerPageList }) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.consistencyStore.data);
  const accessToken = useSelector((state) => state.general.accessToken);
  const currentPage = useSelector(
    (state) => state.consistencyStore.currentPage
  );
  const itemsPerPage = useSelector(
    (state) => state.consistencyStore.itemsPerPage
  );
  const total = useSelector((state) => state.consistencyStore.total);

  const selectedStoreId = useSelector(
    (state) => state.consistencyStore.selectedStoreId
  );
  const selectedStoreName = useSelector(
    (state) => state.consistencyStore.selectedStoreName
  );
  const selectedAtribute = useSelector(
    (state) => state.consistencyStore.selectedAtribute
  );
  const selectedValueSivina = useSelector(
    (state) => state.consistencyStore.selectedValueSivina
  );
  const selectedValueErri = useSelector(
    (state) => state.consistencyStore.selectedValueErri
  );
  const selectedConsistenciesStore = useSelector(
    (state) => state.consistencyStore.selectedConsistenciesStore
  );

  const [sortBy, setSortBy] = useState("store_id");
  const [sortOrder, setSortOrder] = useState("asc");

  function fetchData(currentPage, itemsPerPage, sortBy, sortOrder) {
    dispatch(setCurrentPageStore(currentPage));
    const customParams = {
      size: itemsPerPage,
      page: currentPage,
      store_id: selectedStoreId,
      store_name: selectedStoreName,
      atribute: selectedAtribute,
      value_sivina: selectedValueSivina,
      value_erri: selectedValueErri,
      type: "rdc_mt",
      sort_by: sortBy,
      sort_order: sortOrder,
    };
    fetchConsistencyStore(dispatch, accessToken, customParams);
  }

  useEffect(() => {
    fetchData(currentPage || 1, itemsPerPage, sortBy, sortOrder);
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    selectedStoreId,
    selectedStoreName,
    selectedAtribute,
    selectedValueSivina,
    selectedValueErri,
    sortBy,
    sortOrder,
  ]);

  const handlePageChange = (event, page) => {
    fetchData(page + 1, itemsPerPage, sortBy, sortOrder);
  };

  const handleChangeItemPerPageSelect = (e) => {
    dispatch(setItemsPerPageStore(e.target.value));
    fetchData(1, e.target.value, sortBy, sortOrder);
  };

  const handleSort = (column) => {
    const isSorting = sortBy === column && sortOrder === "asc";
    setSortOrder(isSorting ? "desc" : "asc");
    setSortBy(column);
  };

  const sortTableCell = (label, sortByKey) => (
    <TableCell style={tableCellStyle}>
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortOrder}
        onClick={() => handleSort(sortByKey)}
        sx={{
          color: "#FFF !important",
          "&:hover": {
            color: "#FFF",
          },
          "& .MuiTableSortLabel-icon": {
            color: "#FFF !important",
          },
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  async function fetchEditConsistency(id, option, previousValue) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/data-processing/store-master/compare/${id}?option=${option}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ option }),
        }
      );

      if (response.status === 200) {
        toast.success("Data berhasil diubah", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        dispatch(setSelectedConsistencyStore({ id, value: option }));
      } else {
        throw new Error("Error updating consistency");
      }
    } catch (error) {
      console.error("Error fetching consistency:", error);
      toast.error("Ada masalah saat menyimpan data", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
      // Revert to previous value if the update fails
      dispatch(setSelectedConsistencyStore({ id, value: previousValue }));
    }
  }

  const handleConsistencyChange = async (id, event) => {
    const option = event.target.value;
    const previousValue = selectedConsistenciesStore[id] || "";
    dispatch(setSelectedConsistencyStore({ id, value: option }));
    await fetchEditConsistency(id, option, previousValue);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer component={Paper} style={{ maxHeight: "450px" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {sortTableCell("Kode Outlet", "store_id")}
              {sortTableCell("Nama Outlet", "store_name")}
              {sortTableCell("Atribut", "atribute")}
              {sortTableCell("Sivina", "label_sivina")}
              {sortTableCell("ERRI", "label_erri")}
              <TableCell style={tableCellStyle}>Pilihan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 ? (
              items.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{row.store_id}</TableCell>
                  <TableCell align="center">{row.store_name}</TableCell>
                  <TableCell align="center">{row.atribute}</TableCell>
                  <TableCell align="center">{row.label_sivina}</TableCell>
                  <TableCell align="center">{row.label_erri}</TableCell>
                  <TableCell align="center">
                    <TextField
                      id={`outlined-select-consistency-${row.id}`}
                      select
                      sx={{ height: 40, width: 150 }}
                      size="small"
                      value={selectedConsistenciesStore[row.id] || ""}
                      onChange={(event) =>
                        handleConsistencyChange(row.id, event)
                      }
                      className="reporting-textfield"
                    >
                      <MenuItem value="sivina">{row.label_sivina}</MenuItem>
                      <MenuItem value="erri">{row.label_erri}</MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Tidak ada data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {items.length > 0 && (
        <TablePagination
          component="div"
          count={total}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={handleChangeItemPerPageSelect}
          rowsPerPageOptions={itemsPerPageList}
        />
      )}
    </Paper>
  );
};
