
export async function fetchDataSummaryData(
  token,
  setIsLoading,
  setFilteredRows,
  selectedSurveyId = "all",
  selectedTipeSurvey = "all",
  selectedArea = "all",
  selectedRegion = "all",
  selectedWilayah = "all",
  selectedRegencyId = "all",
  selectedDistrictId = "all",
  selectedSubDistrictId = "all",
  selectedUserNameSurveyor = "all",
  selectedPeriode = 'all'
) {
  setFilteredRows([]);
  setIsLoading(true);

  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  queryParams.append("is_area", "true");

  // Add selectedSurveyId to the query if it's provided
  if (selectedSurveyId && selectedSurveyId !== "all") {
    queryParams.append("survey_id", selectedSurveyId);
  }

  // Add selectedTipeSurvey to the query if it's provided
  if (selectedTipeSurvey && selectedTipeSurvey !== "all") {
    queryParams.append("tipe_survey", selectedTipeSurvey);
  }

  // Add selectedArea to the query if it's provided
  if (selectedArea && selectedArea !== "all") {
    queryParams.append("area", selectedArea);
  }

  // Add selectedRegion to the query if it's provided
  if (selectedRegion && selectedRegion !== "all") {
    queryParams.append("region_name", selectedRegion);
  }

  // Add selectedWilayah to the query if it's provided
  if (selectedWilayah && selectedWilayah !== "all") {
    queryParams.append("wilayah_name", selectedWilayah);
  }

  // Add selectedRegencyId to the query if it's provided
  if (selectedRegencyId && selectedRegencyId !== "all") {
    queryParams.append("regency_id", selectedRegencyId);
  }

  // Add selectedDistrictId to the query if it's provided
  if (selectedDistrictId && selectedDistrictId !== "all") {
    queryParams.append("district_id", selectedDistrictId);
  }

  // Add selectedSubDistrictId to the query if it's provided
  if (selectedSubDistrictId && selectedSubDistrictId !== "all") {
    queryParams.append("sub_district_id", selectedSubDistrictId);
  }

  if (selectedUserNameSurveyor && selectedUserNameSurveyor !== "all") {
    queryParams.append("user_name_surveyor", selectedUserNameSurveyor);
  }

  if (selectedPeriode && selectedPeriode !== 'all') {
    queryParams.append('periode', selectedPeriode);
  }

  // Construct the full URL with query parameters
  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/dashboard/audit-summary` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setFilteredRows(data.data); // Initialize filteredRows with the retrieved data
    } else {
      setFilteredRows([]);
      // Handle error
    }
  } catch (error) {
    // Handle error
  }

  setIsLoading(false);
}

export async function fetchDataStatData(
  token,
  setIsLoading,
  setFilteredRows,
  selectedSurveyId = "all",
  selectedTipeSurvey = "all",
  selectedArea = "all",
  selectedRegion = "all",
  selectedWilayah = "all",
  selectedRegencyId = "all",
  selectedDistrictId = "all",
  selectedSubDistrictId = "all",
  selectedUserNameSurveyor = "all"
) {
  setFilteredRows([]);
  setIsLoading(true);

  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  // Add selectedSurveyId to the query if it's provided
  if (selectedSurveyId && selectedSurveyId !== "all") {
    queryParams.append("survey_id", selectedSurveyId);
  }

  // Add selectedTipeSurvey to the query if it's provided
  if (selectedTipeSurvey && selectedTipeSurvey !== "all") {
    queryParams.append("tipe_survey", selectedTipeSurvey);
  }

  // Add selectedArea to the query if it's provided
  if (selectedArea && selectedArea !== "all") {
    queryParams.append("area", selectedArea);
  }

  // Add selectedRegion to the query if it's provided
  if (selectedRegion && selectedRegion !== "all") {
    queryParams.append("region_name", selectedRegion);
  }

  // Add selectedWilayah to the query if it's provided
  if (selectedWilayah && selectedWilayah !== "all") {
    queryParams.append("wilayah_name", selectedWilayah);
  }

  // Add selectedRegencyId to the query if it's provided
  if (selectedRegencyId && selectedRegencyId !== "all") {
    queryParams.append("regency_id", selectedRegencyId);
  }

  // Add selectedDistrictId to the query if it's provided
  if (selectedDistrictId && selectedDistrictId !== "all") {
    queryParams.append("district_id", selectedDistrictId);
  }

  // Add selectedSubDistrictId to the query if it's provided
  if (selectedSubDistrictId && selectedSubDistrictId !== "all") {
    queryParams.append("sub_district_id", selectedSubDistrictId);
  }

  // Add selectedUserNameSurveyor to the query if it's provided
  if (selectedUserNameSurveyor && selectedUserNameSurveyor !== "all") {
    queryParams.append("user_name_surveyor", selectedUserNameSurveyor);
  }

  // Construct the full URL with query parameters
  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/dashboard/stat` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const { data } = await response.json();
      data.unshift({
        audit_status: "Total Store",
        count_audit_status: data[0].count_store,
        count_store: data[0].count_store,
        percentage: "",
      });

      setFilteredRows(data); // Initialize filteredRows with the retrieved data
    } else {
      setFilteredRows([]);
      // Handle error
    }
  } catch (error) {
    // Handle error
  }

  setIsLoading(false);
}

export async function fetchDataDailyTrendData(
  token,
  setIsLoading,
  setDatagrafik,
  Highcharts,
  selectedSurveyId = "all",
  selectedTipeSurvey = "all",
  selectedArea = "all",
  selectedRegion = "all",
  selectedWilayah = "all",
  selectedRegencyId = "all",
  selectedDistrictId = "all",
  selectedSubDistrictId = "all",
  selectedUserNameSurveyor = "all",
  selectedDatestart,
  selectedDateend,
  setSelectedDateFirst,
  setSelectedDateLast,
  setFilteredData

) {
  setIsLoading(true);

  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  // Add selectedSurveyId to the query if it's provided
  if (selectedSurveyId && selectedSurveyId !== "all") {
    queryParams.append("survey_id", selectedSurveyId);
  }
  // Add selectedTipeSurvey to the query if it's provided
  if (selectedTipeSurvey && selectedTipeSurvey !== "all") {
    queryParams.append("tipe_survey", selectedTipeSurvey);
  }
  // Add selectedArea to the query if it's provided
  if (selectedArea && selectedArea !== "all") {
    queryParams.append("area", selectedArea);
  }

  // Add selectedRegion to the query if it's provided
  if (selectedRegion && selectedRegion !== "all") {
    queryParams.append("region_name", selectedRegion);
  }

  // Add selectedWilayah to the query if it's provided
  if (selectedWilayah && selectedWilayah !== "all") {
    queryParams.append("wilayah_name", selectedWilayah);
  }

  // Add selectedRegencyId to the query if it's provided
  if (selectedRegencyId && selectedRegencyId !== "all") {
    queryParams.append("regency_id", selectedRegencyId);
  }

  // Add selectedDistrictId to the query if it's provided
  if (selectedDistrictId && selectedDistrictId !== "all") {
    queryParams.append("district_id", selectedDistrictId);
  }

  // Add selectedSubDistrictId to the query if it's provided
  if (selectedSubDistrictId && selectedSubDistrictId !== "all") {
    queryParams.append("sub_district_id", selectedSubDistrictId);
  }

  // Add selectedUserNameSurveyor to the query if it's provided
  if (selectedUserNameSurveyor && selectedUserNameSurveyor !== "all") {
    queryParams.append("user_name_surveyor", selectedUserNameSurveyor);
  }

  if (selectedDatestart && selectedDatestart !== "all") {
    queryParams.append("start_date", selectedDatestart);
  }

  if (selectedDateend && selectedDateend !== "all") {
    queryParams.append("end_date", selectedDateend);
  }

  // Construct the full URL with query parameters
  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/dashboard/daily-trend` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const { data } = await response.json();
      if (data && data.length > 0) {
        const categories = data.map((item) => {
          const date = new Date(item.date_of_data_collected);
          const month = date.toLocaleString('en-US', { month: 'long' });
          const day = date.getDate();
          const year = date.getFullYear();
          return `${day} ${month} ${year}`;
        });
        const validData = data.map((item) => item.store_valid_count);
        const closedData = data.map((item) => item.store_closed_count);

        const filteredReachChartOption = {
          chart: {
            type: "line",
            renderTo: "daily-trend-chart",
          },
          title: {
            text: "",
          },
          xAxis: {
            categories: categories,
          },
          yAxis: {
            title: {
              text: "",
            },
          },
          series: [
            {
              name: "Closed",
              data: closedData,
              color: "#DF2E38",
            },
            {
              name: "Valid",
              data: validData,
              color: "#5D9C59",
            },
          ],
        };

        Highcharts.chart("daily-trend-chart", filteredReachChartOption);

        setDatagrafik(data);

        // useEffect(() => {
        // Memastikan bahwa Datagrafik telah diambil dari API sebelum memperbarui selectedDateFirst dan selectedDateLast
        if (data.length > 0) {
          setSelectedDateFirst(data[0].date_of_data_collected);
          setSelectedDateLast(data[data.length - 1].date_of_data_collected);
          setFilteredData(data);
        }
        // }, [Datagrafik]);
      } else {
        setDatagrafik([]);
        setFilteredData([]);
      }
    } else {
      // Jika response tidak ok, hapus chart dan kosongkan data
      setDatagrafik([]);
      setFilteredData([]);
    }

  } catch (error) {
    // Handle error
  }

  setIsLoading(false);
}


export async function fetchDataDailydiagram(
  token,
  setIsLoading,
  Highcharts,
  selectedSurveyId = "all",
  selectedTipeSurvey = "all",
  selectedArea = "all",
  selectedRegion = "all",
  selectedWilayah = "all",
  selectedRegencyId = "all",
  selectedDistrictId = "all",
  selectedSubDistrictId = "all",
  selectedUserNameSurveyor = "all",
  setDataDiagram,
) {
  setIsLoading(true);

  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  // queryParams.append("survey_id", selectedSurveyId);

  // Add selectedSurveyId to the query if it's provided
  if (selectedSurveyId && selectedSurveyId !== "all") {
    queryParams.append("survey_id", selectedSurveyId);
  }

  // Add selectedTipeSurvey to the query if it's provided
  if (selectedTipeSurvey && selectedTipeSurvey !== "all") {
    queryParams.append("tipe_survey", selectedTipeSurvey);
  }

  // Add selectedArea to the query if it's provided
  if (selectedArea && selectedArea !== "all") {
    queryParams.append("area", selectedArea);
  }

  // Add selectedRegion to the query if it's provided
  if (selectedRegion && selectedRegion !== "all") {
    queryParams.append("region_name", selectedRegion);
  }

  // Add selectedWilayah to the query if it's provided
  if (selectedWilayah && selectedWilayah !== "all") {
    queryParams.append("wilayah_name", selectedWilayah);
  }

  // Add selectedRegencyId to the query if it's provided
  if (selectedRegencyId && selectedRegencyId !== "all") {
    queryParams.append("regency_id", selectedRegencyId);
  }

  // Add selectedDistrictId to the query if it's provided
  if (selectedDistrictId && selectedDistrictId !== "all") {
    queryParams.append("district_id", selectedDistrictId);
  }

  // Add selectedSubDistrictId to the query if it's provided
  if (selectedSubDistrictId && selectedSubDistrictId !== "all") {
    queryParams.append("sub_district_id", selectedSubDistrictId);
  }

  // Add selectedUserNameSurveyor to the query if it's provided
  if (selectedUserNameSurveyor && selectedUserNameSurveyor !== "all") {
    queryParams.append("user_name_surveyor", selectedUserNameSurveyor);
  }

  // Construct the full URL with query parameters
  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/dashboard/summary-grouped-audit-status` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const { data } = await response.json();
      const totalClosed = data.Closed;
      const totalValid = data.Valid;
      const totalOnProgress = data['On Progress'];

      // Define chart options
      const filteredDailyUpdateChartOption = {
        chart: {
          type: "column",
          renderTo: "daily-progress-diagram",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: ["Closed Store", "Valid Stores", "On Progress"],
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        series: [
          {
            name: "Closed Store",
            data: [totalClosed],
            color: "#DF2E38",
          },
          {
            name: "Valid Stores",
            data: [totalValid],
            color: "#5D9C59",
          },
          {
            name: "On Progress",
            data: [totalOnProgress],
          },
        ],
      };

      setDataDiagram(data);

      // Render chart
      Highcharts.chart("daily-progress-diagram", filteredDailyUpdateChartOption);
    } else {
      setDataDiagram([]);
    }
  } catch (error) {
    // Handle error
  }

  setIsLoading(false);
}

export async function fetchAreasFormatted(token, setDataArea) {
  setDataArea([]);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/area/areas`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const { data } = await response.json();
      const newArray = data.map((item) => ({
        label: item.name,
        id: item.name,
      }));

      setDataArea(newArray);
    } else {
      setDataArea([]);
    }
  } catch (error) {
    console.error(error);
  }
}


export async function fetchRegenciesFormatted(token, area, setDataRegency) {
  setDataRegency([]);
  if (area && area !== "all") {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/regencies?area=${area}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        const newArray = data.map((item) => ({
          label: item.name,
          id: item.code,
        }));

        setDataRegency(newArray);
      } else {
        setDataRegency([]);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export async function fetchRegionFormatted(token, setDataRegion) {
  setDataRegion([]);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/area/regions`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const { data } = await response.json();
      const newArray = data.map((item) => ({
        label: item.name,
        id: item.name,
      }));

      setDataRegion(newArray);
    } else {
      setDataRegion([]);
    }
  } catch (error) {
    console.error(error);
  }
}

export async function fetchWilayahFormatted(token, setDataWilayah) {
  setDataWilayah([]);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/area/wilayah`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const { data } = await response.json();
      const newArray = data.map((item) => ({
        label: item.name,
        id: item.name,
      }));

      setDataWilayah(newArray);
    } else {
      setDataWilayah([]);
    }
  } catch (error) {
    console.error(error);
  }
}


export async function fetchDistrictFormatted(
  token,
  regencyCode,
  setDataDistrict
) {
  setDataDistrict([]);
  if (regencyCode && regencyCode !== "all") {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/districts?regency_code=${regencyCode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        const newArray = data.map((item) => ({
          label: item.name,
          id: item.code,
        }));

        setDataDistrict(newArray);
      } else {
        setDataDistrict([]);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export async function fetchSubDistrictFormatted(
  token,
  districtCode,
  setDataSubDistrict
) {
  setDataSubDistrict([]);
  if (districtCode && districtCode !== "all") {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/sub-districts?district_code=${districtCode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        const newArray = data.map((item) => ({
          label: item.name,
          id: item.code,
        }));

        setDataSubDistrict(newArray);
      } else {
        setDataSubDistrict([]);
      }
    } catch (error) {
      console.error(error);
    }
  }
}



export async function fetchPeriodeFormatted(token, setDataPeriode) {
  setDataPeriode([]);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/project/period`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      const newArray = data.data.map(item => ({
        label: item.period.toString(), // Mengonversi period menjadi string
        id: item.period.toString(),
      }));
      setDataPeriode(newArray);
    } else {
      setDataPeriode([]);
    }
  } catch (error) {
    console.error(error);
  }
}

export async function fetchProjectFormatted(token, periodeCode, setDataProject, setDatadateend, setDatadatestart) {
  setDataProject([])
  if (periodeCode && periodeCode !== 'all') {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project?period=${periodeCode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();

        const newArray = data.map(item => ({
          label: item.project_name,
          id: item.survey_id,
          project_type_id: item.project_type_id,
          project_start_at: item.project_start_at,
          project_end_at: item.project_end_at,
        }));
        setDataProject(newArray)
      } else {
        setDataProject([])
      }
    } catch (error) {
      console.error(error);
    }
  }
}

