// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbar {
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
.content-button {
    display: flex;
    justify-content: right;
    margin-right: 15px ;
}
.productbutton {
    height: 40px;
}
@media only screen and (max-width:700px) {
    p {
        font-size: 10px;
    }
    .productbutton {
        height: 30px;
    }
}
@media only screen and (max-width:530px) {
    .searchbar {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width:370px) {
    p {
        font-size: 9px;
    }
    .productbutton {
        width: 40px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/style/productmaster.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oCAAoC;AACxC;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,YAAY;IAChB;AACJ;AACA;IACI;QACI,aAAa;QACb,qCAAqC;IACzC;AACJ;AACA;IACI;QACI,cAAc;IAClB;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".searchbar {\n    display: grid;\n    grid-template-columns: repeat(4,1fr);\n}\n.content-button {\n    display: flex;\n    justify-content: right;\n    margin-right: 15px ;\n}\n.productbutton {\n    height: 40px;\n}\n@media only screen and (max-width:700px) {\n    p {\n        font-size: 10px;\n    }\n    .productbutton {\n        height: 30px;\n    }\n}\n@media only screen and (max-width:530px) {\n    .searchbar {\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n    }\n}\n@media only screen and (max-width:370px) {\n    p {\n        font-size: 9px;\n    }\n    .productbutton {\n        width: 40px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
