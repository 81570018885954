import { createSlice } from "@reduxjs/toolkit"

const processManagementSlice = createSlice({
  name: "processManagement",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
    processManagementStatus: [
      {
        "id": "rawdata"
      },
    ]
  },
  reducers: {
    // addToProcessManagement: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setProcessManagements: (state, action) => {
      state.data = action.payload;
    },
    setProcessManagement: (state, action) => {
      state.detail = action.payload;
    },
    // editProcessManagement: (state, action) => {
    //     const editedProcessManagementIndex = state.data.findIndex(ProcessManagement => ProcessManagement.id === action.payload.id);
    //     if (editedProcessManagementIndex !== -1) {
    //       state.data[editedProcessManagementIndex] = action.payload;
    //     }
    //   },
    deleteProcessManagement: (state, action) => {
      state.data = state.data.filter(ProcessManagement => ProcessManagement.id !== action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
  }
})

export const { deleteProcessManagement, setProcessManagements, setCurrentPage, setItemsPerPage, setProcessManagement, setTotal } = processManagementSlice.actions;
export default processManagementSlice.reducer;
