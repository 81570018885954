import { createSlice } from "@reduxjs/toolkit";

const rawDataSlice = createSlice({
  name: "rawData",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    type_projects: [],
    periods: [], 
    market_breakdowns: [],
    levels: [],
    facts: [],
    detail: {},
    selectedTypeProjectData: '',
    selectedPeriod: '',
    selectedMarketBreakDown: '',
    selectedLevel: '',
    selectedFact: '',
  },
  reducers: {
    setRawDatas: (state, action) => {
      state.data = action.payload;
    },
    setRawData: (state, action) => {
      state.data = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setPeriod: (state, action) => {
      state.periods = action.payload; 
    },
    setMarketBreakDown: (state, action) => {
      state.market_breakdowns = action.payload; 
    },
    setLevel: (state, action) => {
      state.levels = action.payload; 
    },
    setFact: (state, action) => {
      state.facts = action.payload; 
    },
    setSelectedPeriod: (state, action) => {
      state.selectedPeriod = action.payload; 
    },
    setSelectedMarketBreakDown: (state, action) => {
      state.selectedMarketBreakDown = action.payload; 
    },
    setSelectedLevel: (state, action) => {
      state.selectedLevel = action.payload; 
    },
    setSelectedFact: (state, action) => {
      state.selectedFact = action.payload; 
    },
    setSelectedTypeProjectData: (state, action) => {
      state.selectedTypeProjectData = action.payload; 
    },
    setTypeProjects: (state, action) => {
      state.type_projects = action.payload; 
    },
  }
});

export const {
  setRawDatas,
  setCurrentPage,
  setItemsPerPage,
  setRawData,
  setTotal,
  setPeriod,
  setMarketBreakDown,
  setLevel,
  setFact,
  setSelectedPeriod,
  setSelectedMarketBreakDown,
  setSelectedLevel,
  setSelectedFact,
  setTypeProjects,
  setSelectedTypeProjectData
} = rawDataSlice.actions;

export default rawDataSlice.reducer;
