import { createSlice } from "@reduxjs/toolkit"

const provinceSlice = createSlice({
  name: "province",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
  },
  reducers: {
    // addToProvince: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setProvinces: (state, action) => {
      state.data = action.payload;
    },
    setProvince: (state, action) => {
      state.detail = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
  }
})

export const { setProvinces, setCurrentPage, setItemsPerPage, setProvince, setTotal } = provinceSlice.actions;
export default provinceSlice.reducer;
