import React from 'react'
import {
    Modal,
    Box,
    Button
} from "@mui/material";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";
import TextField from '@mui/material/TextField';

function ModalUpdateAnswerItem({
    isLoading,
    isUpdateAnswerItem,
    setIsUpdateAnswerItem,
    modalStyle,
    editedAnswerItem,
    seteditedAnswerItem,
    handleUpdateAnswerItem
}) {
    return (
        <Modal
            open={isUpdateAnswerItem}
            onClose={() => setIsUpdateAnswerItem(false)}
            aria-labelledby="delete-modal-title"
            aria-describedby="delete-modal-description"
            sx={{ border: 0 }}
        >
            <Box sx={modalStyle}>
                <h1>Update Answer Item</h1>
                <TextField
                label="Answer"
                    name="answer"
                    value={editedAnswerItem.answer}
                    sx={{ mb: 2, width: "95%" }}
                    multiline
                    minRows={1}
                    InputProps={{
                        style: { fontSize: '1.5rem', fontWeight: 'bold' },
                    }}
                    onChange={(e) =>
                        seteditedAnswerItem({
                            ...editedAnswerItem,
                            answer: e.target.value,
                        })}
                />
                <TextField
                     label="Answer Item order"
                    name="answer_item_order"
                    value={editedAnswerItem.answer_item_order}
                    sx={{ mb: 2, width: "95%" }}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        style: { fontSize: '1.5rem', fontWeight: 'bold' },
                    }}
                    onChange={(e) =>
                        seteditedAnswerItem({
                            ...editedAnswerItem,
                            answer_item_order: e.target.value,
                        })}
                />
                {/* <TextField
                    name="answer_2nd"
                    value={editedAnswerItem.answer_2nd}
                    sx={{ mb: 2, width: "95%" }}
                    multiline
                    InputProps={{
                        style: { fontSize: '1.5rem', fontWeight: 'bold' },
                    }}
                    onChange={(e) =>
                        seteditedAnswerItem({
                            ...editedAnswerItem,
                            answer_2nd: e.target.value,
                        })}
                /> */}
                <div>
                    <Button
                        variant="outlined"
                        disableRipple
                        sx={{
                            width: 190,
                            height: 50,
                            mt: 2,
                            color: "#b34469",
                            borderColor: "#b34469",
                            mr: 1
                        }}
                        onClick={() => setIsUpdateAnswerItem(false)}
                    >
                        Cancel
                    </Button>
                    <BootstrapButton
                        variant="outlined"
                        disableRipple
                        sx={{ width: 190, mt: 2, height: 50, color: "#000000", borderColor: "#000000", ml: 1 }}
                        disabled={isLoading}
                        onClick={handleUpdateAnswerItem}
                    >
                        <p>{isLoading ? "Loading..." : "Update Answer Item"}</p>
                    </BootstrapButton>
                </div>
            </Box>
        </Modal>
    )
}

export default ModalUpdateAnswerItem
