import React from "react";
import { Modal, Button } from "@mui/material";

export const ModalGenerateDB = ({
  modalGenerate,
  handleCloseModal,
  handleGenerate,
}) => {
  return (
    <div>
      <Modal
        open={modalGenerate}
        onClose={handleCloseModal}
        aria-labelledby="generate-db-modal"
        aria-describedby="modal-to-confirm-generate-db"
      >
        <div
          style={{
            position: "absolute",
            width: 420,
            backgroundColor: "white",
            padding: 20,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <p style={{fontSize: "18px", fontWeight: "500", marginBottom: "50px"}}>Apakah anda yakin ingin melakukan generate DB?</p>
          <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            style={{ marginRight: 10 }}
          >
            Tidak
          </Button>
          <Button
            variant="contained"
            onClick={handleGenerate}
            style={{ backgroundColor: "#074173", color: "#FFF" }}
          >
            Ya
          </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
