import { React, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

function AddDataModal({
  isLoading, newUserData,
  isModalOpen, setIsModalOpen,
  modalStyle,
  dataRoleCodeOption,
  handleInputChange, handleSubmit
}) {
  // password management
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box
        sx={modalStyle}
        style={{
          maxHeight: "400px",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <h2>Add New User</h2>
        <TextField
          variant="filled"
          name="full_name"
          label="Full Name"
          value={newUserData.full_name}
          onChange={handleInputChange}
          sx={{ width: "100%", mb: 2 }}
        />
        <TextField
          variant="filled"
          name="user_name"
          autoComplete="off"
          label="User Name (Unik)"
          value={newUserData.user_name}
          onChange={handleInputChange}
          sx={{ width: "100%", mb: 2 }}
        />
        <FormControl sx={{ mb: 2, width: "100%" }} variant="filled">
          <InputLabel htmlFor="filled-adornment-password">
            Password
          </InputLabel>
          <FilledInput
            name="password"
            autoComplete="off"
            id="filled-adornment-password"
            type={showPassword ? "text" : "password"}
            value={newUserData.password}
            onChange={handleInputChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <TextField
          variant="filled"
          name="role_code"
          label="Role Name"
          select
          sx={{ width: "100%", mb: 2 }}
          value={newUserData.role_code}
          onChange={handleInputChange}
        >
          {dataRoleCodeOption.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              width: 182,
              mt: 2,
              mr: 2,
              height: 50,
              color: "#b34469",
              borderColor: "#b34469",
            }}
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <BootstrapButton
            variant="contained"
            disableRipple
            sx={{ width: 182, mt: 2, height: 50 }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "SUBMIT"}
          </BootstrapButton>
        </div>
      </Box>
    </Modal>
  )
}

export default AddDataModal
