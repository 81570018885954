// import React, { useState } from "react";
import "../style/login.css";
import logo from "../assets/images/bg/logo.png";

function Landing() {
  // const [getLoginValue, setLoginValue] = useState("");

  // function changeLoginButton() {
  //   setLoginValue('aefaf')
  // }
  return (
    <div className="container-out">
      <div className="login-container">
        <div className="kiri">
          <img className="login-logo" src={logo} alt="logo" />
        </div>
        <div className="kanan">
          <p className="sub-title">
            DATA COLLECTION SYSTEM FOR RDC AND RA SURVEY
          </p>
          <p className="title">
            Application For{" "}
            <span className="retail-color">
              Retail Audit and Observation Survey.
            </span>
          </p>
          <button className="landing-button">
            <a href="/login" className="landing-continue">
              Sign In
            </a>
          </button>
          <br />
          {/* <button className="landing-button" style={{marginTop: "20px"}}>
            <a href="https://sites.google.com/starcore.co/sivina/home" target="_blank" className="landing-continue">
              User Guide
            </a>
          </button> */}
        </div>
      </div>
      <div className="copyright">Copyright 2023 PT Exorty Indonesia</div>
    </div>
  );
}

export default Landing;
