import { createSlice } from "@reduxjs/toolkit"

const auditPlanSlice = createSlice({
  name: "auditPlan",
  initialState: {
    itemsPerPage: 10,
    currentPage: 1,
    total: 1,
    data: [],
    detail: {},
    selectedSurveyId: '',
    selectedStoreTypeCode: '',
    selectedSurveyTypeCode: '',
    selectedProvinceId: '',
    selectedRegencyId: '',
    selectedDistrictId: '',
    selectedSubDistrictId: '',
    selectedAuditStatus: '',
    selectedUserNameSurveyor: '',
    auditPlanfilterColumnName: [],
    filterColumnName: '',
    filterColumnOperator: 'LIKE',
    filterColumnValue: '',
  },
  reducers: {
    // addToauditPlan: (state, action) => {
    //     state.data.push(action.payload);
    // },
    setAuditPlans: (state, action) => {
      state.data = action.payload;
    },
    setAuditPlan: (state, action) => {
      state.detail = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setSelectedSurveyId: (state, action) => {
      state.selectedSurveyId = action.payload;
    },
    setSelectedStoreTypeCode: (state, action) => {
      state.selectedStoreTypeCode = action.payload;
    },
    setSelectedSurveyTypeCode: (state, action) => {
      state.selectedSurveyTypeCode = action.payload;
    },
    setSelectedProvinceId: (state, action) => {
      state.selectedProvinceId = action.payload;
    },
    setSelectedRegencyId: (state, action) => {
      state.selectedRegencyId = action.payload;
    },
    setSelectedDistrictId: (state, action) => {
      state.selectedDistrictId = action.payload;
    },
    setSelectedSubDistrictId: (state, action) => {
      state.selectedSubDistrictId = action.payload;
    },
    setSelectedAuditStatus: (state, action) => {
      state.selectedAuditStatus = action.payload;
    },
    setSelectedUserNameSurveyor: (state, action) => {
      state.selectedUserNameSurveyor = action.payload;
    },
    setAuditPlanColumn: (state, action) => {
      state.auditPlanfilterColumnName = action.payload;
    },
    setFilterColumnName: (state, action) => {
      state.filterColumnName = action.payload;
    },
    setFilterColumnOperator: (state, action) => {
      state.filterColumnOperator = action.payload;
    },
    setFilterColumnValue: (state, action) => {
      state.filterColumnValue = action.payload;
    },
  }
})

export const {
  setAuditPlans,
  setCurrentPage,
  setItemsPerPage,
  setAuditPlan,
  setTotal,
  setSelectedSurveyId,
  setSelectedStoreTypeCode,
  setSelectedSurveyTypeCode,
  setSelectedProvinceId,
  setSelectedRegencyId,
  setSelectedDistrictId,
  setSelectedSubDistrictId,
  setSelectedAuditStatus,
  setSelectedUserNameSurveyor,
  setAuditPlanColumn,
  setFilterColumnName,
  setFilterColumnOperator,
  setFilterColumnValue,
} = auditPlanSlice.actions;
export default auditPlanSlice.reducer;
